import { h, ComponentType } from 'preact'
import './Map.scss'
import { GoogleMap, OverlayView } from '@react-google-maps/api'
import { useState, useEffect } from 'preact/hooks'
import MapMarker from './MapMarker'
import CoachingClassPreviewVertical from '../../CoachingClasses/CoachingClassPreviewVertical'
import { CoachingClass } from '../../types/coaching_class'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { Category } from '../../types/category'
import Coach from '../../types/coach'
import CoachPreview from '../../CoachPreview'

interface Props {
  children?: JSX.Element | string
  center?: { lat: number; lng: number }
  zoom?: number
  markers?: {
    coachingClass: CoachingClass
    iconUrl: string
    onClick?: () => void
  }[]
  coachesMarkers?: {
    coach: Coach
    icon: ComponentType
    onClick?: () => void
  }[]
  options?: any
}

export default (props: Props) => {
  const [mapZoom, setMapZoom] = useState(2)
  const [mapCenter, setMapCenter] = useState(
    props.center || { lat: 48.866667, lng: 2.333333 }
  )
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setMapCenter(
        props.center ? { ...props.center } : { lat: 48.866667, lng: 2.333333 }
      )
      setMapZoom(+props.zoom)
    }, 200)
  }, [props.center])

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -height,
  })

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const refreshMap = () => {
    setMapZoom(mapZoom)
  }

  return (
    <div class="Map">
      <div class="Map__zoomControls">
        <div
          class="Map__zoomControlsPlus"
          onClick={() => setMapZoom(mapZoom + 1)}
        >
          {/* <img src={IconPlus} /> */}+
        </div>
        <div class="Map__zoomControlsSeparator"></div>
        <div
          class="Map__zoomControlsMinus"
          onClick={() => setMapZoom(mapZoom - 1)}
        >
          {/* <img src={IconMinus} /> */}-
        </div>
      </div>
      <div class="Map__wrapper">
        <GoogleMap
          onLoad={(map) => {
            // @ts-ignore
            const bounds = new window.google.maps.LatLngBounds()
            map.fitBounds(bounds)
          }}
          zoom={mapZoom}
          options={props.options}
          center={mapCenter}
          mapContainerClassName="Map__content"
        >
          {props.markers &&
            props.markers.map((m) => {
              return (
                <OverlayView
                  position={m.coachingClass.location}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <MapMarker
                    refreshMap={refreshMap}
                    modal={
                      <div class="Map__contentMarker">
                        <CoachingClassPreviewVertical
                          categories={categories}
                          coachingClass={m.coachingClass}
                          sessions={m.coachingClass.sessions}
                          coach={m.coachingClass.coach}
                        />
                        <a href={`/coaching_classes/${m.coachingClass.id}`}>
                          <div class="Map__contentMarkerLink">
                            Voir le cours
                          </div>
                        </a>
                      </div>
                    }
                    iconUrl={m.iconUrl}
                    onClick={() => {
                      m.onClick()
                    }}
                  />
                </OverlayView>
              )
            })}
          {props.coachesMarkers &&
            props.coachesMarkers.map((m) => {
              return (
                <OverlayView
                  position={m.coach.coords}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <MapMarker
                    refreshMap={refreshMap}
                    modal={
                      <div class="Map__contentMarker">
                        <CoachPreview categories={categories} coach={m.coach} />
                        <a href={`/coaches/${m.coach.id}`}>
                          <div class="Map__contentMarkerLink">
                            Voir le coach
                          </div>
                        </a>
                      </div>
                    }
                    icon={m.icon}
                    onClick={() => {
                      m.onClick()
                    }}
                  />
                </OverlayView>
              )
            })}
        </GoogleMap>
        {props.children}
      </div>
    </div>
  )
}
