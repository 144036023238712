import { h } from 'preact'
import Input from '../../components/Input'
import { useState, useContext } from 'preact/hooks'
import Button from '../../components/Button'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import './Contact.scss'
import store, { Message } from '../../services/store'
import { StepperContext } from 'react-material-stepper'
import { DateTimePicker } from 'react-widgets'
import moment from 'moment'

export default () => {
  const [phone, setPhone] = useState(undefined)
  const [address, setAddress] = useState(undefined)
  const [zipcode, setZipcode] = useState(undefined)
  const [city, setCity] = useState(undefined)
  const [birthdate, setBirthdate] = useState(undefined)
  const { resolve } = useContext(StepperContext)

  const onValidate = async () => {
    try {
      await httpClient.req(
        ROUTES.UPDATE_ME({
          phone,
          address,
          zipcode,
          city,
          birthdate: moment(birthdate).utc().toISOString().replace('Z', ''),
        })
      )
      resolve({})
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder les informations')
      console.warn(e)
    }
  }

  return (
    <div class="CoachSignupContact">
      <div class="CoachSignupContact__title">Informations complémentaires</div>
      <div class="CoachSignupContact__form">
        <div class="CoachSignupContact__formRow">
          <Input
            onChange={(v) => setPhone(v)}
            placeholder="Téléphone"
            value={phone}
          />
          <DateTimePicker
            placeholder="Date de naissance"
            value={birthdate}
            time={false}
            onChange={setBirthdate}
          />
        </div>
        <Input
          class="CoachSignupContact__formAddress"
          onChange={(v) => setAddress(v)}
          placeholder="Adresse"
          value={address}
        />
        <div class="CoachSignupContact__formRow">
          <Input
            onChange={(v) => setZipcode(v)}
            placeholder="Code postal"
            value={zipcode}
          />
          <Input
            onChange={(v) => setCity(v)}
            placeholder="Ville"
            value={city}
          />
        </div>
        <Button shadow onClick={onValidate}>
          Valider
        </Button>
      </div>
    </div>
  )
}
