import { h, Fragment } from 'preact'
// import IconCheck from '../../../assets/images/icons/icon_check.svg'
import IconPreview from '../../../assets/images/icons/icon_preview.svg'
import IconCross from '../../../assets/images/icons/icon_remove.svg'
import IconUpload from '../../../assets/images/icons/icon_upload.svg'
import './FileInput.scss'

interface Props {
  label: string
  onChange: (v: File) => void
  onDelete: () => void
  filled?: boolean
  previewUrl?: string
  status?: string
}

export default (props: Props) => {
  return (
    <div
      class={`FileInput${props.filled ? ' FileInput--filled' : ''}${
        props.status ? ` FileInput--${props.status}` : ''
      }`}
    >
      <div class="FileInput__left">
        <div class="FileInput__label">{props.label}</div>
        {/* {props.filled && (
          <div class="FileInput__check">
            <IconCheck />
          </div>
        )} */}
      </div>
      <div class="FileInput__right">
        {props.filled ? (
          <Fragment>
            <a
              target="_blank"
              href={props.previewUrl}
              class="FileInput__button FileInput__button--preview"
            >
              <IconPreview />
            </a>
            <div
              class="FileInput__button FileInput__button--delete"
              onClick={props.onDelete}
            >
              <IconCross />
            </div>
          </Fragment>
        ) : (
          <div class="FileInput__button FileInput__button--upload">
            <label>
              <input
                type="file"
                onChange={(e: any) => props.onChange(e.target.files[0])}
              />
              <IconUpload />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}
