import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import './CGV.scss'
import { useEffect } from 'preact/hooks'

export default (props: RoutableProps) => {
  useEffect(() => {
    if (window.location.hash) {
      setTimeout(
        () =>
          scrollTo(
            0,
            (document.querySelector(window.location.hash) as any).offsetTop -
              100
          ),
        500
      )
    }
  }, [])

  return (
    <div class="LegalNotice">
      <h1>CONDITIONS GENERALES ET PARTICULIERES D’UTILISATION</h1>
      <p>
        La société CENTES met à la disposition du public une plateforme en
        ligne, sous son nom commercial « GYMSPOT » (lequel désignera dans le
        cadre des présentes, la société CENTES) destinée à mettre en relation
        d’une part des particuliers désireux de bénéficier de prestations de
        coaching dans un cadre sportif collectif ou individuel dans le ressort
        de leur lieu de résidence, de travail ou de villégiature (ci-après
        désignés « le(s) client(s) » tel que ce terme est défini ci-après, à
        l’article DEFINITION), et d’autre part des coachs (tel que ce terme est
        défini ci-après, à l’article DEFINITION), référencés sur le Site,
        crédibles, sérieux et fiables, capables d’enseigner et d’animer des
        séances collectives dans leurs disciplines de prédilection (ci-après
        désignés « le(s) Coach(s) »).
        <br />
        <br />
        Le rôle de GYMSPOT se limiter à un rôle d’intermédiaire et de
        facilitateur.
        <br />
        <br />
        GYMSPOT ne conclut aucun contrat au nom et/ou pour le compte d’un Coach
        et/ou d’un Client, ces derniers contractant directement entre eux par
        l’intermédiaire de la plateforme GYMSPOT.
        <br />
        <br /> GYMSPOT n’étant pas partie, à quelque titre que ce soit, aux
        contrats relatifs à une Prestation conclus entre les Coachs et les
        Clients, ces derniers sont seuls responsables des difficultés,
        réclamations et litiges pouvant intervenir lors de la conclusion et/ou
        de l’exécution desdits contrats.
        <br />
        <br /> En conséquence, chaque Utilisateur (tel que ce terme est défini
        ci-après, à l’article DEFINITION) dégage GYMSPOT de toute responsabilité
        quant aux conséquences directes ou indirectes résultant de manière
        directe ou indirecte de la mise en relation, de la conclusion et/ou de
        l’exécution d’un tel contrat entre un Coach et un Client.
        <br />
        <br /> La garantie du GYMSPOT est limitée à la fourniture des moyens
        nécessaires à la mise en relation des coachs et des clients, afin de
        conclure un contrat de prestation de services par le biais de la
        plateforme GYMSPOT.
        <br />
        <br /> Ladite plateforme est accessible via l’URL : www.gymspot.fr .
        <br /> Elle est exploitée par la société CENTES, société par actions
        simplifiée, au capital de 1000 euros, dont le siège social est situé au
        1 place Paul Verlaine à Boulogne-Billancourt immatriculée au RCS de
        Nanterre sous le numéro 882 848 310.
      </p>
      <h2>DEFINITIONS</h2>
      <p>
        <strong>*Client</strong> : personne physique, régulièrement assurée
        (notamment, au titre de la Responsabilité Civile), n’ayant aucune
        contrindication de pratiquer des activités physiques et /ou sportives,
        ayant réservé, à partir de son espace personnel, sur le site
        www.gymspot.fr, une place pour participer à une séance collective ou
        individuelle de coaching sportif.
      </p>
      <br />
      <p>
        <strong>*Coach</strong> : tout Coach professionnel sportif,
        régulièrement assuré et remplissant les conditions d’exercice
        professionnel, pour proposer à travers le site www.gymspot.fr des
        Séances de coaching sportif collectif ou individuel auprès des clients
        utilisateurs de la plateforme GYMSPOT.
        <br />
        <br /> Il est rappelé en tant que de besoin, qu’aux termes des
        dispositions de l’article L 212- 1 du Code du Sport, <br />
        <br />
        <i style={{ fontFamily: 'Lato' }}>
          « I.-Seuls peuvent, contre rémunération, enseigner, animer ou encadrer
          une activité physique ou sportive ou entraîner ses pratiquants, à
          titre d'occupation principale ou secondaire, de façon habituelle,
          saisonnière ou occasionnelle, sous réserve des dispositions du
          quatrième alinéa du présent article et de l'article L.
          <br />
          <br />
          212-2 du présent code, les titulaires d'un diplôme, titre à finalité
          professionnelle ou certificat de qualification professionnelle :{' '}
          <br />
          <br />
          1° Garantissant la compétence de son titulaire en matière de sécurité
          des pratiquants et des tiers dans l'activité considérée ;<br />
          <br /> 2° Et enregistré au répertoire national des certifications
          professionnelles dans les conditions prévues à l'article L.6113-5 du
          code du travail.
          <br />
          <br />
          Peuvent également exercer contre rémunération les fonctions
          mentionnées au premier alinéa ci-dessus les personnes en cours de
          formation pour la préparation à un diplôme, titre à finalité
          professionnelle ou certificat de qualification professionnelle
          conforme aux prescriptions des 1° et 2° ci-dessus, dans les conditions
          prévues par le règlement de ce diplôme, titre ou certificat.
          <br />
          <br />
          II. Le diplôme mentionné au I peut être un diplôme étranger admis en
          équivalence.
          <br />
          <br /> III. Les dispositions du I s'appliquent à compter de
          l'inscription des diplômes, titres à finalité professionnelle ou
          certificats de qualification professionnelle sur la liste des
          diplômes, titres à finalité professionnelle ou certificats de
          qualification professionnelle répondant aux conditions prévues aux
          paragraphes I et II, au fur et à mesure de cette inscription.
          <br />
          <br />
          IV.- Les personnes qui auront acquis, dans la période précédant
          l'inscription mentionnée au III et conformément aux dispositions
          législatives en vigueur, le droit d'exercer contre rémunération une
          des fonctions mentionnées au I conservent ce droit.
          <br />
          <br />
          V.- Un décret en Conseil d'Etat détermine les conditions d'application
          du présent article.
          <br />
          <br />
          Il fixe notamment les modalités selon lesquelles est établie la liste
          mentionnée au III.»{' '}
        </i>
      </p>
      <p>
        <strong>*Utilisateur</strong> : client ou coach disposant d’un espace
        personnel sur le site www.gymspot.fr
      </p>
      <p>
        <strong>*Plateforme</strong> : La combinaison des supports physiques,
        informatiques et logiciels permettant l’accès aux Services Applicatifs
        du site site www.gymspot.fr par le client et/ou le coach.
      </p>
      <p>
        <strong>*Commande</strong> : La souscription par le Client, à une séance
        sportive payante sur la plateforme GYMSPOT suivant les présentes.
      </p>
      <p>
        <strong>*Offre</strong> : L’offre de service ayant fait l’objet d’une
        Commande par le Client.
      </p>
      <p>
        <strong>*Séance</strong> : Temps consacré par le Coach sportif aux
        clients dans un cadre collectif ou individuel.
        <br />
        Chaque séance est d’une durée de 45 à 120 minutes.
        <br />
        Elle démarre à l’heure convenue dans la Commande.
      </p>
      <p>
        <strong>*Site internet</strong> : site internet de GYMSPOT sur lequel la
        Plateforme est rendue accessible à tout Client ou Coach sur l’espace qui
        leur est respectivement et personnellement dédié.
      </p>
      <p>
        Les présentes Conditions Générales ont pour objet de régir les
        conditions et modalités d’utilisation et de vente du Site, ainsi que de
        définir les droits et obligations des Utilisateurs mis en relation sur
        le Site.
        <br />
        <br /> L’inscription et l’utilisation de la Plateforme supposent
        l’acceptation pleine et entière des présentes Conditions Générales.
        <br />
        <br /> Tout accès et/ou utilisation du Site suppose l’acceptation sans
        réserves et le respect de l’ensemble des termes des présentes
        Conditions.
      </p>
      <br />
      <br />
      <h2>
        *CONDITIONS D’UTILISATION COMMUNES AUX UTILISATEURS (CLIENTS / COACH)
      </h2>
      <br />
      <p>
        GYMSPOT et chacun des Utilisateurs sont des parties indépendantes,
        chacun agissant en son nom personnel et pour leur propre compte.
        <br />
        <br /> L’Utilisateur est tenu d'en prendre connaissance des présentes
        conditions générales avant toute passation de commande ou proposition de
        prestations.
        <br />
        <br />
        Le choix, la proposition et/ou l'achat d'un Service est de la seule
        responsabilité de l’Utilisateur.
        <br />
        <br />
        Ces conditions sont susceptibles d'être complétées par des conditions
        particulières, énoncées sur le site Internet, avant toute transaction
        avec l’Utilisateur.
        <br />
        <br />
        Ces Conditions Générales d’Utilisation et de Vente sont accessibles à
        tout moment sur le site Internet et prévaudront, le cas échéant, sur
        toute autre version ou tout autre document contradictoire.
        <br />
        <br /> Les systèmes et fichiers informatiques font foi dans les rapports
        entre GYMSPOT et le Client ou entre GYMSPOT et le Coach.
        <br />
        <br />
        Ainsi, GYMSPOT pourra valablement produire dans le cadre de toute
        procédure, aux fins de preuve les données, fichiers, programmes,
        enregistrements ou autres éléments, reçus, émis ou conservés au moyen
        des systèmes informatiques exploités par GYMSPOT, sur tous supports
        numériques ou analogiques, et s’en prévaloir sauf erreur manifeste.
        <br />
        <br /> L’Utilisateur déclare avoir pris connaissance des présentes
        Conditions Générales d’Utilisation et de Vente et les avoir acceptées en
        cochant la case prévue à cet effet avant la mise en œuvre de la
        procédure de commande en ligne ou de proposition de prestations, ainsi
        que des conditions générales d'utilisation du site internet GYMSPOT.
        <br />
        <br /> La validation de la commande de Services ou de proposition de
        prestation par l’Utilisateur vaut acceptation sans restriction ni
        réserve des présentes Conditions Générales d’Utilisation et de Vente.
        <br />
        <br />
        L’Utilisateur reconnaît avoir la capacité requise pour contracter et
        bénéficier des Services proposés sur le site internet GYMSPOT.
        <br />
        <br />
        Ces Conditions Générales d’Utilisation et de Vente peuvent faire l'objet
        de modifications ultérieures, la version applicable à la proposition de
        services de l’Utilisateur est celle en vigueur sur le site internet à la
        date de passation de la commande.
        <br />
        <br />
        Les modifications de ces Conditions Générales d’Utilisation et de Vente
        sont opposables aux utilisateurs du site internet GYMSPOT à compter de
        leur mise en ligne et ne peuvent s'appliquer aux transactions conclues
        antérieurement.
      </p>
      <br />
      <p>
        <strong>*UTILISATION DU SITE</strong>
      </p>
      <p>
        Les équipements (ordinateur, tablettes, smartphone) permettant l’accès à
        la Plateforme sont à la charge exclusive et respective du client et du
        coach.
        <br /> <br /> Le Coach et le Client déclarent être informés qu'ils
        devront en qualité d’utilisateur, pour accéder au Site, disposer d'un
        accès à l'Internet souscrit auprès du fournisseur de leur choix, dont le
        coût est à leur charge, et reconnaissent que :<br /> <br /> - La
        fiabilité des transmissions est aléatoire en raison, notamment, du
        caractère hétérogène des infrastructures et réseaux sur lesquelles elles
        circulent et que, en conséquence, des pannes ou saturations peuvent
        intervenir ;<br /> <br /> - Il leur appartient de prendre toute mesure
        qu'ils jugeront appropriées pour assurer la sécurité de leur équipement
        et de leurs propres données, logiciels ou autres, notamment contre la
        contamination par tout virus et/ou les tentatives d'intrusion dont ils
        pourraient être victimes ;<br /> <br /> - Tout équipement connecté au
        Site est et reste sous leur entière responsabilité.
        <br />
        <br />
        La responsabilité de GYMSPOT ne pourra ainsi pas être recherchée pour
        tout dommage direct ou indirect qui pourrait se produire du fait de leur
        connexion au Site.
        <br />
        <br />- L’accessibilité au Site et la qualité des services rendus
        dépendront notamment de la qualité de la connexion du Coach et des
        clients GYMSPOT ne garantit pas le fonctionnement sans interruption ou
        sans erreur de fonctionnement des Services, en particulier GYMSPOT ne
        saurait être engagée en cas d’interruption d’accès au Site en raison
        d’opérations de maintenance, de mises à jour ou d’améliorations
        techniques.
        <br />
        <br />
        Compte tenu des limites liées à l’internet, GYMSPOT ne peut exclure que
        l’accès et le fonctionnement du Site et des Services puissent être
        interrompus, notamment en cas de force majeure, de mauvais
        fonctionnement des équipements de l’Utilisateur, de dysfonctionnements
        du réseau internet de l’Utilisateur, d’opération de maintenance
        destinées à améliorer le Site et les Services.
        <br /> <br /> En conséquence, GYMSPOT ne saurait être tenue responsable
        d’une interruption des Services, qu’elle soit volontaire ou non, étant
        précisé qu’elle s’engage à faire ses meilleurs efforts pour limiter les
        interruptions qui lui seraient imputables.
        <br />
        <br />
        GYMSPOT décline toute responsabilité : <br /> <br />- en cas
        d'interruptions, de pannes, de modifications et de dysfonctionnements du
        Site quelles qu'en soient l'origine et la provenance ; <br /> <br />- en
        cas de perte de données ou d'informations stockées sur le Site.
        <br />
        <br />
        Il incombe au Coach de prendre toutes précautions nécessaires pour
        conserver les éléments relatifs aux propositions qu'il envoie, notamment
        en cas d'impossibilité momentanée d'accès au Site, par exemple en raison
        de problèmes techniques ; <br /> <br />- en cas de dommages directs ou
        indirects causés aux utilisateurs, et cela quelle qu'en soit la nature,
        résultant du contenu et/ou de l'accès, de l'utilisation, du
        dysfonctionnement et/ou de l'interruption du Site ; - en cas
        d'utilisation anormale ou d'une exploitation illicite du Site par tout
        utilisateur ou tout tiers ;<br /> <br /> - en cas d'utilisation non
        conforme du Site par 1 utilisateur, ou en cas de nonconformité du Site
        aux besoins ou aux attentes spécifiques d’un utilisateur ;<br /> <br />{' '}
        - du fait de préjudices ou dommages directs ou indirects, de quelque
        nature que ce soit, résultant de la gestion, l'utilisation,
        l'exploitation, l'interruption ou le dysfonctionnement du Site.
        <br />
        <br />
        Par ailleurs, GYMSPOT ne pourra être tenue pour responsable des retards
        ou impossibilités de remplir ses obligations contractuelles, en cas :
        <br /> <br /> - de force majeure,
        <br /> <br /> - d'impossibilité momentanée d'accès au Site en raison de
        problèmes techniques, quelle qu'en soit l'origine, - d'attaque ou
        piratage informatique, privation, suppression ou interdiction,
        temporaire ou définitive, et pour quelque cause que ce soit, de l'accès
        au réseau Internet.
        <br /> <br />
      </p>
      <p>
        <strong>*Protection du site</strong>
        <br />
        L’ensemble du contenu dudit Site, notamment les designs, textes,
        graphiques, images, vidéos, informations, logos, icônes-boutons,
        logiciels, fichiers audio et autres appartient à GYMSPOT, lequel est
        seul titulaire de l’intégralité des droits de propriété intellectuelle y
        afférents.
        <br /> <br />
        <p>
          <strong> *Propriété intellectuelle</strong>
          <br /> Tous les droits de propriété intellectuelle (tels que notamment
          droits d'auteur, droits voisins, droits des marques, droits des
          producteurs de bases de données) portant tant sur la structure que sur
          les contenus du Site (ci-après désignés dans leur ensemble " les
          Données ") sont réservés.
          <br />
          <br />
          Ces Données sont la propriété de GYMSPOT, et sont mises à disposition
          des utilisateurs pour la seule utilisation du Site, et dans le cadre
          d'une utilisation normale de ses fonctionnalités.
          <br />
          <br />
          Les utilisateurs s'engagent à ne modifier en aucune manière les
          Données.
          <br />
          <br />
          Il est interdit à tout utilisateur de copier, modifier, créer une
          œuvre dérivée, inverser la conception ou l'assemblage ou de toute
          autre manière tenter de trouver le code source, vendre, sous-licencier
          ou transférer de quelque manière que ce soit tout droit afférent aux
          Données.
          <br />
          <br /> Tout utilisateur s'engage notamment à ne pas :<br />
          <br /> - utiliser le Site pour le compte ou au profit d'autrui ; -
          reproduire en nombre, à des fins commerciales ou non, des informations
          présentes sur le Site ;<br />
          <br /> - intégrer tout ou partie du contenu du Site dans un site
          tiers, à des fins commerciales ou non ;<br />
          <br /> - utiliser un robot, notamment d'exploration (spider), une
          application de recherche ou de récupération de sites Internet ou tout
          autre moyen permettant de récupérer ou d'indexer tout ou partie du
          contenu du Site, excepté en cas d'autorisation expresse et préalable
          de GYMSPOT ; <br />
          <br />- copier les informations sur des supports de toute nature
          permettant de reconstituer tout ou partie des fichiers d'origine.
          <br />
          <br />
          Toute reproduction, représentation, publication, transmission,
          utilisation, modification ou extraction de tout ou partie des Données
          et ce de quelque manière que ce soit, faite sans l'autorisation
          préalable et écrite de GYMSPOT est illicite.
          <br />
          <br />
          Elles constituent une violation des droits d’auteur et donc une
          contrefaçon susceptible d’engager la responsabilité de ses auteurs et
          d'entraîner des poursuites judiciaires à leur encontre, notamment pour
          contrefaçon.
          <br />
          <br /> Les marques et logos appartenant à GYMSPOT, ainsi que les
          marques et logos des partenaires de GYMSPOT sont des marques déposées.
          <br />
          <br />
          Toute reproduction totale ou partielle de ces marques et/ou logos sans
          l'autorisation préalable et écrite de GYMSPOT ou de leurs titulaires
          respectifs est interdite.
          <br />
          <br />
          GYMSPOT est producteur des bases de données du Site.
          <br />
          <br />
          En conséquence, toute extraction et/ou réutilisation de la ou des
          bases de données (au sens des articles L 342- 1 et L 342-2 du Code de
          la Propriété Intellectuelle) est interdite.
          <br />
          <br />
          GYMSPOT se réserve la possibilité d’utiliser toutes voies de droit à
          l'encontre des personnes qui n'auraient pas respecté les interdictions
          contenues dans le présent article.
          <br />
          <br />
        </p>
      </p>
      <p>
        <strong>* Droit d’accès au Site</strong>
      </p>
      <br />
      <br />
      <strong>
        {' '}
        Inscription préalable obligatoire sur le site internet Gymspot
      </strong>
      <br />
      <br /> Pour pouvoir accéder aux Services, l’Utilisateur doit s’inscrire
      sur le site GYMSPOT.
      <br />
      <br /> L’Utilisateur doit être âgé d’au moins 18 ans et être capable
      juridiquement de contracter et d’utiliser le Site, conformément aux
      présentes Conditions Générales d’Utilisation et de Vente.
      <br />
      <br />
      L’Utilisateur est tenu de fournir des informations exactes, fiables et
      sincères, qu’il s’engage à mettre immédiatement et sans délais à jour, en
      cas de modifications.
      <br />
      <br /> L’utilisateur s’engage en s’inscrivant à fournir dans le cadre de
      cette inscription tant une photographie d’identité conforme à son visuel
      au jour de ladite inscription, qu’une adresse email et un numéro de
      portable aux fins de recevoir en temps utiles toutes notifications rendues
      nécessaires et en lien avec la prestation souscrite.
      <br />
      <br />
      L’utilisateur reconnait ainsi, par avance autoriser GYMSPOT à utiliser ses
      coordonnées téléphoniques et son adresse électronique aux fins de lui
      communiquer toutes informations utiles en lien avec le site internet
      GYMSPOT.
      <br />
      <br />
      L’accès au compte créé est protégé par un identifiant et un mot de passe
      choisi par l’Utilisateur lors de son inscription sur le Site.
      <br />
      <br /> L’Utilisateur est seul responsable de tout usage qui pourrait être
      fait de son identifiant et/ou mot de passe, et demeure le seul garant de
      leur confidentialité, ainsi que de toute utilisation de son compte.
      <br />
      <br /> Pour être référencé en qualité de Coach sur le Site, il lui
      appartient d’adresser à GYMSPOT lors de son inscription sur le site
      internet GYMSPOT sa carte professionnelle à jour et en cours de validité
      ainsi que toute pièce utile qui pourrait lui être demandée, dans le cadre
      de l’instruction et de la validation de sa demande d’inscription.
      <br />
      <br />
      Le Coach est référencé uniquement sous son Nom et Prénom.
      <br />
      <br /> Le Coach s’engage à ne pas prendre contact directement avec le
      client sans passer par le site internet htpp://www.gymspot.fr.
      <br />
      <br />
      Le Coach accepte par ailleurs de conclure un Mandat de facturation avec
      GYMSPOT pour utiliser les Services.
      <br />
      <br />
      Le Mandat de facturation désigne le mandat de facturation conclu entre le
      coach et GYMSPOT, aux termes duquel le coach accepte de confier à GYMSPOT,
      dans le respect des règles applicables, l’établissement de ses factures
      relatives aux prestations réalisées pour un client via le site.
      <br />
      <br /> Pour être enregistré en qualité de Client, il convient de procéder
      à la création d’un Compte sur l’espace personnel dédié à cet effet.
      <br />
      <br /> Ceci constitue la condition préalable à toute réservation et
      paiement d’une Prestation et vaut acceptation des présentes Conditions
      Générales d’Utilisation et de Vente.
      <br />
      <br /> Toute utilisation contraire du Site à sa finalité est strictement
      interdite et constitue un manquement aux présentes dispositions Dans
      l'hypothèse où l’Utilisateur (Client ou Coach) fournirait des données
      fausses, inexactes, périmées ou incomplètes, GYMSPOT sera en droit de
      suspendre ou de procéder à la fermeture de son compte et de lui refuser, à
      l'avenir, l'accès à tout ou partie des Services.
      <br />
      <br />
      Les utilisateurs s’interdisent de : <br />
      <br />
      1. Transmettre, publier, distribuer, enregistrer ou détruire tout
      matériel, en particulier les contenus de GYMSPOT, en violation des lois ou
      règlementations en vigueur concernant la collecte, le traitement ou le
      transfert d’informations personnelles;
      <br />
      <br /> 2. Fournir des informations inexactes dans le formulaire de
      programmation de séance; <br />
      <br />
      3. Diffuser des données, informations, ou contenus à caractère
      diffamatoire, injurieux, obscène, offensant, violent ou incitant à la
      violence, ou à caractère politique, raciste ou xénophobe et de manière
      générale tout contenu qui serait contraire aux lois et règlements en
      vigueur ou aux bonnes mœurs; <br />
      <br />
      4. Référencer ou créer des liens vers tout contenu ou information
      disponible depuis les sites de GYMSPOT, sauf y être expressément autorisé
      par les présentes Conditions; <br />
      <br />
      5. Obtenir d’autres Clients des mots de passe ou des données personnelles
      d’identification; <br />
      <br /> 6. Utiliser des informations, contenus ou toutes données présentes
      sur le Site afin de proposer un service considéré, à l’entière discrétion
      de GYMSPOT, comme concurrentiel à GYMSPOT ; <br />
      <br />
      7. Vendre, échanger ou monnayer des informations, contenus ou toutes
      données présentes sur le Site ou Service proposé par le Site, sans
      l’accord express et écrit de GYMSPOT ; <br />
      <br />
      8. Pratiquer de l’ingénierie inversée (Reverse Engineering), décompiler,
      désassembler, déchiffrer ou autrement tenter d’obtenir le code source en
      relation avec toute propriété intellectuelle sous-jacente utilisée pour
      fournir tout ou partie des Services ; <br />
      <br />
      9. Utiliser des logiciels ou appareils manuels ou automates, robots de
      codage ou autres moyens pour accéder, explorer, extraire ou indexer toute
      page du Site ; <br />
      <br />
      10. Mettre en danger ou essayer de mettre en danger la sécurité d’un site
      web de GYMSPOT.
      <br />
      <br />
      Cela comprend les tentatives de contrôler, scanner ou tester la
      vulnérabilité d’un système ou réseau ou de violer des mesures de sécurité
      ou d’authentification sans une autorisation préalable expresse ; <br />
      <br />
      11. Contrefaire ou d’utiliser les produits, les logos, les marques ou tout
      autre élément protégé par les droits de propriété intellectuel de GYMSPOT{' '}
      <br />
      <br />
      12. Simuler l’apparence ou le fonctionnement du Site, en procédant par
      exemple à un effet miroir; 13. Perturber ou troubler, directement ou
      indirectement, GYMSPOT, ou imposer une charge disproportionnée sur
      l’infrastructure du Site ou tenter de transmettre ou d’activer des virus
      informatique via ou sur le Site.
      <br />
      <br /> Il est rappelé que les violations de la sécurité du système ou du
      réseau peuvent conduire à des poursuites civiles et pénales.
      <br />
      <br />
      GYMSPOT vérifie l’absence de telle violation et peut faire appel aux
      autorités judiciaires pour poursuivre, le cas échéant, des utilisateurs
      ayant participé à de telles violations.
      <br />
      <br />
      Les utilisateurs s’engagent à utiliser le Site de manière loyale,
      conformément à sa finalité professionnelle et aux dispositions légales,
      réglementaires, aux présentes Conditions et aux usages en vigueur.
      <br />
      <br />
      L’Utilisateur s’interdit de publier sur les pages du Site accessibles aux
      autres Utilisateurs (notamment sur la page profil du Coach, les espaces de
      discussion etc.) toute « information de contact », telle que notamment un
      numéro de téléphone ou une adresse email.
      <br />
      <br />
      Les Utilisateurs s’engagent à faire une utilisation loyale du Site et des
      Services et s’interdisent expressément de contourner les Services et le
      Site GYMSPOT.
      <br />
      <br />
      En conséquence, lorsqu’un Coach et un Client sont mis en relation par le
      biais de la plateforme GYMSPOT, ils s’engagent à contracter exclusivement
      par l’intermédiaire du Site.
      <br />
      <br />
      Tout Utilisateur constatant un contournement des Services de GYMSPOT ou
      incité par un autre Utilisateur à les contourner, s’engage à en informer
      immédiatement GYMSPOT.
      <br />
      <br />
      De même, tout Utilisateur s’interdit de procéder à toute extraction du
      contenu du Site pour une activité similaire ou concurrente, ou à des fins
      de recrutement.
      <br />
      <br />
      L’Utilisateur s’interdit également d’utiliser les Services et le Site
      GYMSPOT pour faire la promotion de son activité ou de celle d’un tiers, en
      dehors de la plateforme.
      <br />
      <br />
      <h2>*CONDITIONS PARTICULIERES PROPRES AUX CLIENTS</h2>
      <p>
        <strong>* Information précontractuelle</strong>
        <br />
        <br />
        - Acceptation de l’Utilisateur L’Utilisateur reconnaît avoir eu
        communication, préalablement à la passation de sa commande et à la
        conclusion du contrat avec le Coach, d'une manière lisible et
        compréhensible, des présentes Conditions Générales et de toutes les
        informations listées à l'article L.221-5 du Code de la consommation.
        <br />
        <br />
        <p>
          <strong>* Commandes</strong>
          <br />
          <br />
          Lorsqu'un Client souhaite bénéficier d’une Prestation avec un Coach,
          le Client doit tout d'abord prendre connaissance des conditions et
          détails des Prestations proposées par le Coach, sur sa fiche de
          présentation, avant d’effectuer sa réservation (par exemple les zones
          géographiques des cours desservies par le Coach, le nombre de
          personnes maximum pouvant assister à la séance, la nature des actes
          proposées, etc.)
          <br />
          <br />
          Les informations contractuelles font l'objet d'une confirmation au
          plus tard au moment de la validation de la commande par l’Utilisateur.
          <br />
          <br />
          Le client sélectionne alors sur le site les services qu'il désire
          commander, selon les modalités suivantes : ”Après avoir identifié le
          ou les cour (s) collectif (s) ou individuel (s) du sport souhaité (s),
          l’utilisateur ajoutera ledit service à son panier.
          <br />
          <br />
          Après avoir validé sa commande, le client rentre ses coordonnées
          bancaires et procède audit paiement par le biais de sa carte bancaire,
          l’utilisateur recevant alors par email la confirmation de sa
          commande.»
          <br />
          <br />
          Dans l’hypothèse où le Client souhaite souscrire à une offre le
          paiement du tarif indiqué sur le Site se fait par carte bancaire, via
          la solution de paiement sécurisé Stripe.
          <br />
          <br />
          (Vous pouvez obtenir toutes les informations sur cette procédure en
          vous rendant sur le site stripe, https://www.stripe.com.
          <br />
          <br />
          GYMSPOT, n’a pas d’accès aux données bancaires confidentielles (le
          numéro de carte bancaire à 16 chiffres ainsi que la date d’expiration,
          le code CVV) lesquelles sont directement transmises cryptées sur le
          serveur de Stripe.
          <br />
          <br />
          Les systèmes d’enregistrement automatique sont considérés comme valant
          preuve de la nature, du contenu et de la date de la réservation.
          <br />
          <br />
          Le Client est engagé par sa réservation dès qu’il clique sur «Payer».
          <br />
          <br />
          GYMSPOT confirmera au Client l’acceptation de sa réservation par
          l’envoi d’un message de confirmation à l’adresse courriel que ce
          dernier aura communiqué.
          <br />
          <br />
          GYMSPOT se réserve le droit de refuser ou d’annuler toute réservation
          d’un Client notamment, en cas d’insolvabilité ou dans l’hypothèse d’un
          défaut de paiement de la réservation concernée ou d’un litige relatif
          au paiement d’une commande antérieure.
          <br />
          <br />
          Les mentions indiquées par le Client, lors de la saisie des
          informations inhérentes à sa réservation engagent celui-ci et seront
          utilisées par GYMSPOT pour établir la facture au nom et pour le compte
          du Coach.
          <br />
          <br />
          GYMSPOT ne saurait être tenu responsable des erreurs commises par le
          Client dans le libellé des champs à compléter au moment de la
          programmation de séance.
          <br />
          <br />
          Conformément aux dispositions de l’article L.121-18 du code de la
          consommation, le Client dispose d’un droit de rétractation dans un
          délai de quatorze jours à compter de la souscription à une Offre.
          <br />
          <br />
          Le droit de rétractation peut être exercé en ligne à l’aide du
          formulaire disponible sur le site GYMSPOT, sans avoir à justifier de
          motifs ni à payer de pénalité, afin de remboursement.
          <br />
          <br />
          Pour se rétracter, le Client devra adresser ledit formulaire à
          GYMPOST.
          <br />
          <br />
          Le remboursement interviendra dans les 14 jours de la réception de la
          lettre de rétractation.
          <br />
          <br />
          Dans ce contexte particulier, le Coach ne pourra en aucun cas
          prétendre au versement d’une quelconque somme, dans la mesure où lui
          seul assume les risques liés aux prestations.
          <br />
          <br />
          Le droit de rétractation ne peut être exercé dès lors que la Séance
          est exécutée ou en voie d’exécution prochaine.
          <br />
          <br />
          La Séance est considérée comme exécutée ou en voie d’exécution
          prochaine si le Client n’a pas annulé au moins 24 heures auparavant.
          <br />
          <br />
          Les commandes peuvent être annulées par le client, hors l'exercice du
          droit de rétractation ou cas de force majeure, via une demande
          réalisée par voie électronique à l’adresse email contact@gymspot.fr
          dans un délai de 24 heures au moins avant la date prévue pour la
          prestation de services.
          <br />
          <br />
          En cas d’annulation ou de rétractation, les frais retenus par les
          services Stripe demeurent, en toute hypothèse, à la charge du Client.
          <br />
          <br />
          L'enregistrement d'une commande sur le site GYMSPOT est réalisé
          lorsque le client accepte les présentes Conditions Générales
          d’Utilisation et de Vente en cochant la case prévue à cet effet et
          valide sa commande.
          <br />
          <br />
          Il appartient donc au client de vérifier l'exactitude de la commande
          et de signaler immédiatement toute erreur.
          <br />
          <br />
          L’engagement contractuel ne sera formé entre le client et le coach
          qu'après l'envoi au client de la confirmation de l'acceptation de la
          commande par GYMSPOT, par courrier électronique et après paiement en
          ligne de l'intégralité du prix dû.
          <br />
          <br />
          Toute commande passée sur le site GYMSPOT constitue la formation d'un
          contrat conclu à distance entre le client et le Coach, grâce à la mise
          en relation de la plateforme GYMSPOT.
          <br />
          <br />
          GYMSPOT se réserve le droit d'annuler ou de refuser toute commande
          d'un client utilisateur avec lequel il existerait un litige relatif au
          paiement d'une commande antérieure.
          <br />
          <br />
          Le client reconnaît que le paiement de la Prestation ne constitue pas
          l'acceptation d'une offre ferme et que la conclusion de la Prestation
          demeure également, subordonnée à la confirmation par le Coach, ce dont
          s’assure GYMSPOT.
          <br />
          <br />
          C’est dans ces circonstances, qu’une fois confirmée par le coach, le
          client recevra sur sa confirmation de commande, qu’il lui appartiendra
          soit d’imprimer, soit de conserver sur son portable aux fins de
          permettre aux coachs, de vérifier tant l’identité des clients
          présents, que le règlement effectif de la prestation sur le site
          internet.
          <br />
          <br />
          Une fois confirmée et acceptée par le coach et GYMSPOT, dans les
          conditions ci-dessus décrites, la commande n'est pas modifiable.
          <br />
          <br />
          Au surplus, le client pourra être amené à recevoir sur son téléphone
          portable, renseigné lors de l’inscription, des notifications aux fins
          de l’informer des mises à jour récentes, des éventuelles modifications
          sur la prestation, d’un éventuel retard du coach, annulations etc…
          <br />
          <br />
          En cas d’impossibilité matérielle du Coach d’honorer cette
          réservation, GYMSPOT et le Coach déploieront leurs meilleurs efforts,
          dans le cadre d’une obligation de moyen, pour proposer un créneau
          alternatif ou un cours alternatif au Client, voire un bon de la valeur
          du cours annulé afin qu’il puisse se réinscrire sur le cours de son
          choix, dans les meilleurs délais ou en cas d’échec, donner lieu à son
          remboursement intégral.
          <br />
          <br />
          Si les services commandés n'ont pas été fournis dans un délai de 8
          jours après la date mentionnée sur le site internet GYMSPOT, pour
          toute autre cause que la force majeure ou le fait du client, la vente
          pourra être résolue à la demande écrite du client
          <br />
          <br />
          Les conditions prévues aux articles L 216-2 L 216-3 et L241-4 du Code
          de la consommation.
          <br />
          <br />
          Les sommes versées par le client lui seront alors restituées au plus
          tard dans les quatorze jours qui suivent la date de dénonciation du
          contrat, à l'exclusion de toute indemnisation ou retenue (à
          l’exception des frais attachés à la solution de paiement Stripe qui ne
          pourront donner lieu, en toute hypothèse, à remboursement).
          <br />
          <br />
        </p>
        <p>
          <strong>
            * Tarifs de la prestation sportive et conditions de paiement à la
            charge du client
          </strong>
          <br />
          <br />
          Les Services proposés via la plateforme GYMSPOT sont fournis aux
          tarifs en vigueur sur le site selon la proposition établie au nom et
          pour le compte du coach, lors de l'enregistrement de la commande.
          <br />
          <br /> Les prix sont exprimés en Euros, HT et TTC.
          <br />
          <br />
          Ces tarifs sont fermes et non révisables pendant leur période de
          validité, telle qu'indiqué sur le site GYMSPOT, celle-ci se réservant
          le droit, hors cette période de validité, de modifier les prix à tout
          moment, suivant notamment, la nature des propositions formulées par le
          Coach.
          <br />
          <br />
          Ceux-ci ne comprennent pas les frais de traitement et de gestion de la
          commande.
          <br />
          <br />
          Le paiement demandé au client correspond au montant total de l'achat,
          y compris ces frais.
          <br />
          <br />
          Une facture est établie par GYMSPOT, au nom et pour le compte du coach
          et transmise au client après fourniture des Services commandés.
          <br />
          <br />
          <br />
          <br />
          Le prix est payable comptant, en totalité au jour de la passation de
          la commande par le client, selon les modalités précisées ci-dessus,
          par voie de paiement sécurisé par cartes bancaires.
          <br />
          <br />
          Le paiement par carte bancaire est irrévocable, sauf en cas
          d'utilisation frauduleuse de la carte.
          <br />
          <br />
          <br />
          <br />
          Les données de paiement sont échangées en mode crypté grâce au service
          de paiement sécurisé effectué via Stripe.
          <br />
          <br />
          Le Coach ne sera pas tenu de procéder à la fourniture des Services
          commandés par le client si le prix n’a pas été préalablement réglé en
          totalité, dans les conditions ci-dessus indiquées.
          <br />
          <br />
          Les paiements effectués par le client ne seront considérés comme
          définitifs, qu'après encaissement effectif des sommes dues.
        </p>
        <br />
        <br />
        <h2>*CONDITIONS PARTICULIERES D’UTILISATION PROPRES AUX COACHS</h2>
        <p>
          Lorsqu’un Coach utilise le Site, il accepte de se conformer et d'être
          lié par les dispositions des présentes Conditions, lesquelles
          régissent ainsi l’accès au Site et son utilisation.
          <br />
          <br />
          Le Coach déclare en avoir pris connaissance et les accepter
          expressément, sans réserve et/ou modification de quelque nature que ce
          soit.
          <br />
          <br />
          Le Coach qui n’accepte pas d’être lié par tout ou partie des présentes
          Conditions ne doit pas utiliser le Site ou, le cas échéant, cesser
          immédiatement toute utilisation du Site.
          <br />
          <br />
          <strong> * Indépendance des parties</strong>
          <br />
          <br />
          Le Coach comprend et accepte que GYMSPOT reste extérieure à l’accord
          passé entre lui et chaque Client, via le site GYMSPOT.
          <br />
          <br />
          Chacune des Parties est une personne indépendante juridiquement et
          financièrement agissant en son nom propre et sous sa seule
          responsabilité.
          <br />
          <br />
          Le Coach reconnait expressément qu’il est un tiers indépendant, qui
          agit exclusivement en son nom et pour son compte.
          <br />
          <br />
          Chaque Partie s'interdit donc de prendre un engagement au nom et pour
          le compte de l'autre Partie à laquelle elle ne saurait en aucun cas se
          substituer, sauf dans les limites nécessaires à l'exécution des
          présentes.
          <br />
          <br />
          Chaque Partie demeure, en outre, intégralement responsable de ses
          prestations et actes.
          <br />
          <br />
          Le Coach comprend et accepte que sa relation avec GYMSPOT ne constitue
          pas une relation salarié/employeur, et qu’il n’agit pas comme agent,
          salarié, préposé ou mandataire de GYMSPOT.
          <br />
          <br />
          Le coach s’engage à réaliser toutes les déclarations et formalités
          nécessaires à son activité, ainsi qu’à satisfaire à toutes ses
          obligations légales, sociales, administratives et fiscales et à toutes
          les obligations spécifiques qui lui incombe le cas échéant en
          application du droit français et/ou de la législation étrangère dont
          il dépend, dans le cadre de son activité et de l’utilisation des
          Services.
          <br />
          <br />
          Le coach est seul responsable du bon accomplissement des formalités
          précitées qui lui incombent.
          <br />
          <br />
          15 16 En conséquence, chacune des Parties répondra de l’ensemble des
          impôts et taxes, des cotisations de sécurité sociale ou des versements
          similaires concernant les sommes reçues en application des présentes.
          <br />
          <br />
          Ainsi GYMSPOT ne pourra en aucun cas être redevable, auprès d’une
          administration ou d’un organisme quelconque, de quelque somme que ce
          soit, qui seraient dues du fait des activités exercées par le Coach,
          via le Site.
          <br />
          <br />
          <strong>*Modification des conditions d’utilisation</strong>
          <br />
          <br />
          GYMSPOT se réserve le droit, à sa seule discrétion, de modifier le
          Site ou de modifier les présentes Conditions à tout moment et sans
          notification préalable.
          <br />
          <br />
          Chaque Coach est invité à consulter régulièrement ces Conditions
          Générales afin de prendre connaissance des changements éventuels
          effectués.
          <br />
          <br />
          Toute modification des présentes Conditions sera publiée sur le Site.
          <br />
          <br />
          En l’absence d’une communication de la part du Coach informant GYMSPOT
          d’un refus des Conditions modifiées dans un délai de sept (7) jours
          suivant la notification par e-mail des modifications apportées, toute
          poursuite de l’utilisation du Site vaudra acceptation par le Coach des
          Conditions modifiées.
          <br />
          <br />
          En cas de refus des Conditions modifiées, le Coach ne pourra plus
          utiliser le Site, ceci avec effet immédiat, mais restera redevable de
          toutes les sommes éventuellement dues à cette date à GYMSPOT.
          <br />
          <br />
          La poursuite de l’utilisation du Site vaudra acceptation par le Coach
          des Conditions modifiées.
          <br />
          <br />
          <strong>
            {' '}
            *Règles relatives à l’ouverture et l’utilisation du Compte par le
            Coach
          </strong>
          <br />
          <br />
          Tout Coach qui souhaite collaborer au fonctionnement du Site doit
          obligatoirement créer un compte personnel en remplissant le formulaire
          d'activation mis à sa disposition sur le Site, et choisir ses
          identifiants, en entrant notamment, un courriel d’utilisateur valide.
          <br />
          <br />
          Toute demande d’inscription incomplète ou erronée ne pourra être
          validée.
          <br />
          <br />
          Le Compte et la page de présentation de chaque Coach sont créés pour
          permettre au Coach l’utilisation du Site, sur la base des données
          qu’il aura fournies.
          <br />
          <br />
          En effet sur la base de ces informations une ou plusieurs pages de
          présentation du Coach seront accessibles en ligne sur le Site.
          <br />
          <br />
          Des photographies pourront être jointes afin d’illustrer cette page de
          présentation, à la condition expresse notamment, que le Coach dispose
          de tous les droits de propriété nécessaire sur les photographies
          proposées.
          <br />
          <br />
          GYMSPOT se réserve le droit de refuser ou supprimer toutes
          informations et illustrations, sans avoir à en justifier auprès du
          Coach.
          <br />
          <br />
          Le Coach garantit fournir des informations exactes, à jour et
          complètes au cours de la procédure d'enregistrement et s’engage à
          mettre à jour, lesdites informations afin d'en préserver l'exactitude,
          la pertinence et l'exhaustivité à tout moment.
          <br />
          <br />
          Au titre de ces informations nécessaires à l’ouverture d’un Compte
          seront notamment demandées :
          <br />
          <br />
          l’identité civile complète et le cas échéant, la dénomination sociale,
          la forme sociale, le n° SIRET, les coordonnées (y compris adresse
          email), les diplômes et cartes professionnelles y attachés,
          l’attestation d’assurance de responsabilité civile professionnelle de
          coach sportif conforme au code du sport.
          <br />
          <br />
          L’attestation devra être fournie et renouvelée tous les ans.
          <br />
          <br />
          GYMSPOT se réserve le droit de :
          <br />
          <br />
          - vérifier les informations fournies par tout moyen,
          <br />
          <br /> - suspendre ou de résilier un Compte et l’accès au Site si le
          Coach ne respecte pas les présentes Conditions, ou si une information
          fournie pendant la procédure d'enregistrement, ou par la suite, se
          révèle inexacte, frauduleuse, obsolète ou incomplète, ou violant de
          quelque autre façon les présentes Conditions ou des droits des tiers.
          <br />
          <br />
          Le Coach est responsable de la protection de son identifiant et de son
          mot de passe.
          <br />
          <br />
          Il devra signaler immédiatement à GYMSPOT toute utilisation non
          autorisée de son Compte, le Coach acceptant dès à présent qu’en pareil
          cas le Site pourra prendre toute mesure appropriée pour faire cesser
          les accès non autorisés.
          <br />
          <br />
          Chaque Compte est personnel au Coach et ne peut être transféré ou cédé
          à aucun tiers.
          <br />
          <br />
          Chaque Coach ne peut créer qu’un seul et unique Compte.
          <br />
          <br />
          Le Coach s'engage à ce que son Compte ne contienne :
          <br />
          <br />
          - aucune information fausse et/ou mensongère,
          <br />
          <br />
          - aucune information ou élément portant atteinte aux droits d'un
          tiers.
          <br />
          <br />
          Dans ce cadre, le Coach déclare et reconnaît qu'il est seul
          responsable des informations renseignées lors de la création de son
          Compte.
          <br />
          <br />
          Il lui appartient d’en vérifier l’exactitude et de signaler
          immédiatement toute erreur à GYMSPOT afin que les corrections
          nécessaires puissent être réalisées.
          <br />
          <br />
          Chaque Coach reconnaît et accepte que GYMSPOT puisse supprimer, à tout
          moment et sans indemnité, un Compte dont le contenu serait contraire
          notamment, à la loi française et/ou aux règles de diffusion fixées par
          les présentes.
          <br />
          <br />
          Le Coach peut fermer à tout moment son Compte, sauf dans le cas où des
          séances de coaching ont été programmées, sauf accord préalable de
          GYMSPOT.
          <br />
          <br />
          La suppression du Compte du Coach sera considérée comme définitive
          après l’envoi par GYMSPOT d’un email de confirmation.
          <br />
          <br />
          <strong> *Notation et avis sur le Coach</strong>
          <br />
          <br /> Chaque Coach pourra, le cas échéant, être noté par les Clients
          à l’issue de chaque séance collective achevée ou individuelle.
          <br />
          <br />
          GYMSPOT se réserve le droit de publier ou non, sur le Site ces
          notations relatives aux Coachs inscrits sur la plateforme GYMSPOT.
          <br />
          <br />
          Dans un tel cas, GYMSPOT ne saurait être responsable au titre de la
          publication de ces commentaires et avis publié sur le Site, et le
          Coach ne pourra réclamer ou obtenir le retrait desdites publication du
          Site.
          <br />
          <br />
          Il pourra notamment, être demandé aux clients d’apprécier, sur la base
          d’un questionnaire de satisfaction, la disponibilité et l’écoute du
          Coach pendant la séance, la qualité de son enseignement, de sa
          pédagogie, etc...
          <br />
          <br />
          En cas de notations ou commentaires négatifs et répétitifs, GYMSPOT
          sera autorisée à suspendre le Compte du Coach concerné, de façon
          définitive ou temporaire, selon sa propre appréciation de la situation
          et sans qu’aucun recours ne puisse être exercé par le Coach à son
          encontre.
          <br />
          <br />
          GYMSPOT se réserve également, en cas de commentaire négatif, le droit
          de contacter directement les Clients afin de recueillir leurs
          témoignages sur les séances réalisées.
          <br />
          <br />
          Elle n’aura pas besoin d’en aviser préalablement, le Coach.
          <br />
          <br />
          Le Coach reconnaît et accepte que toutes les informations recueillies
          dans le cadre des notations et commentaires soient la propriété
          exclusive de GYMSPOT.
          <br />
          <br />
          En outre, le Coach reconnaît et accepte qu’il ne détient aucun droit
          de reproduction, d’affichage, de publication ou autre sur les
          informations et notations publiées sur le Site.
          <br />
          <br />
          Toute utilisation de ces éléments suppose l’accord préalable exprès et
          écrit de GYMSPOT.
          <br />
          <br />
          <strong>*Conditions financières Facturation des Clients</strong>
          <br />
          <br />
          La facture afférente aux prestations commandées par le Client est
          établie et émise par GYMSPOT au nom et pour le compte du Coach,
          conformément au mandat de facturation convenue, sur la base des
          informations fournies par ce dernier.
          <br />
          <br />
          Celle-ci est adressée par GYMSPOT directement au Client via la
          plateforme.
          <br />
          <br />
          Le Coach est tenu de communiquer à GYMSPOT toutes les informations ou
          autres mentions légales devant y figurer conformément à ses
          obligations légales.
          <br />
          <br />
          Chaque facture devra expressément préciser que tous les paiements dus
          en vertu des prestations réalisées après une mise en relation réalisée
          via le Site, doivent s’opérer exclusivement via le Site.
          <br />
          <br />
          Paiement des sommes dues par les Clients opéré exclusivement via le
          Site GYMSPOT est expressément autorisée par le Coach à récolter
          l’ensemble des sommes qui lui sont dues par chaque Client à travers la
          solution de paiement Stripe.
          <br />
          <br />
          Rémunération du Coach Pour chaque commande obtenue grâce au travail de
          mise en relation réalisé par le Site, le Coach versera à GYMSPOT un
          honoraire calculé sur la base d’un pourcentage du montant TTC dû et
          versé par chaque Client et pour laquelle une facture sera
          naturellement établie au nom du Coach par GYMSPOT.
          <br />
          <br />
          Ledit pourcentage sera arrêté directement par GYMSPOT et le Coach lors
          de son inscription.
          <br />
          <br />
          Etant rappelé que l’ensemble des paiements associés à des séances
          réalisées par l’entremise du Site devront transiter par GYMSPOT, c’est
          cette dernière qui réalisera directement les appels de fonds au nom et
          pour le compte du Coach, sur la base des factures établis pour le
          compte de ce dernier.
          <br />
          <br />
          Une fois l’encaissement réalisé via Stripe, GYMSPOT est expressément
          autorisée par le Coach à retenir, sur les paiements des Clients, la
          commission qui lui est due.
          <br />
          <br />
          Cette déduction réalisée, Stripe lui reversera le solde du montant
          acquitté par les Clients revenant au Coach, par virement, aux
          coordonnées bancaires que le Coach lui aura préalablement indiquées
          lors de l’inscription.
          <br />
          <br />
          En cas d’annulation, d’inexécution, de suspension ou de toute
          modification, entrainant une diminution des sommes dues par le Client,
          le Coach ne pourra réclamer à GYMSPOT aucun remboursement des sommes
          qui auraient déjà été perçues au titre des présentes.
          <br />
          <br />
          <strong>*Engagements liés à l’exécution de ses missions</strong>
          <br />
          <br />
          Le Coach accepte de réaliser les séances après réception par GYMSPOT
          de demandes de la part de Clients.
          <br />
          <br />
          Ainsi, le Coach s'engage notamment à ce que ses propositions de
          séances collectives ou individuelle ne contiennent :<br />
          <br /> - aucune information fausse, mensongère ou de nature à induire
          en erreur les Clients,
          <br />
          <br /> - aucune mention de nature à nuire aux intérêts et/ou à l'image
          de GYMSPOT, du Site ou de tout tiers,
          <br />
          <br /> - aucun contenu portant atteinte aux droits de propriété
          intellectuelle de tiers.
          <br />
          <br />
          Le Coach garantit être l'auteur unique et exclusif du contenu de
          chaque proposition.
          <br />
          <br />
          Dans ce cadre, le Coach déclare et reconnaît qu'il est seul
          responsable du contenu des propositions de séances proposées par ses
          soins et qu’il rend accessibles via le Site, ainsi que de toute
          information qu'il transmet.
          <br />
          <br />
          Le Coach s’engage à délivrer les séances commandées via le Site
          conformément aux descriptions et modalités mentionnées, dans le
          respect du calendrier prévu, ainsi que de la législation, des usages
          et règles de l’art de sa profession.
          <br />
          <br />
          Il garantit également que ses réalisations seront conformes notamment,
          aux règles de sécurité et d’assurance s'imposant aux activités
          sportives proposées.
          <br />
          <br />
          Si le Coach était dans l’impossibilité de tenir ses engagements, en ne
          pouvant finalement réaliser les séances prévus dans les propositions
          émises, il devra en assumer l’entière responsabilité, notamment
          financière, et prendre toutes les mesures nécessaires afin que l’image
          du Site et de GYMSPOT ne soit pas entachée par ce manquement.
          <br />
          <br />
          En tout état de cause, il sera tenu d’en informer au plus vite les
          clients concernés et GYMSPOT, afin qu’elle en informe les clients.
          <br />
          <br />
          Si le Coach était définitivement dans l’incapacité d’assumer les
          prestations prévues, il reconnait le droit éventuel, à GYMSPOT de lui
          substituer un autre Coach inscrit sur le site, en accord avec le
          Client concerné.
          <br />
          <br />
          Une telle substitution ne pourra donner lieu à aucun dédommagement au
          profit du Coach défaillant.
          <br />
          <br />
          Un état financier serait alors établi afin de réaliser un bilan
          financier exhaustif, et pouvoir procéder à une facturation finale
          clôturant l’intervention du Coach (facture complémentaire ou avoir
          partiel).
          <br />
          <br />
          D’une façon générale, le Coach devra collaborer activement et de bonne
          foi avec GYMSPOT et les Clients, afin de faciliter les relations et la
          réalisation des prestations commandées.
          <br />
          <br />* Autres engagements du Coach Le Coach devra impérativement
          souscrire les polices d’assurance nécessaires à la réalisation de ses
          prestations, couvrant notamment sa responsabilité vis-à-vis de tous
          tiers, et devra en répondre à GYMSPOT à première demande, via la
          fourniture d’une attestation d’assurance décrivant les garanties
          souscrites et leurs montants.
          <br />
          <br />
          Il devra également fournir, sur simple demande de GYMSPOT, un relevé
          de sinistralité de ses différentes assurances professionnelles.
          <br />
          <br />
          Il assumera l'entière responsabilité des prestations qu’il assure, y
          compris dans l’hypothèse d’une éventuelle sous-traitance agréée par
          GYMSPOT, exclusivement avec un Coach inscrit sur le site (dans cette
          hypothèse, le Coach sous-traitant sera nécessairement soumis à
          l’intégralité des présentes conditions).
          <br />
          <br />
          Il devra, de manière générale, faire son affaire de l’ensemble des
          réclamations des Clients relatives notamment à la qualité des
          prestations réalisées ou des délais d’exécution.
          <br />
          <br />
          GYMSPOT ne saurait être responsable notamment des accidents et
          dommages subis par les Clients ou tout tiers, consécutifs à la
          réalisation des prestations par le Coach.
          <br />
          <br />
          En conséquence le Coach garantit ainsi GYMSPOT contre toute
          condamnation qui pourrait être prononcée à son encontre de ce fait et
          dégage expressément cette dernière de toute responsabilité qu'elle
          pourrait encourir, notamment en cas de réclamation de tiers ou de
          Clients (et cela, quel qu’en soit le motif).
          <br />
          <br />
          Le Coach renonce à exercer tout recours contre GYMSPOT dans le cas de
          poursuites diligentées par un tiers à son encontre du fait de
          l'utilisation et/ou de l'exploitation illicite du Site, en cas de
          perte de ses identifiants ou en cas d'usurpation de son identité.
          <br />
          <br />
          Aucune action ne saurait donc être intentée à ce titre à l’encontre de
          GYMSPOT, le Coach faisant son affaire de l’ensemble des contestations
          portées à l’encontre de GYMSPOT, au titre des prestations commandées
          via le site et/ou exécutées.
          <br />
          <br />
          Le Coach garantit enfin que son adhésion aux présentes Conditions
          Générales et sa collaboration avec le Site ne constituent pas une
          violation d’une quelconque obligation statutaire ou contractuelle
          (notamment d’exclusivité) qu’il aurait pu souscrire auprès d’un tiers,
          ou encore une violation d’une décision d’une autorité administrative,
          judiciaire ou réglementaire qui lui aurait été signifiée ou notifiée
          préalablement à la date de son adhésion au présentes, ou en cours de
          collaboration avec GYMSPOT
          <br />
          <br /> Par ailleurs, le Coach s’interdit : <br />
          <br />• tout comportement de nature à interrompre, suspendre, ralentir
          ou empêcher l’accessibilité au Site,
          <br />
          <br /> • toutes intrusions ou tentatives d’intrusions dans les
          systèmes constituant le Site, y compris le recours à l’exploration de
          données, à des robots ou à d’autres outils similaires de collecte et
          d’extraction de données,
          <br />
          <br />• toutes actions de nature à faire peser sur les infrastructures
          du Site une charge disproportionnée avec ses capacités, <br />
          <br />• toutes atteintes aux mesures de sécurité et/ou
          d’authentification existant sur le Site, <br />
          <br />• tous actes de nature à porter atteinte aux droits et intérêts
          financiers, commerciaux ou moraux de GYMSPOT ou des autres
          utilisateurs du Site (Clients ou autres Coachs), <br />
          <br />• toute commercialisation ou tentative de commercialisation des
          informations présentes sur le Site (qu’elles soient ou non publiques)
          ou de l’accès au Site ou aux services qu’il propose, <br />
          <br />• toute diffusion via le Site de contenus contrevenant à la
          règlementation applicable, attentatoires aux droits de tiers, ou
          contraires à l’ordre public, et notamment tout contenu à caractère
          pornographique, diffamatoire, injurieux, violent, raciste, xénophobe,
          mensonger, trompeur ou proposant ou promouvant des activités illicites
          ou frauduleuses, <br />
          <br />• et enfin plus généralement, tout manquement aux présentes
          Conditions Générales.
          <br />
          <br />
          En cas de violation des engagements ci-dessus, GYMSPOT se réserve le
          droit de prendre toute mesure qu’il jugera nécessaire afin de mettre
          fin au trouble observé, et notamment, il pourra suspendre le Compte du
          Coach sans préavis, refuser la publication ou supprimer tout contenu
          du Coach, ou encore avertir toute autorité concernée.
          <br />
          <br />
          Le Coach garantit GYMSPOT contre toutes plaintes, réclamations,
          actions et/ou revendications quelconques que l’un ou l’autre pourrait
          subir du fait de la violation, par le Coach, de l’une quelconque des
          obligations qu’il a contractées au titre des présentes, et/ou du fait
          des contenus qu’il y diffuse.
          <br />
          <br />
          En conséquence, il devra indemniser intégralement GYMSPOT des
          préjudices subis de son fait, notamment financiers ou en cas de
          revendication de Clients et/ou de tiers.
        </p>
        <br />
        <br />
        <h2>POLITIQUE DE CONFIDENTIALITE ET DONNEES PERSONNELLES</h2>
        <p>
          <strong>
            * CONFIDENTIALITE ET PROTECTION DES DONNEES PERSONNELLES
          </strong>
          <br />
          <br /> Chaque Partie pourra, en application des présentes Conditions,
          avoir accès à des informations confidentielles de l'autre Partie.
          <br />
          <br />
          Les conditions financières et de rémunération des différents acteurs
          du Site seront notamment considérées comme des informations
          confidentielles au titre des présentes.
          <br />
          <br />
          Ne sont pas des informations confidentielles celles qui, en l'absence
          de faute, se trouvent dans le domaine public ; celles dont la Partie
          réceptrice était en possession avant leur communication, sans les
          avoir reçues de l’autre Partie ; celles qui sont communiquées aux
          Parties par des tiers, sans condition de confidentialité ; et celles
          que chaque Partie développe indépendamment.
          <br />
          <br />
          La Partie à qui une information confidentielle sera communiquée en
          préservera le caractère confidentiel avec un soin non inférieur à
          celui qu'elle apporte à la préservation de sa propre information
          confidentielle, et ne pourra les communiquer ou les divulguer à des
          tiers, si ce n'est avec l'accord écrit et préalable de l’autre Partie.
          <br />
          <br />
          Cependant, et dans le cadre des obligations légales en la matière,
          GYMSPOT pourra être amenée à communiquer des informations relatives au
          Coach à la demande des autorités judiciaires ou administratives
          compétentes.
          <br />
          <br />
          Les termes de cette obligation sont valables pendant toute la durée de
          conservation de son Compte par le Coach et pendant les cinq (5) ans
          qui suivront sa fermeture (et cela quelle qu’en soit la raison).
          <br />
          <br />
          L’utilisateur reconnait avoir pris connaissance de la Politique de
          Confidentialité du Site disponible sur celui-ci, et accepter sans
          réserve qu’elle s’applique dans le cadre des présentes et de son
          utilisation du Site.
          <br />
          <br />
          L’utilisateur accepte que les données collectées ou recueillies sur le
          Site soient conservées et utilisées notamment à des fins statistiques
          ou pour répondre à des demandes déterminées ou émanant des pouvoirs
          publics.
          <br />
          <br />
          En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi
          n°2018-493 du 20 juin 2018, il est rappelé que les données nominatives
          demandées à l’Utilisateur sont nécessaires au traitement des
          commandes, aux besoins de la fourniture de services et à
          l'établissement des factures, notamment.
          <br />
          <br />
          Ces données peuvent être communiquées aux éventuels partenaires du
          GYMSPOT, chargés de l'exécution, du traitement, de la gestion et du
          paiement des commandes.
          <br />
          <br />
          Le traitement des informations communiquées par l'intermédiaire du
          site internet GYMSPOT répond aux exigences légales en matière de
          protection des données personnelles, le système d'information utilisé
          assurant une protection optimale de ces données.
          <br />
          <br />
          L’Utilisateur dispose, conformément aux réglementations nationales et
          européennes en vigueur d'un droit d'accès permanent, de modification,
          de rectification, d'opposition de portabilité et de limitation du
          traitement s'agissant des informations le concernant.
          <br />
          <br />
          Ce droit peut être exercé dans les conditions et selon les modalités
          définies sur le site GYMSPOT.
          <br />
          <br />
          <strong>*IMPREVISION – FORCE MAJEURE</strong>
          <br />
          <br /> Les Parties ne pourront être tenues pour responsables si la
          non-exécution ou le retard dans l'exécution de l'une quelconque de
          leurs obligations, telles que décrites dans les présentes découle d’un
          changement de circonstances imprévisibles lors de la souscription de
          la commande ou d'un cas de force majeure, au sens de la Loi et de la
          Jurisprudence.
          <br />
          <br />
          <strong>*DROIT APPLICABLE-LANGUE-LITIGES</strong>
          <br />
          <br /> Les présentes Conditions et les opérations qui en découlent
          sont régies par le droit français.
          <br />
          <br />
          Elles sont rédigées en langue française.
          <br />
          <br />
          Dans le cas où elles seraient traduites en une ou plusieurs langues,
          seul le texte français ferait foi en cas de litige.
          <br />
          <br />
          La conclusion, l’interprétation et la validité des présentes sont
          régies par la loi française.
          <br />
          <br />
          Dans l’hypothèse où un différend portant sur la validité, l’exécution
          ou l’interprétation des présentes et serait porté devant les
          juridictions civiles, il sera soumis aux Juridictions Compétentes dans
          le ressort de la Cour d’Appel, auquel il est fait expressément
          attribution de compétence, même en cas de référé ou de pluralité de
          défendeurs.
          <br />
          <br />
          L’Utilisateur est informé qu'il peut en tout état de cause recourir à
          une médiation conventionnelle ou à tout mode alternatif de règlement
          des différends en cas de contestation.
          <br />
          <br />
          <strong>* DISPOSITIONS DIVERSES</strong>
          <br />
          <br /> Si une partie des présentes Conditions devait s'avérer
          illégale, invalide ou inapplicable, pour quelle que raison que ce
          soit, les dispositions en question seraient réputées non écrites, sans
          remettre en cause la validité des autres dispositions qui continueront
          de s'appliquer entre GYMSPOT et les utilisateurs.{' '}
        </p>
      </p>
    </div>
  )
}
