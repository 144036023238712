import { h, JSX } from 'preact'
import './RadioButton.scss'

interface Props {
  class?: string
  checked?: boolean
  children?: JSX.Element | (JSX.Element | String)[] | string
  onChange?: (val: boolean) => void
}

export default (props: Props) => {
  const onChange = () => {
    props.onChange && props.onChange(!props.checked)
  }

  return (
    <div class={`RadioButton ${props.class || ''}`} onClick={onChange}>
      <div
        class={`RadioButton__radio ${
          props.checked ? 'RadioButton__radio--checked' : ''
        }`}
      ></div>
      {props.children && <div class="RadioButton__label">{props.children}</div>}
    </div>
  )
}
