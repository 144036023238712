import { h, ComponentType } from 'preact'
import './Home.scss'
import IconGeoloc from '../../assets/images/icons/icon_location.svg'
import IconSport from '../../assets/images/icons/icon_sport.svg'
import IconArrow from '../../assets/images/icons/icon_arrow.svg'
import IconMapLocation from '../../assets/images/icons/icon_map_location.svg'
import IconSearch from '../../assets/images/icons/icon_search.svg'
import IconSchedule from '../../assets/images/icons/icon_schedule.svg'
import IconEuro from '../../assets/images/icons/icon_euro.svg'
import IconInstagram from '../../assets/images/icons/icon_instagram_green.svg'
import PhotoPilate from '../../assets/images/home_pilate.png'
import PhotoYoga from '../../assets/images/home_yoga.png'
import PhotoHit from '../../assets/images/home_hit.png'
import PhotoCrossTraining from '../../assets/images/home_cross_training.png'
import PhotoInstagram1 from '../../assets/images/home_instagram_1.png'
import PhotoInstagram2 from '../../assets/images/home_instagram_2.png'
import PhotoInstagram3 from '../../assets/images/home_instagram_3.png'
import PhotoInstagram4 from '../../assets/images/home_instagram_4.png'
import PhotoInstagram5 from '../../assets/images/home_instagram_5.png'
import PhotoInstagram6 from '../../assets/images/home_instagram_6.png'
import Woman from '../../assets/images/woman_home.png'
import { Parallax } from 'react-scroll-parallax'
import RadioButton from '../components/RadioButton'
import { useState, useEffect } from 'preact/hooks'
import CoachingClassPreviewVertical from '../CoachingClasses/CoachingClassPreviewVertical'
import Carousel from '@brainhubeu/react-carousel'
import { RoutableProps, route } from 'preact-router'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import { CoachingClassSession } from '../types/coaching_class_session'
import { CoachingClass } from '../types/coaching_class'
import { Category } from '../types/category'
import { unique } from '../utils'
import store, { Message } from '../services/store'
import Map from '../components/Map'
import Coach from '../types/coach'
import InputWithLabel from '../components/InputWithLabel'
import { UUID } from '../types/common'
import { StandaloneSearchBox } from '@react-google-maps/api'
import MetaTags from 'react-meta-tags'
import IconCoach from '../../assets/images/icons/icon_coach_map.svg'
import moment from 'moment'

export default (_: RoutableProps) => {
  const [useGeoloc, setUseGeoloc] = useState(false)
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(
    undefined
  )
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState<UUID>(undefined)
  const [markers, setMarkers] = useState<
    {
      coachingClass: CoachingClass
      iconUrl: string
      onClick?: () => void
    }[]
  >([])
  const [coachesMarkers, setCoachesMarkers] = useState<
    {
      coach: Coach
      icon: ComponentType
      onClick?: () => void
    }[]
  >([])
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [searchBox, setSearchBox] = useState<any>(undefined)

  useEffect(() => {
    fetchData()
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    let elem = document.querySelector('.Home__search') as HTMLElement

    elem.style.height = window.innerHeight.toString()
    elem.style.width = window.innerWidth.toString()
  }

  useEffect(() => {
    setMarkers(
      sessions
        .filter((s) => {
          const cc = coachingClasses.find((cc) => cc.id == s.coachingClassId)

          if (!coaches.find((c) => c.id == cc.coachId)?.validated) {
            return false
          }
          if (!selectedCategory) {
            return true
          } else {
            return selectedCategory == cc.categoryId
          }
        })
        .map((s) => {
          const cc = coachingClasses.find((cc) => cc.id == s.coachingClassId)

          return {
            coachingClass: {
              ...cc,
              sessions: sessions.filter((s) => s.coachingClassId == cc.id),
              coach: coaches.find((c) => c.id == cc.coachId),
            },
            iconUrl:
              categories.find((c) => c.id == cc.categoryId).iconUrl ||
              categories.find(
                (c) =>
                  c.id ==
                  categories.find((c) => c.id == cc.categoryId).categoryId
              )?.iconUrl,
            onClick: () => route(`/coaching_classes/${cc.id}`),
          }
        })
        .filter(unique)
    )
    setCoachesMarkers(
      [
        ...coaches
          .filter((c) => c.personalCoachingPrice && c.validated && c.coords)
          .map((c) => {
            return {
              coach: c,
              icon: IconCoach,
              onClick: () => route(`/coaches/${c.id}`),
            }
          }),
      ].filter(unique)
    )
  }, [sessions, coachingClasses, selectedCategory])

  const onGeolocSuccess = (pos: any) => {
    var coords = pos.coords

    setMapCenter({ lat: coords.latitude, lng: coords.longitude })
  }

  const onGeolocError = (err: any) => {
    console.error(err)
    store.notify(Message.Error, 'Impossible de récupérer votre position')
  }

  const onUseGeolocChange = (val: boolean) => {
    const geolocOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    if (val) {
      navigator.geolocation.getCurrentPosition(
        onGeolocSuccess,
        onGeolocError,
        geolocOptions
      )
    }
    setUseGeoloc(val)
  }

  const fetchData = async () => {
    await fetchCoaches()
    await fetchCategories()
    await fetchCoachingClasses()
    await fetchSessions()
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      setCoaches(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_ALL_SESSIONS({})
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(
        res.filter((s) => s.date > Date.now()).filter((s) => !s.canceled)
      )
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const goToSearch = () => {
    let url = `/coaching_classes/search?`
    let args = []

    if (selectedCategory) {
      args.push(`categoryId=${selectedCategory}`)
    }
    if (mapCenter) {
      args.push(`mapCenter=${mapCenter.lat},${mapCenter.lng}`)
    }

    route(url + args.join('&'))
  }

  const onSearchBoxLoad = (ref: any) => setSearchBox(ref)

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0]

    if (!place) {
      return
    }
    setMapCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
  }

  return (
    <article class="Home">
      <MetaTags>
        <meta
          name="google-site-verification"
          content="EsDy2wbqTfL3yCdaAnxQ7iomgiPntMao9XEzGU2P2No"
        />
      </MetaTags>
      <div class="Home__content">
        <section class="Home__search">
          <h1 style={{ opacity: 0, position: 'absolute', top: '-1000px' }}>
            Votre coach sportif près de chez vous
          </h1>
          <div class="Home__searchText">
            <Parallax className="Home__searchTextFirst" y={[-35, 35]}>
              <div className="Home__searchTextFirstLine">Votre coach</div>
              <div className="Home__searchTextSecondLine">SPORTIF</div>
            </Parallax>
            <Parallax className="Home__searchImage" y={[-0, 0]}>
              <img src={Woman}></img>
            </Parallax>
            <Parallax className="Home__searchTextSecond" y={[-35, 35]}>
              <div className="Home__searchTextFirstLine">près de</div>
              <div className="Home__searchTextSecondLine">CHEZ VOUS</div>
            </Parallax>
          </div>
          {/* BEGIN -- MOBILE ONLY -- BEGIN */}
          <div class="Home__searchText--mobileVisible">
            Votre coach
            <br />
            SPORTIF
            <br />
            près de
            <br />
            CHEZ VOUS
          </div>
          {/* END -- MOBILE ONLY -- END */}
          <div class="Home__searchBar">
            <div class="Home__searchBarContent">
              <div class="Home__searchBarLocation">
                <IconGeoloc />
                <StandaloneSearchBox
                  onLoad={onSearchBoxLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <InputWithLabel
                    placeholder="11 Avenue des Champs-Élysées, Paris"
                    disableAutocomplete
                  />
                </StandaloneSearchBox>
              </div>
              <div class="Home__searchBarSeparator" />
              <div class="Home__searchBarSport">
                <IconSport />
                <InputWithLabel
                  selectOptions={[
                    { value: undefined, label: 'Tous les sports' },
                    ...categories.map((c) => ({
                      label: c.name,
                      value: c.id,
                    })),
                  ]}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                />
              </div>
              <div class="Home__searchBarButton" onClick={goToSearch}>
                <i>search</i> Rechercher
              </div>
            </div>
            <div class="Home__searchBarGeoloc">
              <RadioButton checked={useGeoloc} onChange={onUseGeolocChange}>
                Autour de chez moi
              </RadioButton>
            </div>
          </div>
        </section>
        {(moment().month() <= 1 && moment().months() >= 0) ||
        (moment().month() >= 9 && moment().month() <= 11) ? (
          <section class="Home__coaches">
            <div class="Home__coachesTitle">
              Les coachs sportifs <span>près de chez vous</span>
            </div>
            <div class="Home__coachesControls">
              <IconArrow
                onClick={() =>
                  (document.querySelector('.Home__coachesContent').scrollLeft =
                    document.querySelector('.Home__coachesContent').scrollLeft -
                    275)
                }
              />
              <IconArrow
                onClick={() =>
                  (document.querySelector('.Home__coachesContent').scrollLeft =
                    document.querySelector('.Home__coachesContent').scrollLeft +
                    275)
                }
              />
            </div>
            <div class="Home__coachesContent">
              {coaches
                .filter(
                  (c) => c.pictureUrl && c.pictureUrl.length > 0 && c.validated
                )
                .map((c) => (
                  <div class="Home__coachesEntry">
                    <a href={`/coaches/${c.id}`}>
                      <img class="Home__coachesEntryPhoto" src={c.pictureUrl} />
                      <div class="Home__coachesEntryName">
                        {c.firstName} {c.lastName}
                      </div>
                      <div class="Home__coachesEntryLocation">
                        <IconGeoloc /> {c.location}
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </section>
        ) : (
          <section class="Home__bestClasses">
            <h2 class="Home__bestClassesTitle">
              Nos meilleurs <span>cours de sport</span> collectif
            </h2>
            <div class="Home__bestClassesControls">
              <IconArrow
                onClick={() =>
                  (document.querySelector(
                    '.Home__bestClassesContent'
                  ).scrollLeft =
                    document.querySelector('.Home__bestClassesContent')
                      .scrollLeft - 275)
                }
              />
              <IconArrow
                onClick={() =>
                  (document.querySelector(
                    '.Home__bestClassesContent'
                  ).scrollLeft =
                    document.querySelector('.Home__bestClassesContent')
                      .scrollLeft + 275)
                }
              />
            </div>
            <div class="Home__bestClassesContent">
              {coachingClasses
                .filter(
                  (cc) =>
                    sessions.find((s) => s.coachingClassId == cc.id) &&
                    coaches.find((c) => c.id == cc.coachId)?.validated
                )
                .map((cc) => (
                  <a href={`/coaching_classes/${cc.id}`}>
                    <CoachingClassPreviewVertical
                      categories={categories}
                      coach={coaches.find((c) => c.id == cc.coachId)}
                      coachingClass={cc}
                      sessions={sessions.filter(
                        (s) => s.coachingClassId == cc.id
                      )}
                    />
                  </a>
                ))}
            </div>
            <a href="/coaching_classes">
              <div class="Home__bestClassesSeeMore">Voir plus de cours</div>
            </a>
          </section>
        )}
        <section class="Home__map">
          <div class="Home__mapContentWrapper">
            <div class="Home__mapContent">
              <h2 class="Home__mapTitle">
                Recherchez un coach sportif <span>près de chez vous</span>
              </h2>
              <Map
                center={mapCenter}
                markers={markers}
                coachesMarkers={coachesMarkers}
                zoom={13}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  zoomControl: false,
                  clickableIcons: false,
                }}
              >
                <div class="Home__mapContentMapSearch">
                  <div class="Home__mapContentMapSearchLeft">
                    <IconSport />
                    <InputWithLabel
                      selectOptions={[
                        { value: undefined, label: 'Tous les sports' },
                        ...categories.map((c) => ({
                          label: c.name,
                          value: c.id,
                        })),
                      ]}
                      value={selectedCategory}
                      onChange={setSelectedCategory}
                    />
                    {/* <input
                      type='text'
                      placeholder='Quel sport recherchez vous ?' */}
                    {/* /> */}
                  </div>
                </div>
              </Map>
            </div>
          </div>
        </section>
        <section class="Home__description">
          <h2 class="Home__descriptionTitle">
            Réservez un cours de sport collectif : Comment{' '}
            <span>ça marche</span>
          </h2>
          <div class="Home__descriptionContent">
            <div class="Home__descriptionItem">
              <div class="Home__descriptionItemIcon">
                <IconMapLocation />
              </div>
              <div class="Home__descriptionItemTitle">Cherchez un cours</div>
              <div class="Home__descriptionItemText">
                Cherchez un cours collectif grâce à la carte intéractive
              </div>
            </div>
            <div class="Home__descriptionItem">
              <div class="Home__descriptionItemIcon">
                <IconSearch />
              </div>
              <div class="Home__descriptionItemTitle">
                Sélectionnez votre cours
              </div>
              <div class="Home__descriptionItemText">
                Sélectionnez votre cours préféré en fonction de vos envies
              </div>
            </div>
            <div class="Home__descriptionItem">
              <div class="Home__descriptionItemIcon">
                <IconSchedule />
              </div>
              <div class="Home__descriptionItemTitle">Réservez votre cours</div>
              <div class="Home__descriptionItemText">
                Réservez le créneau de votre choix en fonction de vos
                disponibilités
              </div>
            </div>
            <div class="Home__descriptionItem">
              <div class="Home__descriptionItemIcon">
                <IconEuro />
              </div>
              <div class="Home__descriptionItemTitle">Payez votre cours</div>
              <div class="Home__descriptionItemText">
                Payez par carte bancaire directement sur notre site
              </div>
            </div>
          </div>
        </section>
        <section class="Home__questions">
          <div class="Home__questionsContent">
            <div class="Home__questionsText">Une question sur nos offres ?</div>
            <a target="_blank" href="mailto:contact@gymspot.fr">
              <div class="Home__questionsButton">Nous contacter</div>
            </a>
          </div>
        </section>
        <section class="Home__categories">
          <div class="Home__categoriesTitle">
            Découvrez <span>nos différentes activités</span> sportives en groupe
          </div>
          <div class="Home__categoriesContent">
            <Carousel
              arrowLeft={<IconArrow />}
              arrowRight={<IconArrow />}
              centered
              infinite
              arrows
              addArrowClickHandler
              autoPlay={5000}
              minDraggableOffset={1}
              slidesPerPage={window.innerWidth > 1000 ? 2 : 1.25}
            >
              <div class="Home__categoriesContentPhoto">
                <a href="/coaching_classes/search?categoryId=7dd43716-c749-4548-8bc8-a79fea6c277b">
                  <img src={PhotoCrossTraining} />
                </a>
              </div>
              <div class="Home__categoriesContentPhoto">
                <a href="/coaching_classes/search?categoryId=1354130d-2931-4745-b2b9-7de6ac8614e0">
                  <img src={PhotoHit} />
                </a>
              </div>
              <div class="Home__categoriesContentPhoto">
                <a href="/coaching_classes/search?categoryId=a7b4a58b-3153-47ca-b330-0155a3369d15">
                  <img src={PhotoPilate} />
                </a>
              </div>
              <div class="Home__categoriesContentPhoto">
                <a href="/coaching_classes/search?categoryId=16ed099a-69bb-4682-bda6-8ad8f928f62d">
                  <img src={PhotoYoga} />
                </a>
              </div>
            </Carousel>
          </div>
        </section>
        {(moment().month() <= 1 && moment().months() >= 0) ||
        (moment().month() >= 9 && moment().month() <= 11) ? (
          <section class="Home__bestClasses">
            <h2 class="Home__bestClassesTitle">
              Nos meilleurs <span>cours de sport</span> collectif
            </h2>
            <div class="Home__bestClassesControls">
              <IconArrow
                onClick={() =>
                  (document.querySelector(
                    '.Home__bestClassesContent'
                  ).scrollLeft =
                    document.querySelector('.Home__bestClassesContent')
                      .scrollLeft - 275)
                }
              />
              <IconArrow
                onClick={() =>
                  (document.querySelector(
                    '.Home__bestClassesContent'
                  ).scrollLeft =
                    document.querySelector('.Home__bestClassesContent')
                      .scrollLeft + 275)
                }
              />
            </div>
            <div class="Home__bestClassesContent">
              {coachingClasses
                .filter(
                  (cc) =>
                    sessions.find((s) => s.coachingClassId == cc.id) &&
                    coaches.find((c) => c.id == cc.coachId)?.validated
                )
                .map((cc) => (
                  <a href={`/coaching_classes/${cc.id}`}>
                    <CoachingClassPreviewVertical
                      categories={categories}
                      coach={coaches.find((c) => c.id == cc.coachId)}
                      coachingClass={cc}
                      sessions={sessions.filter(
                        (s) => s.coachingClassId == cc.id
                      )}
                    />
                  </a>
                ))}
            </div>
            <a href="/coaching_classes">
              <div class="Home__bestClassesSeeMore">Voir plus de cours</div>
            </a>
          </section>
        ) : (
          <section class="Home__coaches">
            <div class="Home__coachesTitle">
              Les coachs sportifs <span>près de chez vous</span>
            </div>
            <div class="Home__coachesControls">
              <IconArrow
                onClick={() =>
                  (document.querySelector('.Home__coachesContent').scrollLeft =
                    document.querySelector('.Home__coachesContent').scrollLeft -
                    275)
                }
              />
              <IconArrow
                onClick={() =>
                  (document.querySelector('.Home__coachesContent').scrollLeft =
                    document.querySelector('.Home__coachesContent').scrollLeft +
                    275)
                }
              />
            </div>
            <div class="Home__coachesContent">
              {coaches
                .filter(
                  (c) => c.pictureUrl && c.pictureUrl.length > 0 && c.validated
                )
                .map((c) => (
                  <div class="Home__coachesEntry">
                    <a href={`/coaches/${c.id}`}>
                      <img class="Home__coachesEntryPhoto" src={c.pictureUrl} />
                      <div class="Home__coachesEntryName">
                        {c.firstName} {c.lastName}
                      </div>
                      <div class="Home__coachesEntryLocation">
                        <IconGeoloc /> {c.location}
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </section>
        )}
        <section class="Home__instagram">
          <div class="Home__instagramTitle">
            Des activités sportives en groupe <span>à la carte</span>
          </div>
          <div class="Home__instagramContent">
            <div class="Home__instagramContentPhotos">
              <div class="Home__instagramContentRow">
                <div class="Home__instagramContentEntry">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram1} />
                  </a>
                </div>
                <div class="Home__instagramContentEntry">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram2} />
                  </a>
                </div>
                <div class="Home__instagramContentEntry Home__instagramContentEntry--large">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram3} />
                  </a>
                </div>
              </div>
              <div class="Home__instagramContentRow">
                <div class="Home__instagramContentEntry Home__instagramContentEntry--large">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram4} />
                  </a>
                </div>
                <div class="Home__instagramContentEntry">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram5} />
                  </a>
                </div>
                <div class="Home__instagramContentEntry">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gymspotfr/"
                  >
                    <img src={PhotoInstagram6} />
                  </a>
                </div>
              </div>
            </div>
            <a target="_blank" href="https://www.instagram.com/gymspotfr/">
              <div class="Home__instagramContentButton">
                Découvrez notre compte Instagram !<IconInstagram />
              </div>
            </a>
          </div>
        </section>
        {!store.store.JWT && (
          <div class="Home__questions">
            <div class="Home__questionsContent">
              <div class="Home__questionsText">
                C'est le moment de nous rejoindre !
              </div>
              <a href="/signup">
                <div class="Home__questionsButton">Inscrivez-vous</div>
              </a>
            </div>
          </div>
        )}
      </div>
    </article>
  )
}
