import { h } from 'preact'
import './RangeFilter.scss'
import { useState } from 'preact/hooks'
import Range from '../../Range'
import { useEffect } from 'react'
import { callOnClickOutside } from '../../../utils'

interface Props {
  values: {
    min: number
    max: number
  }[]
  limits: {
    min: number
    max: number
  }[]
  onChanges: ((v: { min: number; max: number }) => void)[]
  valueLabels?: string[]
  rangeTitles: string[]
  class?: string
  title: string
}

export default (props: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [element, setElement] = useState<HTMLElement>(undefined)

  useEffect(() => {
    callOnClickOutside(close, element)
  }, [element])

  const toggleExpanded = () => setExpanded(!expanded)

  const close = () => setExpanded(false)

  const stopPropagation = (e: any) => e.stopPropagation()

  return (
    <div
      class={`RangeFilter ${props.class ? props.class : ''} ${
        expanded ? 'RangeFilter--expanded' : ''
      }`}
      onClick={toggleExpanded}
    >
      <div class="RangeFilter__title" ref={setElement}>
        {props.title}
      </div>
      {expanded && (
        <div class="RangeFilter__content" onClick={stopPropagation}>
          {props.limits.map((_, i) => (
            <Range
              limits={{ min: props.limits[i].min, max: props.limits[i].max }}
              value={
                props.values[i] && {
                  min: props.values[i].min,
                  max: props.values[i].max,
                }
              }
              onChange={props.onChanges[i]}
              valueLabel={props.valueLabels[i]}
              title={props.rangeTitles[i]}
            />
          ))}
        </div>
      )}
    </div>
  )
}
