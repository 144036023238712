import { h, Fragment } from 'preact'
import './CoachDetails.scss'
import { RoutableProps, route } from 'preact-router'
import { useState } from 'preact/hooks'
import { CoachingClass } from '../types/coaching_class'
import Coach from '../types/coach'
import Rater from 'react-rater'
import { useEffect } from 'react'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import store, { Message } from '../services/store'
import IconArrow from '../../assets/images/icons/icon_arrow.svg'
import IconFacebook from '../../assets/images/icons/icon_facebook_green.svg'
import IconMail from '../../assets/images/icons/icon_mail_green.svg'
import IconPlaceholder from '../../assets/images/icons/icon_person.svg'
import CoachingClassPreviewVertical from '../CoachingClasses/CoachingClassPreviewVertical'
import { Category } from '../types/category'
import { unique } from '../utils'
import CategoryTag from '../components/CategoryTag'
import { CoachingClassSession } from '../types/coaching_class_session'
import { Review } from '../types/review'
import { UUID } from '../types/common'
import { User } from '../types/user'
import ReactPaginate from 'react-paginate'
import MetaTags from 'react-meta-tags'

interface Props extends RoutableProps {
  id?: string
}

export default (props: Props) => {
  const [coach, setCoach] = useState<Coach>(undefined)
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [coachCategories, setCoachCategories] = useState<Category[]>([])
  const [reviews, setReviews] = useState<Review[]>([])
  const [filteredReviews, setFilteredReviews] = useState<Review[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [reviewsOffset, setReviewsOffset] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredReviews(reviews.slice(reviewsOffset, 4))
  }, [reviews, reviewsOffset])

  useEffect(() => {
    filteredReviews.forEach((r) => fetchUser(r.userId))
  }, [filteredReviews])

  useEffect(() => {
    let catIds = coachingClasses.map((cc) => cc.categoryId).filter(unique)

    setCoachCategories(categories.filter((c) => catIds.includes(c.id)))
  }, [categories, coachingClasses])

  useEffect(() => {
    if (coach && !coach.validated) {
      route('/')
    }
  }, [coach])

  const fetchData = async () => {
    await fetchCoachingClasses()
    await fetchSessions()
    await fetchCategories()
    await fetchCoach()
    await fetchReviews()
  }

  const fetchCoach = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH({
          id: props.id,
        })
      )

      setCoach(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger le coach')
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_ALL_SESSIONS({})
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(
        res.filter((s) => s.date > Date.now()).filter((s) => !s.canceled)
      )
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les sessions')
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH_COACHING_CLASSES({
          id: props.id,
        })
      )

      setCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours du coach')
      console.warn(e)
    }
  }

  const fetchReviews = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH_REVIEWS({
          coachId: props.id,
        })
      )

      setReviews(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les avis')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchUser = async (id: UUID) => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USER({ id }))

      setUsers((prev) => [...prev, res])
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les avis')
      console.warn(e)
    }
  }

  const sessionsForCoachingClass = (cc_id: UUID) => {
    return sessions.filter((s) => s.coachingClassId == cc_id)
  }

  const goToDescription = () => {
    document.getElementById('description').scrollIntoView({
      behavior: 'smooth',
    })
  }

  const averageGrade = () => {
    if (reviews.length == 0) {
      return undefined
    }
    return reviews.map((r) => r.rating).reduce((t, e) => t + e) / reviews.length
  }

  const goToPage = ({ selected }: { selected: number }) =>
    setReviewsOffset(selected)

  return (
    coach && (
      <div class="CoachDetails">
        <MetaTags>
          <title>{`Gymspot : Gymspot : tous les cours de sport et coaching personnel de ${coach.firstName} ${coach.lastName}`}</title>
          <meta
            name="description"
            content={`Besoin d’un coach sportif personnel pour vous aider à atteindre vos objectifs ? Découvrez tous les cours proposés par ${coach.firstName} ${coach.lastName} (${coach.location}). Découvrez tous les lieux et les dates proposées.`}
          />
        </MetaTags>
        <div class="CoachDetails__header">
          <div class="CoachDetails__headerImages">
            <div class="CoachDetails__headerImagesCoach">
              <img src={coach.pictureUrl} />
            </div>
          </div>
          <div class="CoachDetails__headerDesc">
            <div class="CoachDetails__headerDescLocation">{coach.location}</div>
            <div class="CoachDetails__headerDescName">
              {coach.firstName} {coach.lastName}
            </div>
            <div class="CoachDetails__headerDescRating">
              {coach.averageGrade && (
                <Fragment>
                  <div class="CoachDetails__headerDescRatingValue">
                    {(coach.averageGrade / 10).toFixed(1)}
                  </div>
                  <Rater rating={averageGrade()} interactive={false} />
                  <div class="CoachDetails__headerDescRatingFeedbacks">
                    <a href="#ratings">{reviews.length} avis</a>
                  </div>
                </Fragment>
              )}
              <div class="CoachDetails__headerDescRatingNetworks">
                <a
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    location.href
                  )}`}
                >
                  <IconFacebook />
                </a>
                <a target="_blank" href={`mailto:?body=${location.href}`}>
                  <IconMail />
                </a>
              </div>
            </div>
            <div class="CoachDetails__headerDescBook" onClick={goToDescription}>
              En savoir plus sur le coach
            </div>
            {coach.personalCoachingPrice &&
              coach.personalCoachingCategories.length > 0 &&
              (store.store.JWT ? (
                <a
                  target="_blank"
                  href={`mailto:contact@gymspot.fr?subject=${store.store.JWT.first_name} ${store.store.JWT.last_name} souhaite prendre du coaching personnel avec ${coach.firstName} ${coach.lastName}&body=Je souhaite prendre du coaching personnel avec ${coach.firstName} ${coach.lastName} afin de ...\nMon numéro de téléphone est le suivant : ...`}
                >
                  <div class="CoachDetails__headerDescBook">
                    Réserver du coaching personnel (
                    {coach.personalCoachingPrice}€ par séance)
                  </div>
                </a>
              ) : (
                <a
                  target="_blank"
                  href={`mailto:contact@gymspot.fr?subject=... souhaite prendre du coaching personnel avec ${coach.firstName} ${coach.lastName}&body=Je souhaite prendre du coaching personnel avec ${coach.firstName} ${coach.lastName} afin de ...\nMon numéro de téléphone est le suivant : ...`}
                >
                  <div class="CoachDetails__headerDescBook">
                    Réserver du coaching personnel (
                    {coach.personalCoachingPrice}€ par séance)
                  </div>
                </a>
              ))}
          </div>
        </div>
        <div class="CoachDetails__content">
          <div class="CoachDetails__contentClasses">
            <h1 class="CoachDetails__contentClassesTitle">
              Les cours proposés{' '}
              <span>
                par {coach.firstName} {coach.lastName}
              </span>
            </h1>
            <div class="CoachDetails__contentClassesControls">
              <IconArrow />
              <IconArrow />
            </div>
            <div class="CoachDetails__contentClassesContent">
              {coachingClasses
                .filter((cc) => sessionsForCoachingClass(cc.id).length > 0)
                .map((cc) => (
                  <a href={`/coaching_classes/${cc.id}`}>
                    <CoachingClassPreviewVertical
                      categories={categories}
                      coach={coach}
                      coachingClass={cc}
                      sessions={sessions.filter(
                        (s) => s.coachingClassId == cc.id
                      )}
                    />
                  </a>
                ))}
            </div>
          </div>
          <div class="CoachDetails__contentDescription" id="description">
            <div class="CoachDetails__contentDescriptionLeft">
              <div class="CoachDetails__contentDescriptionDesc">
                <h2 class="CoachDetails__contentDescriptionDescTitle">
                  Description
                </h2>
                <div class="CoachDetails__contentDescriptionDescContent">
                  {coach.description}
                </div>
              </div>
              <div class="CoachDetails__contentDescriptionQualifications">
                <h2 class="CoachDetails__contentDescriptionQualificationsTitle">
                  Qualifications
                </h2>
                <div class="CoachDetails__contentDescriptionQualificationsContent">
                  {coach.qualifications}
                </div>
              </div>
            </div>
            <div class="CoachDetails__contentDescriptionRight">
              <div class="CoachDetails__contentDescriptionCategories">
                <div class="CoachDetails__contentDescriptionCategoriesTitle">
                  Catégories de sport
                </div>
                <div class="CoachDetails__contentDescriptionCategoriesContent">
                  {coachCategories.map((c) => (
                    <CategoryTag category={c} categories={categories} />
                  ))}
                  {coach.personalCoachingCategories.map((c) => (
                    <CategoryTag
                      category={categories.find((c2) => c2.id == c)}
                      categories={categories}
                      personalCoaching
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {reviews.length > 0 && (
            <div class="CoachDetails__contentReviews" id="ratings">
              <div class="CoachDetails__contentReviewsContent">
                <div class="CoachDetails__contentReviewsTitle">
                  Avis sur {coach.firstName} {coach.lastName}
                </div>
                <div class="CoachDetails__contentReviewsSumup">
                  <div class="CoachDetails__contentReviewsSumupAverage">
                    <span>{(coach.averageGrade / 10).toFixed(1)}</span>
                    <Rater rating={averageGrade()} interactive={false} />
                  </div>
                  <div class="CoachDetails__contentReviewsSumupCount">
                    {reviews.length} avis
                  </div>
                </div>
                <div class="CoachDetails__contentReviewsEntries">
                  {filteredReviews.map((r) => {
                    let u = users.find((u) => u.id == r.userId)

                    return (
                      u && (
                        <div class="CoachDetails__contentReviewsEntry">
                          <div class="CoachDetails__contentReviewsEntryLeft">
                            <div class="CoachDetails__contentReviewsEntryLeftPhoto">
                              {u.pictureUrl && u.pictureUrl.length > 0 ? (
                                <img src={u.pictureUrl} />
                              ) : (
                                <IconPlaceholder />
                              )}
                            </div>
                            <div class="CoachDetails__contentReviewsEntryLeftName">
                              {u.firstName} {u.lastName}
                            </div>
                            <div class="CoachDetails__contentReviewsEntryLeftRating">
                              <Rater rating={r.rating} interactive={false} />
                            </div>
                          </div>
                          <div class="CoachDetails__contentReviewsEntryRight">
                            {r.comment || ''}
                          </div>
                        </div>
                      )
                    )
                  })}
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={reviews.length / 4}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={goToPage}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}
