import { h } from 'preact'
import './MyPaymentMethods.scss'
import Button from '../../components/Button'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import IconCreditCard from '../../../assets/images/icons/icon_credit_card.svg'
import IconAmex from '../../../assets/images/icons/icon_amex.svg'
import IconVisa from '../../../assets/images/icons/icon_visa.svg'
import IconMastercard from '../../../assets/images/icons/icon_mastercard.svg'
import IconClose from '../../../assets/images/icons/icon_remove.svg'
import CreditCard from '../../types/credit_card'

interface Props {}

export default (props: Props) => {
  const [cards, setCards] = useState<CreditCard[]>([])

  useEffect(() => {
    fetchCreditCards()
  }, [])

  const fetchCreditCards = async () => {
    try {
      const ccs = await httpClient.req(ROUTES.FETCH_MY_CREDIT_CARDS({}))

      setCards(ccs.data.map((cc: any) => ({ ...cc.card, stripeId: cc.id })))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer les cartes')
      console.warn(e)
    }
  }

  const deleteCard = (card: CreditCard) => async () => {
    try {
      await httpClient.req(
        ROUTES.DELETE_CREDIT_CARD({ stripeId: card.stripeId })
      )

      setCards(cards.filter((c) => c.stripeId != card.stripeId))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de supprimer la carte')
      console.warn(e)
    }
  }

  return (
    <div class="MyPaymentMethods">
      <div class="MyPaymentMethods__title">Mes moyens de paiement</div>
      <div class="MyPaymentMethods__content">
        <div class="MyPaymentMethods__contentCards">
          {cards.length == 0 ? (
            <div class="MyPaymentMethods__contentCardsPlaceholder">
              Aucun moyen de paiement enregistré
              <br />
              <span>
                Vous pourrez l'enregistrer lors de votre 1ère commande
              </span>
            </div>
          ) : (
            cards.map((c) => {
              let Image = IconCreditCard

              if (c.brand == 'mastercard') {
                Image = IconMastercard
              } else if (c.brand == 'visa') {
                Image = IconVisa
              } else if (c.brand == 'amex') {
                Image = IconAmex
              }
              return (
                <div class="MyPaymentMethods__contentCardsEntry">
                  <div class="MyPaymentMethods__contentCardsEntryLeft">
                    <div class="MyPaymentMethods__contentCardsEntryIcon">
                      <Image />
                    </div>
                    <div class="MyPaymentMethods__contentCardsEntryInfos">
                      <div class="MyPaymentMethods__contentCardsEntryLast4">
                        **** **** **** {c.last4}
                      </div>
                      <div class="MyPaymentMethods__contentCardsEntryExpiration">
                        Date d'expiration : {c.expMonth}/{c.expYear}
                      </div>
                    </div>
                  </div>
                  <div class="MyPaymentMethods__contentCardsEntryRight">
                    <div class="MyPaymentMethods__contentCardsEntryDelete">
                      <Button onClick={deleteCard(c)}>
                        <IconClose /> Supprimer
                      </Button>
                    </div>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}
