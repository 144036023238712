import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import './FAQ.scss'

export default (_: RoutableProps) => {
  return (
    <div class="FAQ">
      <h1 class="FAQ__title">FAQ</h1>
      <div class="FAQ__entry">
        <h2 class="FAQ__entryQuestion">
          Pourquoi s’entrainer avec un coach sportif ?
        </h2>
        <div class="FAQ__entryAnswer">
          Les avantages de s’entrainer avec un coach sportif sont nombreux.
          L’encadrement de votre séance de sport avec un coach sportif
          professionnel vous permettra de faire votre cours de sport en toute
          sécurité, en ayant une bonne technique d’exécution sur les mouvements
          et en ayant une logique d’entrainement adaptée à vos capacités.
          L’entrainement avec un coach sportif est personnalisé, ce qui vous
          permet d’atteindre vos objectifs plus rapidement. Aussi, lorsqu’un
          manque de motivation se fait ressentir, le coach est là pour vous
          booster et vous aidera à garder vos objectifs en ligne de mire.
        </div>
      </div>
      <div class="FAQ__entry">
        <h2 class="FAQ__entryQuestion">Comment Gymspot recrute les coachs ?</h2>
        <div class="FAQ__entryAnswer">
          Gymspot veille à ce que tous les coachs sportifs soient diplômé
          d’Etat. Les coachs sont tenus de renseigner leurs spécialités afin de
          répondre au mieux aux objectifs des pratiquants et d’offrir un panel
          de cours variés. L’équipe Gymspot a également mis en place un système
          de rédaction d’avis et de notation des coachs qui permet aux
          pratiquants d’évaluer la prestation de chaque coach sportif. Ainsi,
          une bonne note d’un coach garantit un engagement qualité.
        </div>
      </div>
      <div class="FAQ__entry">
        <h2 class="FAQ__entryQuestion">
          Quel est le tarif d’un coach sportif ?
        </h2>
        <div class="FAQ__entryAnswer">
          Les cours de sports collectifs sont généralement moins onéreux que les
          cours de coaching individuel car les cours individuels sont plus
          personnalisés. Les coachs fixent eux même leurs tarifs. Pour certains
          coachs qui sont agrées « service à la personne », vous pourriez
          bénéficier de 50% de réduction d’impôts. Cette mention est préciser
          sur le profil du coach que vous pouvez filtrer dans votre recherche.
        </div>
      </div>
      <div class="FAQ__entry">
        <h2 class="FAQ__entryQuestion">
          Quels cours de sport sont-ils proposés ?
        </h2>
        <div class="FAQ__entryAnswer">
          Un panel assez large vous est proposé afin de répondre à toutes vos
          envies et objectifs. Que se soit des objectifs de perte de poids, de
          renforcement musculaire, de détente, de prise de masse, etc. Pilates,
          yoga, cardio training, hiit cardio, vous avez le choix !
        </div>
      </div>
      <div class="FAQ__entry">
        <h2 class="FAQ__entryQuestion">
          Quels sont les bienfaits du sport sur la santé ?
        </h2>
        <div class="FAQ__entryAnswer">
          Les bienfaits du sport sur la santé sont aujourd’hui nombreux et
          démontrés : La diminution du risque d’accident cardiovasculaire, de
          cancer, de stress, de dépression, d’obésité, de diabète, etc. La
          pratique sportive vous permet également d’avoir plus confiance en
          vous, d’être plus épanouie tant dans votre vie personnelle que
          professionnelle. C’est d’ailleurs pour cela que le sport en entreprise
          est de plus en plus répandu.
        </div>
      </div>
    </div>
  )
}
