import { h } from 'preact'
import './MyAccount.scss'
import { RoutableProps } from 'preact-router'
import { useState } from 'preact/hooks'
import { User } from '../types/user'
import { useEffect } from 'react'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import store, { Message } from '../services/store'
import IconPerson from '../../assets/images/icons/icon_person_alt.svg'
import IconCheck from '../../assets/images/icons/icon_check.svg'
import IconClose from '../../assets/images/icons/icon_remove.svg'
import IconCog from '../../assets/images/icons/icon_cog.svg'
import IconCart from '../../assets/images/icons/icon_cart.svg'
import IconCard from '../../assets/images/icons/icon_card.svg'
import IconInfosCoach from '../../assets/images/icons/icon_infos_coach.svg'
import IconCoach from '../../assets/images/icons/icon_coach.svg'
import IconCoachingClasses from '../../assets/images/icons/icon_coaching_classes.svg'
import IconCheckFilled from '../../assets/images/icons/icon_check_filled.svg'
import MenuEntry from './MenuEntry'
import PersonalInfos from './PersonalInfos'
import Optins from './Optins'
import CoachInfos from './CoachInfos'
import Coach from '../types/coach'
import CoachDocument from '../types/coach_document'
import CoachProfile from './CoachProfile'
import MyCoachingClasses from './MyCoachingClasses'
import MyBookings from './MyBookings'
import MyPaymentMethods from './MyPaymentMethods'
import Registrations from './Registrations'
import { Category } from '../types/category'

enum Tab {
  PersonnalInfos,
  CoachInfos,
  CoachProfile,
  CoachingClasses,
  Registrations,
  Bookings,
  PaymentMethods,
  Optins,
}

interface Props extends RoutableProps {
  matches?: { tab?: string; selected?: string }
  showMenu: boolean
  toggleShowMenu: () => void
}

export default (props: Props) => {
  const [me, setMe] = useState<User>(undefined)
  const [coach, setCoach] = useState<Coach>(undefined)
  const [categories, setCategories] = useState<Category[]>([])
  const [coachDocuments, setCoachDocuments] = useState<CoachDocument[]>([])
  const [tab, setTab] = useState<Tab>(Tab.PersonnalInfos)

  useEffect(() => {
    fetchMe()

    if (store.store.JWT.role == 'coach') {
      fetchCategories()
      fetchCoach()
    }

    if (props.matches.tab == 'personal_infos') {
      setTab(Tab.PersonnalInfos)
    } else if (props.matches.tab == 'coach_infos') {
      setTab(Tab.CoachInfos)
    } else if (props.matches.tab == 'coach_profile') {
      setTab(Tab.CoachProfile)
    } else if (props.matches.tab == 'bookings') {
      setTab(Tab.Bookings)
    } else if (props.matches.tab == 'payment_methods') {
      setTab(Tab.PaymentMethods)
    } else if (props.matches.tab == 'optins') {
      setTab(Tab.Optins)
    } else if (props.matches.tab == 'my_coaching_classes') {
      setTab(Tab.CoachingClasses)
    } else if (props.matches.tab == 'registrations') {
      setTab(Tab.Registrations)
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    let elem = document.querySelector('.MyAccount__content') as HTMLElement
    let menu = document.querySelector('.MyAccount__menu') as HTMLElement

    if (elem) {
      elem.style.height = window.innerHeight.toString()
      elem.style.width = window.innerWidth.toString()
    }
    if (menu && window.innerWidth <= 1000) {
      menu.style.height = (window.innerHeight - 80).toString()
    }
  }

  useEffect(() => {
    if (me && me.pictureUrl && me.pictureUrl.length > 0) {
      updateMe()
    }
  }, [me?.pictureUrl])

  useEffect(() => {
    window.scrollTo(0, 0)
    props.toggleShowMenu()
  }, [tab])

  const fetchMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ME({}))

      setMe(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer le profil')
      console.warn(e)
    }
  }

  const fetchCoach = async () => {
    try {
      const c = await httpClient.req(ROUTES.FETCH_ME_COACH({}))
      const cds = await httpClient.req(ROUTES.FETCH_ME_COACH_DOCUMENTS({}))

      setCoach(c)
      setCoachDocuments(cds)
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de récupérer les informations coach'
      )
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const uploadPhoto = async (e: any) => {
    const file = e.target.files[0]

    try {
      if (file.size / 1024 / 1024 <= 10) {
        const res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

        setMe({ ...me, pictureUrl: res.fileUrl })

        return res.fileUrl
      } else {
        store.notify(
          Message.Error,
          'La taille max pour les fichiers est de 10Mo'
        )
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible d'uploader la photo")
      console.warn(e)
    }
  }

  const updateMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.UPDATE_ME(me))

      setMe(res)
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de sauvegarder la photo de profil'
      )
      console.warn(e)
    }
  }

  const disconnect = () => {
    store.notify(Message.NeedAuth)
  }

  return (
    me && (
      <div class="MyAccount">
        <div
          class={`MyAccount__menu${
            props.showMenu ? ' MyAccount__menu--visible' : ''
          }`}
          ref={updateDimensions}
        >
          <div class="MyAccount__menuWrapper">
            <div class="MyAccount__menuTop">
              <div class="MyAccount__menuPhoto">
                {me.pictureUrl && me.pictureUrl.length > 0 && (
                  <img src={me.pictureUrl} />
                )}
                <label>
                  <input type="file" onChange={uploadPhoto} />
                  <div class="MyAccount__menuPhotoUpload">
                    Changer la photo de profil
                  </div>
                </label>
              </div>
              <div class="MyAccount__menuName">
                {me.firstName} {me.lastName}
              </div>
              <div class="MyAccount__menuEmail">{me.email}</div>
            </div>
            <div class="MyAccount__menuSeparator"></div>
            <div class="MyAccount__menuEntries">
              <MenuEntry
                focused={tab == Tab.PersonnalInfos}
                title="Mes informations personnelles"
                icon={IconPerson}
                onClick={() => setTab(Tab.PersonnalInfos)}
              />
              {store.store.JWT.role == 'coach' && coach && (
                <MenuEntry
                  focused={tab == Tab.CoachInfos}
                  title="Mes documents"
                  icon={IconInfosCoach}
                  onClick={() => setTab(Tab.CoachInfos)}
                  iconRight={coach.validated ? IconCheck : IconClose}
                  class={
                    coach.validated
                      ? 'MenuEntry--validated'
                      : 'MenuEntry--notValidated'
                  }
                />
              )}
              {store.store.JWT.role == 'coach' && coach && (
                <MenuEntry
                  focused={tab == Tab.CoachProfile}
                  title="Ma fiche coach"
                  icon={IconCoach}
                  onClick={() => setTab(Tab.CoachProfile)}
                />
              )}
              {store.store.JWT.role == 'coach' && coach && (
                <MenuEntry
                  focused={tab == Tab.CoachingClasses}
                  title="Mes cours"
                  icon={IconCoachingClasses}
                  onClick={() => setTab(Tab.CoachingClasses)}
                />
              )}
              {store.store.JWT.role == 'coach' && coach && (
                <MenuEntry
                  focused={tab == Tab.Registrations}
                  title="Mes inscrits"
                  icon={IconCheckFilled}
                  negative
                  onClick={() => setTab(Tab.Registrations)}
                />
              )}
              <MenuEntry
                focused={tab == Tab.Bookings}
                title="Mes réservations"
                icon={IconCart}
                onClick={() => setTab(Tab.Bookings)}
              />
              <MenuEntry
                focused={tab == Tab.PaymentMethods}
                title="Mes moyens de paiement"
                icon={IconCard}
                onClick={() => setTab(Tab.PaymentMethods)}
              />
              <MenuEntry
                focused={tab == Tab.Optins}
                title="Mes abonnements newsletter"
                icon={IconCog}
                onClick={() => setTab(Tab.Optins)}
              />
              <MenuEntry
                title="Déconnexion"
                onClick={disconnect}
                class="MyAccount__disconnect"
              />
            </div>
          </div>
        </div>
        <div
          class={`MyAccount__content${
            props.showMenu ? ' MyAccount__content--hidden' : ''
          }`}
        >
          {tab == Tab.PersonnalInfos && (
            <PersonalInfos user={me} onChange={setMe} />
          )}
          {tab == Tab.CoachInfos && coach && (
            <CoachInfos
              coach={coach}
              documents={coachDocuments}
              onChange={setCoach}
            />
          )}
          {tab == Tab.CoachProfile && coach && (
            <CoachProfile
              categories={categories}
              coach={coach}
              onChange={setCoach}
            />
          )}
          {tab == Tab.CoachingClasses && coach && (
            <MyCoachingClasses coach={coach} />
          )}
          {tab == Tab.Optins && <Optins user={me} onChange={setMe} />}
          {tab == Tab.Bookings && (
            <MyBookings selected={props.matches.selected} />
          )}
          {tab == Tab.PaymentMethods && <MyPaymentMethods />}
          {tab == Tab.Registrations && coach && <Registrations coach={coach} />}
        </div>
      </div>
    )
  )
}
