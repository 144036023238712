import { h } from 'preact'
import './MultiselectFilter.scss'
import { useState, useEffect } from 'preact/hooks'
import Multiselect from '../../Multiselect'
import { callOnClickOutside } from '../../../utils'

interface Props<T> {
  options: T[]
  selected?: T[]
  onChange: (s: T[]) => void
  class?: string
  placeholder?: string
  title: string
  identifier: string
  optionsLabel: string
}

export default <T extends any>(props: Props<T>) => {
  const [expanded, setExpanded] = useState(false)
  const [element, setElement] = useState<HTMLElement>(undefined)

  useEffect(() => {
    callOnClickOutside(close, element)
  }, [element])

  const toggleExpanded = () => setExpanded(!expanded)

  const close = () => setExpanded(false)

  return (
    <div
      class={`MultiselectFilter ${
        props.selected.length > 0 ? 'MultiselectFilter--withSelection' : ''
      } ${props.class ? props.class : ''} ${
        expanded ? 'MultiselectFilter--expanded' : ''
      }`}
      onClick={toggleExpanded}
    >
      <div class="MultiselectFilter__title" ref={setElement}>
        {props.title}
        {props.selected.length > 0 && (
          <div class="MultiselectFilter__titleBadge">
            {props.selected.length}
          </div>
        )}
      </div>
      {expanded && (
        <Multiselect
          options={props.options}
          selected={props.selected}
          optionsLabel={props.optionsLabel}
          onChange={props.onChange}
          identifier={props.identifier}
          placeholder={props.placeholder || props.title}
        />
      )}
    </div>
  )
}
