import { h } from 'preact'
import IconCheck from '../../../assets/images/icons/icon_check.svg'
import IconCross from '../../../assets/images/icons/icon_remove.svg'
import './Switch.scss'

interface Props {
  label?: string
  on?: boolean
  onChange?: (v: boolean) => void
}

export default (props: Props) => {
  const onChange = () => {
    props.onChange && props.onChange(!props.on)
  }

  return (
    <div class={`Switch ${props.on ? 'Switch--on' : ''}`} onClick={onChange}>
      <div class="Switch__switch">
        <div class="Switch__switchInner">
          {props.on ? <IconCheck /> : <IconCross />}
        </div>
      </div>
      {props.label && <div class="Switch__label">{props.label}</div>}
    </div>
  )
}
