import { h } from 'preact'
import './ForgottenPassword.scss'
import { RoutableProps, route } from 'preact-router'
import Input from '../../components/Input'
import { useState } from 'preact/hooks'
import Button from '../../components/Button'
import ROUTES from '../../routes'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'

interface Props extends RoutableProps {
  resetPassword?: boolean
  matches?: { token: string }
}

export default (props: Props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const askReset = async () => {
    try {
      await httpClient.req(
        ROUTES.ASK_RESET_PASSWORD({
          email,
        })
      )
      route('/signin')
      store.notify(
        Message.Notification,
        'La demande de réinitialisation du mot de passe a été effectuée. Veuillez vérifier votre boite mail.'
      )
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de procéder à la réinitialisation'
      )
      console.warn(e)
    }
  }

  const submitReset = async () => {
    try {
      await httpClient.req(
        ROUTES.SUBMIT_RESET_PASSWORD({
          token: props.matches?.token,
          password,
        })
      )
      route('/signin')
      store.notify(Message.Notification, 'Mot de passe modifié avec succès')
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de procéder à la réinitialisation'
      )
      console.warn(e)
    }
  }

  return (
    <div class="ForgottenPassword">
      <div class="ForgottenPassword__content">
        <div class="ForgottenPassword__title">
          <div class="ForgottenPassword__titleTop">
            {props.resetPassword ? 'Réinitialisation du' : 'Mot de passe'}
          </div>
          <div class="ForgottenPassword__titleBottom">
            {props.resetPassword ? 'Mot de passe' : 'Oublié'}
          </div>
        </div>
        <div class="ForgottenPassword__form">
          <div class="ForgottenPassword__formMiddle">
            {props.resetPassword ? (
              <Input
                placeholder="Nouveau mot de passe *"
                type="password"
                onChange={setPassword}
              />
            ) : (
              <Input placeholder="EMAIL *" type="email" onChange={setEmail} />
            )}
          </div>
          <Button
            class="ForgottenPassword__submit"
            shadow
            onClick={props.resetPassword ? submitReset : askReset}
          >
            Valider
          </Button>
        </div>
      </div>
    </div>
  )
}
