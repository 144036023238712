import { h, Fragment } from 'preact'
import Input from '../../components/Input'
import { useState, useContext } from 'preact/hooks'
import Button from '../../components/Button'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import './Business.scss'
import store, { Message, Stored } from '../../services/store'
import { StepperContext } from 'react-material-stepper'
import IconPerson from '../../../assets/images/icons/icon_person.svg'
import FileInput from '../../components/FileInput'
import { route } from 'preact-router'

export default () => {
  const [photo, setPhoto] = useState(undefined)
  const [identityCard, setIdentityCard] = useState(undefined)
  const [professionnalCard, setProfessionalCard] = useState(undefined)
  const [professionalCivilInsurance, setProfessionalCivilInsurance] = useState(
    undefined
  )
  const { getData } = useContext(StepperContext)

  const onValidate = async () => {
    try {
      const data = getData('infos')

      await httpClient.req(
        ROUTES.CREATE_COACH({
          firstName: data.firstName,
          lastName: data.lastName,
          pictureUrl: photo,
        })
      )

      let creds = await httpClient.req(
        ROUTES.REFRESH_JWT({ token: httpClient.token })
      )
      const [rawJwt, jwt, token] = httpClient.storeCreds(creds)

      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.update(Stored.RefreshToken, token)

      await httpClient.req(ROUTES.UPDATE_ME({ pictureUrl: photo }))
      if (identityCard) {
        await httpClient.req(
          ROUTES.CREATE_COACH_DOCUMENT({
            documentType: 'identity_card',
            file_url: identityCard,
          })
        )
      }
      if (professionnalCard) {
        await httpClient.req(
          ROUTES.CREATE_COACH_DOCUMENT({
            documentType: 'professional_card',
            file_url: professionnalCard,
          })
        )
      }
      if (professionalCivilInsurance) {
        await httpClient.req(
          ROUTES.CREATE_COACH_DOCUMENT({
            documentType: 'professional_civil_insurance',
            file_url: professionalCivilInsurance,
          })
        )
      }
      route('/my_account?tab=coach_infos')
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder les informations')
      console.warn(e)
    }
  }

  const onProfessionalCardChange = async (file: File) => {
    setProfessionalCard(await uploadFile(file))
  }

  const onIdentityCardChange = async (file: File) => {
    setIdentityCard(await uploadFile(file))
  }

  const onProfessionalCivilInsuranceChange = async (file: File) => {
    setProfessionalCivilInsurance(await uploadFile(file))
  }

  const onPhotoChange = async (e: any) => {
    setPhoto(await uploadFile(e.target.files[0]))
  }

  const uploadFile = async (file: File) => {
    try {
      if (file.size / 1024 / 1024 <= 10) {
        const res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

        return res.fileUrl
      } else {
        store.notify(
          Message.Error,
          'La taille max pour les fichiers est de 10Mo'
        )
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible d'uploader le fichier")
      console.warn(e)
    }
  }

  return (
    <div class="CoachSignupBusiness">
      <div class="CoachSignupBusiness__title">Pièces à importer</div>
      <div class="CoachSignupBusiness__form">
        <div class="CoachSignupBusiness__formPhoto">
          <label>
            <input type="file" onChange={onPhotoChange}></input>
            <div class="CoachSignupBusiness__formPhotoWrapper">
              {photo ? (
                <img src={photo} />
              ) : (
                <Fragment>
                  <IconPerson />
                  Photo
                </Fragment>
              )}
            </div>
          </label>
        </div>
        <div class="CoachSignupBusiness__formRow CoachSignupBusiness__formFiles">
          <FileInput
            filled={!!identityCard}
            onChange={onIdentityCardChange}
            label="Carte d'identité"
            onDelete={() => setIdentityCard(undefined)}
          />
          <FileInput
            onChange={onProfessionalCardChange}
            label="Carte professionnelle"
            filled={!!professionnalCard}
            onDelete={() => setProfessionalCard(undefined)}
          />
        </div>
        <div class="CoachSignupBusiness__formRow CoachSignupBusiness__formFiles">
          <FileInput
            filled={!!professionalCivilInsurance}
            onChange={onProfessionalCivilInsuranceChange}
            label="Assurance civile professionnelle"
            onDelete={() => setProfessionalCivilInsurance(undefined)}
          />
        </div>
        <div class="CoachSignupBusiness__actions">
          <Button shadow onClick={onValidate}>
            Enregistrer
          </Button>
          <Button shadow onClick={onValidate}>
            Compléter plus tard
          </Button>
        </div>
      </div>
    </div>
  )
}
