import { h } from 'preact'
import './CoachPreview.scss'
import StarIcon from '../../assets/images/icons/icon_star.svg'
import LocationIcon from '../../assets/images/icons/icon_location.svg'
import Coach from '../types/coach'
import CheckIcon from '../../assets/images/icons/icon_check.svg'
import { Category } from '../types/category'

interface Props {
  coach: Coach
  categories: Category[]
  onClick?: (c: Coach) => void
}

export default (props: Props) => {
  const { coach } = props

  const onClick = () => props.onClick && props.onClick(coach)

  return (
    <div class="CoachPreview" onClick={onClick}>
      <div class="CoachPreview__photo">
        <img src={coach.pictureUrl} class="CoachPreview__photoBackground" />
      </div>
      <div class="CoachPreview__infos">
        <div class="CoachPreview__infosLeft">
          <div class="CoachPreview__infosLeftName">
            {coach.firstName} {coach.lastName}
            <div class="CoachPreview__infosLeftCategories">
              {props.categories
                .filter((c) =>
                  props.coach.personalCoachingCategories.includes(c.id)
                )
                .map((c) => c.name)
                .join(', ')}
            </div>
          </div>
          <div class="CoachPreview__infosLeftLocation">
            <LocationIcon />
            {coach.location}
          </div>
          {coach.taxDeduction && (
            <div class="CoachPreview__infosLeftTaxDeduction">
              <CheckIcon />
              Déduction d'impôts
            </div>
          )}
        </div>
        <div class="CoachPreview__infosRight">
          {props.coach.averageGrade > 0 && (
            <div class="CoachPreview__infosRightRating">
              {(props.coach.averageGrade / 10).toFixed(1)} <StarIcon />
            </div>
          )}
          <div class="CoachPreview__infosRightPrice">
            {coach.personalCoachingPrice}€
          </div>
        </div>
      </div>
    </div>
  )
}
