import { h, Fragment, ComponentType } from 'preact'
import { UUID } from '../../types/common'
import { RoutableProps, route } from 'preact-router'
import './CoachingClassesList.scss'
import CoachingClassPreviewHorizontal from '../CoachingClassPreviewHorizontal'
import { useState, useEffect } from 'react'
import { Category } from '../../types/category'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { CoachingClass } from '../../types/coaching_class'
import FiltersHandler, {
  Filters,
  ClassType,
} from '../../components/FiltersHandler'
import Coach from '../../types/coach'
import { CoachingClassSession } from '../../types/coaching_class_session'
import Map from '../../components/Map'
import { unique } from '../../utils'
import moment from 'moment'
import { getDistance } from 'geolib'
import Button from '../../components/Button'
import CoachPreview from '../../CoachPreview'
import IconCoach from '../../../assets/images/icons/icon_coach_map.svg'
import Loader from 'react-loader-spinner'

interface Props extends RoutableProps {
  matches?: {
    query?: string
    categoryId?: UUID
    mapCenter?: string
    city?: string
    classType: string
  }
}

export default (props: Props) => {
  const [loading, setLoading] = useState(true)
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [coachingClassesWithCity, setCoachingClassesWithCity] = useState<any>(
    []
  )
  const [showMap, setShowMap] = useState(false)
  const [filteredCoachingClasses, setFilteredCoachingClasses] = useState<
    CoachingClass[]
  >([])
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(
    undefined
  )
  const [markers, setMarkers] = useState<
    {
      coachingClass: CoachingClass
      iconUrl: string
      onClick?: () => void
    }[]
  >([])
  const [coachesMarkers, setCoachesMarkers] = useState<
    {
      coach: Coach
      icon: ComponentType
      onClick?: () => void
    }[]
  >([])
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [
    coachingClassesWithSessions,
    setCoachingClassesWithSessions,
  ] = useState<CoachingClass[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [filteredCoaches, setFilteredCoaches] = useState<Coach[]>([])
  const [cities, setCities] = useState([])
  const [filters, setFilters] = useState({
    categories: [],
    cities: [],
    coaches: [],
    time: undefined,
    price: undefined,
    classTypes: [],
    taxDeduction: [],
  })

  useEffect(() => {
    fetchData()

    if (props.matches?.categoryId) {
      setFilters((prev) => ({
        ...prev,
        categories: [{ id: props.matches.categoryId }],
      }))
    }
    if (props.matches?.classType) {
      let types = props.matches.classType.split(',')
      let computedTypes: { name: string; id: ClassType }[] = []

      types.forEach((t) => {
        if (t == 'personal') {
          computedTypes.push({
            name: 'Coaching individuel',
            id: ClassType.PersonalCoaching,
          })
        } else if (t == 'coaching_class') {
          computedTypes.push({
            name: 'Cours collectifs',
            id: ClassType.CoachingClass,
          })
        }
      })
      setFilters((prev) => ({
        ...prev,
        classTypes: computedTypes,
      }))
    }
    if (props.matches?.city) {
      setFilters((prev) => ({
        ...prev,
        cities: [{ city: props.matches.city }],
      }))
    }
    if (props.matches?.mapCenter) {
      let coords = props.matches?.mapCenter.split(',')

      if (coords.length == 2) {
        setTimeout(
          () => setMapCenter({ lat: +coords[0], lng: +coords[1] }),
          300
        )
      }
    }
    scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const sessionsMarkers = sessions
      .filter((s) => {
        const cc = coachingClasses.find((cc) => cc.id == s.coachingClassId)
        const c = coaches.find((c) => c.id == cc.coachId)

        return c.validated
      })
      .map((s) => {
        const cc = coachingClasses.find((cc) => cc.id == s.coachingClassId)

        return {
          coachingClass: {
            ...cc,
            sessions: sessions.filter((s) => s.coachingClassId == cc.id),
            coach: coaches.find((c) => c.id == cc.coachId),
          },
          iconUrl: categories.find(
            (c) => c.id == cc.categoryId || c.id == cc.categoryId
          ).iconUrl,
          onClick: () => route(`/coaching_classes/${cc.id}`),
        }
      })
    const coachesMarkers = coaches
      .filter((c) => c.personalCoachingPrice && c.validated && c.coords)
      .map((c) => {
        return {
          coach: c,
          icon: IconCoach,
          onClick: () => route(`/coaches/${c.id}`),
        }
      })

    setMarkers(
      [
        ...(filters.classTypes.length == 0 ||
        filters.classTypes.some((ct) => ct.id == ClassType.CoachingClass)
          ? sessionsMarkers
          : []),
      ].filter(unique)
    )
    setCoachesMarkers(
      [
        ...(filters.classTypes.length == 0 ||
        filters.classTypes.some((ct) => ct.id == ClassType.PersonalCoaching)
          ? coachesMarkers
          : []),
      ].filter(unique)
    )
    computeCities()
  }, [coachingClassesWithSessions, coaches, filters.classTypes])

  useEffect(() => {
    setCoachingClassesWithSessions(
      coachingClasses.map((cc) => ({
        ...cc,
        sessions: sessions.filter((s) => s.coachingClassId == cc.id),
      }))
    )
  }, [sessions, coachingClasses])

  useEffect(() => {
    applyFilters()
  }, [filters, coachingClassesWithSessions, coaches])

  const fetchData = async () => {
    await fetchCategories()
    await fetchCoaches()
    await fetchCoachingClasses()
    await fetchSessions()
    setLoading(false)
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_ALL_SESSIONS({})
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(
        res.filter((s) => s.date > Date.now()).filter((s) => !s.canceled)
      )
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      res.sort((c1: Category, c2: Category) => (c1.name > c2.name ? 1 : -1))
      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      res.sort((c1: Coach, c2: Coach) =>
        `${c1.firstName} ${c1.lastName}` > `${c2.firstName} ${c2.lastName}`
          ? 1
          : -1
      )
      setCoaches(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les coaches')
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours')
      console.warn(e)
    }
  }

  const computeCities = async () => {
    setCities(
      [...coachingClasses.map((cc) => cc.city), ...coaches.map((c) => c.city)]
        .filter(unique)
        .filter((c) => c)
    )
  }

  const coachingClassIsInTimeFilter = (cc: CoachingClass) => {
    return cc.sessions
      .map((s) => {
        let date = new Date(s.date)
        let ccBegin = moment(`${date.getHours()}:${date.getMinutes()}`, 'H:m')
        let fMin = moment(`${filters.time.time.min}:00`, 'H::mm')
        let fMax = moment(`${filters.time.time.max}:00`, 'H::mm')

        return ccBegin.isSameOrAfter(fMin) && ccBegin.isSameOrBefore(fMax)
      })
      .find((v) => v)
  }

  const applyFilters = async () => {
    let res = [...coachingClassesWithSessions]
    let resCoaches = [
      ...coaches.filter((c) => c.personalCoachingPrice && c.validated),
    ]

    res = res.filter(
      (cc) =>
        cc.sessions.length > 0 &&
        coaches.find((c) => c.id == cc.coachId)?.validated
    )
    // Class types
    if (filters.classTypes && filters.classTypes.length > 0) {
      if (!filters.classTypes.some((ct) => ct.id == ClassType.CoachingClass)) {
        res = []
      }
      if (
        !filters.classTypes.some((ct) => ct.id == ClassType.PersonalCoaching)
      ) {
        resCoaches = []
      }
    }
    // Categories
    if (filters.categories && filters.categories.length > 0) {
      res = res.filter((cc) =>
        filters.categories.find((c) => c.id == cc.categoryId)
      )
      resCoaches = resCoaches.filter((c) => {
        for (let i = 0; i < c.personalCoachingCategories.length; i++) {
          if (
            filters.categories.some(
              (c2) => c2.id == c.personalCoachingCategories[i]
            )
          ) {
            return true
          }
        }
        return false
      })
    }
    // Cities
    if (filters.cities && filters.cities.length > 0) {
      res = res.filter((cc) =>
        filters.cities
          .map((f) => f.city)
          .includes(
            coachingClassesWithCity.find((cc2: any) => cc2.id == cc.id).city
          )
      )
      resCoaches = resCoaches.filter((c) =>
        filters.cities.map((f) => f.city).includes(c.city)
      )
    }
    // Coaches
    if (filters.coaches && filters.coaches.length > 0) {
      res = res.filter((cc) => filters.coaches.find((c) => c.id == cc.coachId))
      resCoaches = resCoaches.filter((c) =>
        filters.coaches.find((c2) => c.id == c2.id)
      )
    }
    // Time & duration
    if (filters.time && filters.time.time) {
      res = res.filter((cc) =>
        cc.sessions ? coachingClassIsInTimeFilter(cc) : false
      )
    }
    if (filters.time && filters.time.duration) {
      res = res.filter(
        (cc) =>
          cc.duration >= filters.time.duration.min &&
          cc.duration <= filters.time.duration.max
      )
    }
    // Price
    if (filters.price) {
      res = res.filter(
        (cc) => cc.price >= filters.price.min && cc.price <= filters.price.max
      )
      resCoaches = resCoaches.filter(
        (c) =>
          c.personalCoachingPrice >= filters.price.min &&
          c.personalCoachingPrice <= filters.price.max
      )
    }
    //Tax deduction //TODO
    if (filters.taxDeduction && filters.taxDeduction.length > 0) {
      resCoaches = resCoaches.filter((c) =>
        filters.taxDeduction.map((f) => f.value).includes(c.taxDeduction)
      )
    }

    // Map center
    let coords = { lat: 48.866667, lng: 2.333333 }

    if (props.matches.mapCenter) {
      let center = props.matches?.mapCenter.split(',')

      coords = { lat: +center[0], lng: +center[1] }
    }

    res.sort((a, b) => {
      const d1 = getDistance(a.location, coords)
      const d2 = getDistance(b.location, coords)

      return d1 > d2 ? 1 : -1
    })
    resCoaches.sort((a, b) => {
      if (!a.coords && !b.coords) {
        return 0
      } else if (!a.coords) {
        return 1
      } else if (!b.coords) {
        return -1
      }

      const d1 = getDistance(a.coords, coords)
      const d2 = getDistance(b.coords, coords)

      return d1 > d2 ? 1 : -1
    })

    setFilteredCoachingClasses(res)
    setFilteredCoaches(resCoaches)
  }

  const navigateToCoachingClass = (coachingClass: CoachingClass) => {
    route(`/coaching_classes/${coachingClass.id}`)
  }

  const onFiltersChange = (newFilters: Filters) => {
    setFilters({ ...filters, ...newFilters })
  }

  const toggleShowMap = () => {
    setShowMap((prev) => !prev)
  }

  const navigateToCoach = (coach: Coach) => {
    route(`/coaches/${coach.id}`)
  }

  return (
    <div class="CoachingClassesList">
      {loading ? (
        <div class="CoachingClassesList__loader">
          <Loader type="ThreeDots" color="#4dba7f" height={100} width={100} />
        </div>
      ) : (
        <div class="CoachingClassesList__list">
          <div class="CoachingClassesList__listTitle">
            Résultat de la recherche
          </div>
          <div class="CoachingClassesList__listFiltersRow">
            <FiltersHandler
              class="CoachingClassesList__listFilters"
              categories={categories}
              selectedCategories={filters.categories}
              coaches={coaches}
              selectedCoaches={filters.coaches}
              time={{
                time: { min: 6, max: 22 },
                duration: { min: 0, max: 120 },
              }}
              selectedTime={filters.time}
              cities={cities.map((c) => ({ city: c }))}
              selectedCities={filters.cities}
              price={{ min: 1, max: 100 }}
              selectedPrice={filters.price}
              onChange={onFiltersChange}
              classTypes={[
                { name: 'Cours collectifs', id: ClassType.CoachingClass },
                { name: 'Coaching personnel', id: ClassType.PersonalCoaching },
              ]}
              selectedClassTypes={filters.classTypes}
              taxDeduction={[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]}
              selectedTaxDeduction={filters.taxDeduction}
            />
            <Button
              class="CoachingClassesList__listMapButton"
              onClick={toggleShowMap}
            >
              Afficher la carte
            </Button>
          </div>
          {categories.length > 0 && (
            <div class="CoachingClassesList__listContent">
              {filteredCoachingClasses.length == 0 &&
              filteredCoaches.length == 0 ? (
                <div class="CoachingClassesList__listContentPlaceholder">
                  Désolé, il n’y a pas encore de cours ou de coach proche de
                  chez vous.
                  <br />
                  Dites-le nous, envoyez-nous un email à{' '}
                  <a href="mailto:contact@gymspot.fr">contact@gymspot.fr</a> et
                  nous ferons tout notre possible pour couvrir votre secteur au
                  plus vite.
                </div>
              ) : (
                <Fragment>
                  {((moment().month() <= 1 && moment().months() >= 0) ||
                    (moment().month() >= 9 && moment().month() <= 11)) &&
                    filteredCoaches.map((c) => (
                      <CoachPreview
                        onClick={navigateToCoach}
                        coach={c}
                        categories={categories}
                      />
                    ))}
                  {filteredCoachingClasses.map((cc) => (
                    <CoachingClassPreviewHorizontal
                      onClick={navigateToCoachingClass}
                      coachingClass={cc}
                      categories={categories}
                      sessions={cc.sessions}
                      coach={coaches.find((c) => c.id == cc.coachId)}
                    />
                  ))}
                  {moment().month() >= 2 &&
                    moment().month() <= 8 &&
                    filteredCoaches.map((c) => (
                      <CoachPreview
                        onClick={navigateToCoach}
                        coach={c}
                        categories={categories}
                      />
                    ))}
                </Fragment>
              )}
            </div>
          )}
        </div>
      )}
      <div
        class={`CoachingClassesList__map${
          showMap ? ' CoachingClassesList__map--mobileVisible' : ''
        }`}
      >
        <Map
          zoom={13}
          center={mapCenter || { lat: 48.866667, lng: 2.333333 }}
          markers={markers}
          coachesMarkers={coachesMarkers}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            clickableIcons: false,
          }}
        >
          <Button onClick={toggleShowMap}>Afficher la liste</Button>
        </Map>
      </div>
    </div>
  )
}
