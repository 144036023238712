export const callOnClickOutside = (fn: () => void, elem: HTMLElement) => {
  document.addEventListener('click', (e) => {
    if (elem && !elem.contains(e.target as HTMLDivElement)) {
      fn()
    }
  })
}

export const unique = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) == index
}
