import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import './CoachSignup.scss'
import Infos from './Infos'
import Stepper, { Step } from 'react-material-stepper'
import Woman from '../../assets/images/woman_signup.png'
import Contact from './Contact'
import Business from './Business'

export default (_: RoutableProps) => {
  return (
    <div class='CoachSignup'>
      <div class='CoachSignup__content'>
        <div class='CoachSignup__title'>
          <div class='CoachSignup__titleTop'>INSCRIVEZ</div>
          <div class='CoachSignup__titleBottom'>VOUS</div>
        </div>
        <img class='CoachSignup__womanPicture' src={Woman} />
        <Stepper>
          <Step stepId='infos' title=''>
            {(<Infos />) as any}
          </Step>
          <Step stepId='contact' title=''>
            {(<Contact />) as any}
          </Step>
          <Step stepId='business' title=''>
            {(<Business />) as any}
          </Step>
        </Stepper>
      </div>
    </div>
  )
}
