import { h } from 'preact'
import './Optins.scss'
import { User } from '../../types/user'
import { useState } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Switch from '../../components/Switch'

interface Props {
  user: User
  onChange: (u: User) => void
}

export default (props: Props) => {
  const updateMe = async (args: {
    optinNewsletter?: boolean
    optinPartners?: boolean
  }) => {
    try {
      const res = await httpClient.req(
        ROUTES.UPDATE_ME({ ...props.user, ...args })
      )

      props.onChange(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder les préférences')
      console.warn(e)
    }
  }

  return (
    <div class="Optins">
      <div class="Optins__title">Mes abonnements newsletter</div>
      <div class="Optins__form">
        <Switch
          on={props.user.optinNewsletter}
          label="Je souhaite recevoir les offres et actualités de Gymspot"
          onChange={(v) => updateMe({ optinNewsletter: v })}
        />
        <Switch
          on={props.user.optinPartners}
          label="Je souhaite recevoir les offres des partenaires de Gymspot"
          onChange={(v) => updateMe({ optinPartners: v })}
        />
      </div>
    </div>
  )
}
