import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import IconFacebook from '../../assets/images/icons/icon_facebook.svg'
import IconGoogle from '../../assets/images/icons/icon_google.svg'
import Man from '../../assets/images/man_signin.png'
import Input from '../components/Input'
import RadioButton from '../components/RadioButton'
import { useState } from 'preact/hooks'
import Button from '../components/Button'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import store, { Message, Stored } from '../services/store'
import './Signin.scss'
import SocialButton from './SocialButton'
import ReactTooltip from 'react-tooltip'

export default (_: RoutableProps) => {
  const [rememberMe, setRememberMe] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const submitForm = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.LOGIN({
          email,
          password,
        })
      )
      const [rawJwt, jwt, token] = httpClient.storeCreds(res)

      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.update(Stored.RefreshToken, token)
      store.notify(Message.Notification, 'Vous êtes connecté avec succès')
    } catch (e) {
      store.notify(Message.Error, 'Email ou mot de passe incorrect')
      console.warn(e)
    }
  }

  const handleGoogleLogin = async (user: any) => {
    try {
      const res = await httpClient.req(
        ROUTES.LOGIN_GOOGLE({
          token: user.token.accessToken,
        })
      )
      const [rawJwt, jwt, token] = httpClient.storeCreds(res)

      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.update(Stored.RefreshToken, token)
      store.notify(Message.Notification, 'Vous êtes connecté avec succès')
    } catch (e) {
      store.notify(Message.Error, 'Email ou mot de passe incorrect')
      console.warn(e)
    }
  }

  const handleGoogleLoginFailure = (err: any) => {
    console.warn(err)
    store.notify(Message.Error, "Erreur lors de l'authentification avec Google")
  }

  const signinWithFacebook = () => {}

  return (
    <div class='Signin'>
      <div class='Signin__content'>
        <div class='Signin__title'>
          <div class='Signin__titleTop'>CONNECTEZ</div>
          <div class='Signin__titleBottom'>VOUS</div>
        </div>
        <img class='Signin__manPicture' src={Man} />
        <div class='Signin__form'>
          <div class='Signin__formTop'>
            <SocialButton
              provider='google'
              appId='1051183664203-vfk5s8mo9dvds3orcd4to5654kbckl09.apps.googleusercontent.com'
              onLoginSuccess={handleGoogleLogin}
              onLoginFailure={handleGoogleLoginFailure}
            >
              <Button class='Signin__google'>
                <IconGoogle />
                <div class='Signin__googleTitle'>SE CONNECTER AVEC GOOGLE</div>
              </Button>
            </SocialButton>
            <ReactTooltip place='top' type='info' effect='solid' />
            <Button
              class='Signin__facebook'
              onClick={signinWithFacebook}
              dataTip='Arrive bientôt !'
            >
              <IconFacebook />
              <div class='Signin__googleTitle'>SE CONNECTER AVEC FACEBOOK</div>
            </Button>
          </div>
          <div class='Signin__formMiddle'>
            <Input placeholder='EMAIL *' type='email' onChange={setEmail} />
            <Input
              placeholder='MOT DE PASSE *'
              type='password'
              onChange={setPassword}
            />
          </div>
          <div class='Signin__formBottom'>
            <div class='Signin__formBottomLeft'>
              <RadioButton
                checked={rememberMe}
                onChange={(val) => setRememberMe(val)}
              >
                Se souvenir de moi
              </RadioButton>
            </div>

            <div class='Signin__formBottomRight'>
              <a href='/forgotten_password'>Mot de passe oublié</a>
            </div>
          </div>
          <Button class='Signin__submit' shadow onClick={submitForm}>
            Se connecter
          </Button>
        </div>
        <div class='Signin__loginLink'>
          Vous n'avez pas de compte ? <a href='/signup'>Inscrivez-vous</a>
        </div>
      </div>
    </div>
  )
}
