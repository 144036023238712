import { h } from 'preact'
import SocialLogin from 'react-social-login'

export default SocialLogin((props: any) => {
  return (
    <div onClick={props.triggerLogin} {...props}>
      {props.children}
    </div>
  )
})
