import { h, ComponentType } from 'preact'
import './MapMarker.scss'
import { useState, useEffect } from 'preact/hooks'

interface Props {
  iconUrl?: string
  icon?: ComponentType
  onClick?: () => void
  modal?: JSX.Element
  refreshMap?: () => void
}

export default (props: Props) => {
  const Icon = props.icon
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    props.refreshMap && props.refreshMap()
  }, [expanded])

  const close = (e: MouseEvent) => {
    e.stopPropagation()
    setExpanded(false)
  }

  const onClick = (e: MouseEvent) => {
    e.stopPropagation()
    setExpanded(true)
  }

  return (
    <div class={`MapMarker ${expanded ? 'MapMarker--expanded' : ''}`}>
      {expanded && props.modal ? (
        <div onClick={close}>{props.modal}</div>
      ) : Icon ? (
        <div class="MapMarker__iconWrapper" onClick={onClick}>
          <Icon />
        </div>
      ) : (
        <img onClick={onClick} src={props.iconUrl} />
      )}
    </div>
  )
}
