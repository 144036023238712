import { h } from 'preact'
import './Schedules.scss'
import IconArrow from '../../../assets/images/icons/icon_arrow.svg'
import IconClose from '../../../assets/images/icons/icon_remove.svg'
import Button from '../../components/Button'
import Calendar from 'react-calendar'
import moment from 'moment'
import { useState, useContext } from 'preact/hooks'
import { CoachingClass } from '../../types/coaching_class'
import { CoachingClassSession } from '../../types/coaching_class_session'
import { StepperContext } from 'react-material-stepper'
import { useEffect } from 'react'

interface Props {
  coachingClass: CoachingClass
  sessions: CoachingClassSession[]
}

export default (props: Props) => {
  const { resolve, getData } = useContext(StepperContext)
  const [calendarStartingDate, setCalendarStartingDate] = useState(new Date())
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [selectedSessions, setSelectedSessions] = useState<
    CoachingClassSession[]
  >(getData('recap')?.selectedSessions || [])

  useEffect(() => {
    let sessions = [...props.sessions]

    sessions.sort((s1, s2) => (s1.date > s2.date ? 1 : -1))
    if (sessions[0]) {
      setCalendarStartingDate(new Date(sessions[0].date))
    }
  }, [])

  const hasSessionOnDate = (date: Date) => {
    for (const session of props.sessions) {
      if (
        moment(session.date).isAfter(date) &&
        moment(session.date).isBefore(moment(date).add(1, 'day'))
      ) {
        return true
      }
    }
    return false
  }

  const nextMonth = () => {
    setCalendarStartingDate(
      moment(calendarStartingDate).add(1, 'month').toDate()
    )
  }

  const prevMonth = () => {
    setCalendarStartingDate(
      moment(calendarStartingDate).subtract(1, 'month').toDate()
    )
  }

  const onCalendarDateChange = (date: Date) => {
    setCalendarDate(date)
  }

  const sessionsOfDay = () => {
    return props.sessions.filter(
      (s) =>
        moment(s.date).isAfter(calendarDate) &&
        moment(s.date).isBefore(moment(calendarDate).add(1, 'day'))
    )
  }

  const toggleSession = (session: CoachingClassSession) => () => {
    if (selectedSessions.find((s) => s.id == session.id)) {
      setSelectedSessions(selectedSessions.filter((s) => s.id != session.id))
    } else {
      setSelectedSessions([...selectedSessions, session])
    }
  }

  const totalPrice = () => {
    return selectedSessions.length * props.coachingClass.price
  }

  const validate = () => {
    resolve({ selectedSessions })
  }

  return (
    <div class="CoachingClassRegistrationSchedules">
      <div class="CoachingClassRegistrationSchedules__title">
        Choisissez vos horaires
      </div>
      <div class="CoachingClassRegistrationSchedules__calendarWrapper">
        <div class="CoachingClassRegistrationSchedules__calendar">
          <div class="CoachingClassRegistrationSchedules__calendarHeader">
            <div class="CoachingClassRegistrationSchedules__calendarHeaderLabel">
              {moment(calendarStartingDate).format('MMMM YYYY')}
            </div>
            <div class="CoachingClassRegistrationSchedules__calendarHeaderControls">
              <IconArrow onClick={prevMonth} />
              <IconArrow onClick={nextMonth} />
            </div>
          </div>
          <Calendar
            activeStartDate={calendarStartingDate}
            tileDisabled={({ date }) => !hasSessionOnDate(date)}
            showNavigation={false}
            onChange={onCalendarDateChange}
          />
        </div>
        <div class="CoachingClassRegistrationSchedules__sessions">
          <div class="CoachingClassRegistrationSchedules__sessionsTitle">
            Cours proposés à cette date
          </div>
          <div class="CoachingClassRegistrationSchedules__sessionsContent">
            {sessionsOfDay().map((s) => {
              let start = moment(s.date)
              let end = moment(s.date).add(
                props.coachingClass.duration,
                'minutes'
              )

              return (
                <Button
                  onClick={toggleSession(s)}
                  disabled={
                    props.coachingClass.maxAttendees - s.registrationsCount <= 0
                  }
                  class={`CoachingClassRegistrationSchedules__sessionsContentEntry ${
                    props.coachingClass.maxAttendees - s.registrationsCount <= 0
                      ? 'CoachingClassRegistrationSchedules__sessionsContentEntry--full'
                      : props.coachingClass.maxAttendees -
                          s.registrationsCount <=
                        3
                      ? 'CoachingClassRegistrationSchedules__sessionsContentEntry--almostFull'
                      : ''
                  } ${
                    selectedSessions.find((s2) => s2.id == s.id)
                      ? 'CoachingClassRegistrationSchedules__sessionsContentEntry--active'
                      : ''
                  }`}
                >
                  {start.format('HH[h]mm')} - {end.format('HH[h]mm')}
                </Button>
              )
            })}
          </div>
        </div>
      </div>
      <div class="CoachingClassRegistrationSchedules__footer">
        <div class="CoachingClassRegistrationSchedules__footerTitle">
          Votre sélection
        </div>
        <div class="CoachingClassRegistrationSchedules__footerSessions">
          {selectedSessions.map((s) => (
            <div class="CoachingClassRegistrationSchedules__footerSessionsEntry">
              <div class="CoachingClassRegistrationSchedules__footerSessionsEntryLeft">
                <div
                  class="CoachingClassRegistrationSchedules__footerSessionsEntryRemove"
                  onClick={toggleSession(s)}
                >
                  <IconClose />
                </div>
                <div class="CoachingClassRegistrationSchedules__footerSessionsEntryDate">
                  {moment(s.date).format('ddd DD MMMM YYYY')}
                </div>
              </div>
              <div class="CoachingClassRegistrationSchedules__footerSessionsEntrySeparator" />
              <div class="CoachingClassRegistrationSchedules__footerSessionsEntryRight">
                <div class="CoachingClassRegistrationSchedules__footerSessionsEntryTime">
                  {moment(s.date).format('HH[h]mm')} -{' '}
                  {moment(s.date)
                    .add(props.coachingClass.duration, 'minutes')
                    .format('HH[h]mm')}
                </div>
                <div class="CoachingClassRegistrationSchedules__footerSessionsEntryPrice">
                  {props.coachingClass.price}€
                </div>
              </div>
            </div>
          ))}
        </div>
        <div class="CoachingClassRegistrationSchedules__footerTotal">
          <div class="CoachingClassRegistrationSchedules__footerTotalLeft">
            Total à régler :
          </div>
          <div class="CoachingClassRegistrationSchedules__footerTotalRight">
            {totalPrice()}€
          </div>
        </div>
        <div class="CoachingClassRegistrationSchedules__footerValidate">
          <Button onClick={validate} disabled={selectedSessions.length == 0}>
            Confirmer ces horaires
          </Button>
        </div>
      </div>
    </div>
  )
}
