import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import './FoldingRow.scss'

interface Props {
  accordion: JSX.Element | JSX.Element[]
  children: JSX.Element | JSX.Element[]
  class?: string
}

export default (props: Props) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Fragment>
      <tr
        class={`FoldingRow ${props.class ? props.class : ''}`}
        onClick={toggleExpanded}
      >
        {props.children}
      </tr>
      <tr
        class={`FoldingRow__accordion${
          expanded ? ' FoldingRow__accordion--expanded' : ''
        }`}
      >
        {props.accordion}
      </tr>
    </Fragment>
  )
}
