import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import './CoachGuide.scss'

export default (_: RoutableProps) => {
  return (
    <div class="CoachGuide">
      <h1 class="CoachGuide__title">Guide coach sportif</h1>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Inscription</h2>
        <div class="CoachGuide__entryContent">
          L'inscription coach se fait tout en bas du site à :{' '}
          <em>recrutement coach sportif</em>. Si par mégarde vous vous êtes déjà
          inscrit en tant qu'utilisateur, connectez-vous avec votre compte
          utilisateur et cliquez sur "recrutement coach" afin de vous inscrire
          en tant que coach, les deux comptes fusionneront.
          <br />
          Saisissez les informations requises ainsi qu'un mot de passe à 8
          caractères et le tour est joué. Vous êtes inscrit. Il faut valider
          votre compte coach à présent en téléchargeant les documents demandés.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Documents à valider</h2>
        <div class="CoachGuide__entryContent">
          Pour être visible sur la plateforme et pouvoir créer des cours ou être
          contacté pour du coaching individuel, il faudra télécharger tous les
          documents demandés dans votre espace personnel :
          <ul>
            <li>Carte d'identité</li>
            <li>Carte professionnelle</li>
            <li>Attestation d'assurance Pro</li>
            <li>
              Signature éléctronique du mandat de facturation
              <span class="CoachGuide__green">*</span>
            </li>
            <li>
              Lier votre compte à Stripe<span class="CoachGuide__green">*</span>
            </li>
          </ul>
          Les pièces fournies sont validées dans les 24h. Une fois tous les
          documents validés, vous êtes habilités à créer des cours et être
          contactés pour du coaching individuel.
          <br />
          <span class="CoachGuide__green">*</span>
          <em>Le mandat de facturation</em> est un document qui permet à Gymspot
          de facturer en votre nom les utilisateurs du site afin d'en simplifier
          la gestion.
          <br />
          <span class="CoachGuide__green">*</span>
          <em>Stripe</em> est la plateforme de paiement qui vous permettra de
          recevoir vos paiements.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Fiche coach</h2>
        <div class="CoachGuide__entryContent">
          La fiche coach se trouve dans votre espace personnel. Les informations
          demandées sont importantes car c'est ce que les utilisateurs, vos
          potentiels futurs clients verront. Il est impératif de remplir toutes
          les informations afin d'apparaître sur le site. Ne pas oublier de
          telécharger une photo de vous car votre image sera votre première
          vitrine. Rentrez votre vrai nom et prénom ainsi que le lieu où vous
          habitez afin que vous apparaissiez sur la carte géographique.
          <br />
          Dans la description, mettez-y votre caractère, votre passif, votre
          carrière etc. Dans les qualifications, notez vos diplômes,
          certifications et formations. Ensuite se trouve les informations
          concernant uniquement le coaching individuel. Choisissez votre tarif.
          Attention, le tarif indiqué en gros dans la case, est le tarif que les
          utilisateurs verront et non que vous toucherez "dans votre poche".
          Celui-ci sera indiqué "en net" juste au dessus.
          <br />
          Il faut également cocher toutes les catégories que vous dispensez afin
          que les utilisateurs puissent vous sélectionner selon leurs objectifs.
          <br />
          Enfin, si vous bénéficiez de la réduction d'impôt service à la
          personne, il faudra cocher la case en question pour que ce soit
          notifié sur votre description aux utilisateurs.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">
          Comment suis-je contacté pour le coaching individuel ?
        </h2>
        <div class="CoachGuide__entryContent">
          Quand vous avez validé votre inscription (voir début du guide), vous
          êtes visible par tous les utilisateurs du site.
          <br />
          En fonction de votre localisation, des avis, de vos spécialités, de
          votre tarif et de votre description, l'utilisateur intéressé par votre
          profil nous fera parvenir une demande de coaching.
          <br />
          Dans la foulée, notre équipe vous contacte soit par téléphone, soit
          par email afin d'établir le lien entre vous et votre futur client.
          <br />
          Aussi, il est possible que l'équipe Gymspot vous contacte pour sa
          filiale corporate. Selon les besoins et votre localisation, nous
          recrutons également les coachs afin de dispenser des cours
          intra-entreprise.
          <br />
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Créer votre cours collectif</h2>
        <div class="CoachGuide__entryContent">
          Pour créer votre cours, allez dans votre espace personnel et cliquez
          dans "Mes cours". Cliquez sur " + Créer un cours ".
          <br />
          Remplissez les différents champs et cliquez sur valider. Votre cours
          cours est enfin visible par les utilisateurs qui peuvent désormais s'y
          inscrire. <br />
          Si vous faites votre cours dans un endroit où il n'y a pas d'adresse
          exact comme pour un parc par exemple, précisez du mieux possible
          l'endroit de rdv (entrée, plaine, lieu...) pour vos participants. Vous
          pouvez également mettre des photos du lieu dans les champs à remplir.
          <br />
          Le tarif du cours est en brut. C'est à dire que le tarif que vous
          proposez est le tarif que les utilisateurs vont payer et non celui que
          vous allez recevoir dans votre poche. Vos gains réels sont affichés en
          net juste en haut de la case.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Annuler son cours</h2>
        <div class="CoachGuide__entryContent">
          Pour annuler son cours, il faut cliquer sur "Mes cours", puis sur la
          petite icône à droite du cours en question. A côté de la date de votre
          cours , cliquez sur la croix rouge et votre cours sera annulé.
          <br />
          Attention, s'il y a des inscrits à votre cours, vous ne recevrez aucun
          paiements. Vos paiement seront redirigés vers le portefeuille des
          clients afin qu'ils soient remboursés en intégralité.{' '}
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Ses inscrits</h2>
        <div class="CoachGuide__entryContent">
          Allez dans votre espace personnel et cliquez sur " Mes inscrits".{' '}
          <br />
          Vous pourrez voir les participants de votre cours en cliquant sur le
          cours concerné.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Contacter les participants</h2>
        <div class="CoachGuide__entryContent">
          Si vous avez des recommandations pour vos inscrits, vous pourrez leur
          envoyer un message commun en cliquant sur l'enveloppe sur le bord
          droit du cours concerné. Si vous voulez envoyer un message à un seul
          participant vous devriez cliquer sur le cours concerné puis sur
          l'enveloppe, sur le bord droit du participant en question. Si un
          participant vous envoie un message, vous sera notifié par email et
          apparaîtra un petit "1" sur l'enveloppe du participant concerné.
        </div>
      </div>
      <div class="CoachGuide__entry">
        <h2 class="CoachGuide__entryTitle">Où sont mes factures ?</h2>
        <div class="CoachGuide__entryContent">
          Toutes vos factures sont enregistrées sur votre compte. Allez dans
          "Mes inscrits" puis cliquez sur le cours dont vous voulez les
          factures. Vous verrez à côté de chaque participants, une facture
          correspondant à son achat.
          <br />
          Selon si c'est un cours à venir ou passé, sélectionnez l'option
          souhaitée en haut de la page.
        </div>
      </div>
    </div>
  )
}
