import { h } from 'preact'
import Input from '../../components/Input'
import RadioButton from '../../components/RadioButton'
import { useState, useContext } from 'preact/hooks'
import Button from '../../components/Button'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import './Infos.scss'
import store, { Stored, Message } from '../../services/store'
import { StepperContext } from 'react-material-stepper'
import { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

export default () => {
  const [optinNewsletter, setOptinNewsletter] = useState(false)
  const [optinPartners, setOptinPartners] = useState(false)
  const [optinCGU, setOptinCGU] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { resolve } = useContext(StepperContext)

  useEffect(() => {
    let jwt = store.store.JWT

    if (jwt) {
      resolve({ name: `${jwt.firstName} ${jwt.lastName}` })
    }
  }, [])

  const onValidate = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.SIGNUP({
          firstName,
          lastName,
          email,
          password,
          optinNewsletter,
          optinPartners,
        })
      )
      const [rawJwt, jwt, token] = httpClient.storeCreds(res)

      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.update(Stored.RefreshToken, token)
      resolve({ firstName: firstName, lastName: lastName })
    } catch (e) {
      store.notify(
        Message.Error,
        e == 'EMAIL_ALREADY_TAKEN'
          ? 'Un compte existe déjà avec cet email'
          : "Une erreur est survenue lors de l'inscription"
      )
      console.warn(e)
    }
  }

  const canSignup = () => {
    return (
      optinCGU &&
      firstName &&
      lastName &&
      password &&
      password.length >= 8 &&
      email
    )
  }

  return (
    <div class="CoachSignupInfos">
      <div class="CoachSignupInfos__title">Informations personnelles</div>
      <div class="CoachSignupInfos__form">
        <div class="CoachSignupInfos__formTop">
          <Input placeholder="NOM *" onChange={setLastName} value={lastName} />
          <Input
            placeholder="PRÉNOM *"
            onChange={setFirstName}
            value={firstName}
          />
        </div>
        <div class="CoachSignupInfos__formMiddle">
          <Input
            placeholder="EMAIL *"
            onChange={setEmail}
            type="email"
            value={email}
          />
          <Input
            placeholder="MOT DE PASSE *"
            type="password"
            onChange={setPassword}
            value={password}
          />
        </div>
        <div class="CoachSignupInfos__formBottom">
          <div class="CoachSignupInfos__formBottomLeft">
            <RadioButton
              checked={optinNewsletter}
              onChange={setOptinNewsletter}
            >
              S'inscrire à la newsletter Gymspot
            </RadioButton>
            <RadioButton checked={optinPartners} onChange={setOptinPartners}>
              S'inscrire aux offres partenaires Gymspot
            </RadioButton>
            <RadioButton checked={optinCGU} onChange={setOptinCGU}>
              J'accepte les{' '}
              <a href="/tos" target="_blank">
                CGV
              </a>
              *
            </RadioButton>
          </div>
          <div class="CoachSignupInfos__formBottomRight">
            * Champ obligatoire
          </div>
        </div>
        <ReactTooltip place="top" type="error" effect="solid" html />
        <Button
          shadow
          onClick={onValidate}
          disabled={!canSignup()}
          dataTip={
            optinCGU
              ? 'Vous devez accepter les CGV avant de pouvoir continuer'
              : undefined
          }
        >
          Valider
        </Button>
      </div>
      <div class="CoachSignupInfos__loginLink">
        Vous avez déjà un compte ? <a href="/signin">Connectez-vous</a>
      </div>
    </div>
  )
}
