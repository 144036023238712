import { h } from 'preact'
import './CoachRating.scss'
import Rater from 'react-rater'
import IconClose from '../../../../assets/images/icons/icon_remove.svg'
import { useEffect } from 'react'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import Coach from '../../../types/coach'
import { useState } from 'preact/hooks'
import Button from '../../../components/Button'

interface Props {
  coach: Coach
  onClose: () => void
}

export default (props: Props) => {
  const [rating, setRating] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const coach = props.coach

  useEffect(() => {
    fetch_review()
  }, [])

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const fetch_review = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_MY_COACH_REVIEW({
          userId: store.store.JWT.sub,
          coachId: coach.id,
          rating,
          comment: message,
        })
      )

      if (res?.rating) {
        setRating(res.rating)
      }
      if (res?.comment) {
        setMessage(res.comment)
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible de récupérer l'avis")
      console.warn(e)
    }
  }

  const onValidate = async () => {
    try {
      await httpClient.req(
        ROUTES.CREATE_COACH_REVIEW({
          coachId: coach.id,
          rating,
          comment: message,
        })
      )

      store.notify(Message.Notification, 'Avis sauvegardé avec succès')
      props.onClose()
    } catch (e) {
      store.notify(Message.Error, "Impossible d'envoyer l'avis")
      console.warn(e)
    }
  }

  const onRatingChange = ({ rating }: { rating: number }) => {
    setRating(rating)
  }

  const onMessageChange = (e: any) => {
    setMessage(e.target.value)
  }

  return (
    <div class="CoachRating" onClick={props.onClose}>
      {props.coach && (
        <div class="CoachRating__content" onClick={stopPropagation}>
          <div class="MessageForm__close" onClick={props.onClose}>
            <IconClose />
          </div>
          <div class="CoachRating__contentHeader">
            <div class="CoachRating__contentHeaderImages">
              <div class="CoachRating__contentHeaderImagesCoach">
                <img src={coach.pictureUrl} />
              </div>
            </div>
            <div class="CoachRating__contentHeaderDesc">
              <div class="CoachRating__contentHeaderDescLocation">
                {coach.location}
              </div>
              <div class="CoachRating__contentHeaderDescName">
                {coach.firstName} {coach.lastName}
              </div>
              <div class="CoachRating__contentHeaderDescRating">
                <div class="CoachRating__contentHeaderDescRatingValue">
                  {coach.averageGrade / 10}
                </div>
                <Rater rating={coach.averageGrade / 10} interactive={false} />
                <div class="CoachRating__contentHeaderDescRatingFeedbacks">
                  <a href="#">{coach.reviewsCount} avis</a>
                </div>
              </div>
            </div>
          </div>
          <div class="CoachRating__contentBody">
            <div class="CoachRating__contentBodyTitle">
              Votre avis sur le coach {coach.firstName} {coach.lastName}
            </div>
            <div class="CoachRating__contentBodyFeedback">
              <div class="CoachRating__contentBodyFeedbackRating">
                <Rater onRate={onRatingChange} rating={rating} />
              </div>
              <div class="CoachRating__contentBodyFeedbackMessage">
                <textarea
                  maxLength={300}
                  onChange={onMessageChange}
                  placeholder="Votre avis sur le coach... (optionnel)"
                  value={message}
                />
              </div>
              <Button onClick={onValidate} disabled={!rating}>
                Valider
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
