import { h, Fragment } from 'preact'
import './CoachingClassRegistration.scss'
import { CoachingClass } from '../types/coaching_class'
import Rater from 'react-rater'
import IconGeoloc from '../../assets/images/icons/icon_location.svg'
import IconCheck from '../../assets/images/icons/icon_check.svg'
import IconSchedule from '../../assets/images/icons/icon_clock.svg'
import IconClose from '../../assets/images/icons/icon_remove.svg'
import { CoachingClassSession } from '../types/coaching_class_session'
import Stepper, { Step } from 'react-material-stepper'
import Schedules from './Schedules'
import Recap from './Recap'
import Payment from './Payment'
import MultiStripeProvider from '../Stripe/MultiStripeProvider'
import { loadStripe } from '@stripe/stripe-js'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { UUID } from '../types/common'
import { Order } from '../types/order'
import Loader from 'react-loader-spinner'
import InvoiceGymspot from '../InvoiceGymspot'
import { User } from '../types/user'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import store, { Message } from '../services/store'

interface Props {
  coachingClass: CoachingClass
  sessions: CoachingClassSession[]
  onClose: () => void
}

export default (props: Props) => {
  const [order, setOrder] = useState<Order>(undefined)
  const [loading, setLoading] = useState(false)
  const stripePromise = loadStripe('pk_live_YQE73nmhHySE2hhuFnw4btaj00UmIkZNi9')
  const [me, setMe] = useState<User>(undefined)

  useEffect(() => {
    fetchMe()
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  const fetchMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ME({}))

      setMe(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer le profil')
      console.warn(e)
    }
  }

  const updateDimensions = () => {
    let elem = document.querySelector(
      '.CoachingClassRegistration'
    ) as HTMLElement

    elem.style.height = window.innerHeight.toString()
    elem.style.width = window.innerWidth.toString()
  }

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const onOrderValidate = (order: Order) => {
    setOrder(order)
  }

  const changeLoading = (v: boolean) => {
    let contentElement = document.querySelector(
      '.CoachingClassRegistration__content'
    )

    if (v && contentElement) {
      contentElement.scrollTop = 0
    }
    setLoading(v)
  }

  return (
    <div class="CoachingClassRegistration" onClick={props.onClose}>
      <div
        class={`CoachingClassRegistration__content ${
          loading ? 'CoachingClassRegistration__content--loading' : ''
        }`}
        onClick={stopPropagation}
      >
        <div class="MessageForm__close" onClick={props.onClose}>
          <IconClose />
        </div>
        {loading && (
          <div class="CoachingClassRegistration__loader">
            <Loader type="ThreeDots" color="#4dba7f" height={100} width={100} />
          </div>
        )}
        {!order ? (
          <Fragment>
            <div class="CoachingClassRegistration__contentHeader">
              <div class="CoachingClassRegistration__contentHeaderCoach">
                <img src={props.coachingClass.coach.pictureUrl} />
              </div>
              <div class="CoachingClassRegistration__contentHeaderDesc">
                <div class="CoachingClassRegistration__contentHeaderDescCoach">
                  {props.coachingClass.coach.firstName}{' '}
                  {props.coachingClass.coach.lastName}
                </div>
                <div class="CoachingClassRegistration__contentHeaderDescTitle">
                  {props.coachingClass.name}
                </div>
                <div class="CoachingClassRegistration__contentHeaderDescRating">
                  <div class="CoachingClassRegistration__contentHeaderDescRatingValue">
                    {props.coachingClass.coach.averageGrade / 10}
                  </div>
                  <Rater
                    rating={props.coachingClass.coach.averageGrade / 10}
                    interactive={false}
                  />
                </div>
                <div class="CoachingClassRegistration__contentHeaderDescLocation">
                  <IconGeoloc />
                  {props.coachingClass.meetingLocation}
                </div>
              </div>
              <div class="CoachingClassRegistration__contentHeaderPrice">
                <div class="CoachingClassRegistration__contentHeaderPriceContent">
                  <span>{props.coachingClass.price}€</span>
                </div>
              </div>
            </div>
            <div class="CoachingClassRegistration__contentStepper">
              <Stepper>
                <Step stepId="schedules" title="">
                  {
                    (
                      <Schedules
                        coachingClass={props.coachingClass}
                        sessions={props.sessions}
                      />
                    ) as any
                  }
                </Step>
                <Step stepId="recap" title="">
                  {
                    (
                      <Recap
                        coachingClass={props.coachingClass}
                        sessions={props.sessions}
                      />
                    ) as any
                  }
                </Step>
                <Step stepId="payment" title="">
                  {
                    (
                      <MultiStripeProvider stripe={stripePromise}>
                        <Payment
                          coachingClass={props.coachingClass}
                          onOrderValidate={onOrderValidate}
                          setLoading={changeLoading}
                        />
                      </MultiStripeProvider>
                    ) as any
                  }
                </Step>
              </Stepper>
            </div>
          </Fragment>
        ) : (
          <div class="CoachingClassRegistration__contentValidated">
            {order && (
              <InvoiceGymspot
                order={order}
                recipient={{
                  name: `${me.firstName} ${me.lastName}`,
                  address: me.address,
                  zipcode: me.zipcode,
                  city: me.city,
                }}
                coachingClasses={[props.coachingClass]}
                sessions={props.sessions}
                coaches={[props.coachingClass.coach]}
                coachInvoice
              />
            )}
            <div class="CoachingClassRegistration__contentValidatedHeader">
              <div class="CoachingClassRegistration__contentValidatedHeaderTitle">
                Votre réservation a bien été prise en compte <IconCheck />
              </div>
              <div class="CoachingClassRegistration__contentValidatedHeaderSubtitle">
                Vous pouvez retrouver à tout moment vos cours dans la partie
                "Mes réservations" de votre compte.
              </div>
            </div>
            <div class="CoachingClassRegistration__contentValidatedContent">
              <div class="CoachingClassRegistration__contentValidatedContentTitle">
                Récapitulatif de votre commande
              </div>
              <div class="CoachingClassRegistration__contentHeader">
                <div class="CoachingClassRegistration__contentHeaderCoach">
                  <img src={props.coachingClass.coach.pictureUrl} />
                </div>
                <div class="CoachingClassRegistration__contentHeaderDesc">
                  <div class="CoachingClassRegistration__contentHeaderDescCoach">
                    {props.coachingClass.coach.firstName}{' '}
                    {props.coachingClass.coach.lastName}
                    <span> Commande n°{order.id}</span>
                  </div>
                  <div class="CoachingClassRegistration__contentHeaderDescTitle">
                    {props.coachingClass.name}
                  </div>
                  <div class="CoachingClassRegistration__contentHeaderDescLocation">
                    <IconGeoloc />
                    {props.coachingClass.meetingLocation}
                  </div>
                  <div class="CoachingClassRegistration__contentValidatedContentBooking">
                    <div class="CoachingClassRegistration__contentValidatedContentBookingTitle">
                      <IconSchedule /> Réservation
                    </div>
                    <div class="CoachingClassRegistration__contentValidatedContentBookingEntries">
                      {order.sessionIds.map((id: UUID) => {
                        let session = props.sessions.find((s) => s.id == id)
                        let date = moment(session.date)

                        return (
                          <div class="CoachingClassRegistration__contentValidatedContentBookingEntry">
                            <div class="CoachingClassRegistration__contentValidatedContentBookingEntryDate">
                              {date.format('ddd DD MMM YYYY')}{' '}
                            </div>
                            <div class="CoachingClassRegistration__contentValidatedContentBookingEntrySeparator" />
                            <div class="CoachingClassRegistration__contentValidatedContentBookingEntryTime">
                              {date.format('HH[h]mm')} -{' '}
                              {date
                                .add(props.coachingClass.duration, 'minutes')
                                .format('HH[h]mm')}
                            </div>
                            <a
                              target="_blank"
                              href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${
                                props.coachingClass.name
                              } - Gymspot&dates=${moment(session.date).format(
                                'YYYYMMDDTHHmmss'
                              )}/${moment(session.date)
                                .add(props.coachingClass.duration, 'minutes')
                                .format(
                                  'YYYYMMDDTHHmmss'
                                )}&details=Animé par ${`${props.coachingClass.coach.firstName} ${props.coachingClass.coach.lastName}`}&location=${
                                props.coachingClass.meetingLocation
                              }`}
                            >
                              Ajouter au calendrier
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
