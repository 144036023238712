import { h } from 'preact'
import './CoachingClassForm.scss'
import { CoachingClass } from '../../../types/coaching_class'
import IconClose from '../../../../assets/images/icons/icon_remove.svg'
import SportIcon from '../../../../assets/images/icons/icon_coaching_classes.svg'
import IconAdd from '../../../../assets/images/icons/icon_add.svg'
import InputWithLabel from '../../../components/InputWithLabel'
import { useState, useEffect } from 'preact/hooks'
import Button from '../../../components/Button'
import { StandaloneSearchBox } from '@react-google-maps/api'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { Category } from '../../../types/category'
import InputRange from 'react-input-range'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { CoachingClassSession } from '../../../types/coaching_class_session'
import { UUID } from '../../../types/common'
import ReactTooltip from 'react-tooltip'
import RadioButton from '../../../components/RadioButton'
import Div100vh from 'react-div-100vh'

interface Props {
  onClose: () => void
  coachingClass?: CoachingClass
  onValidate: (
    cc: CoachingClass,
    s: CoachingClassSession[],
    sessionsToDelete: UUID[]
  ) => void
}

const defaultLevels = {
  mobility: 0,
  flexibility: 0,
  stamina: 0,
  strength: 0,
}

export default (props: Props) => {
  const [coachingClass, setCoachingClass] = useState<CoachingClass>(
    props.coachingClass || ({} as any)
  )
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [sessionsToDelete, setSessionsToDelete] = useState<UUID[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [searchBox, setSearchBox] = useState<any>(undefined)
  const [tmpPhotoUrl, setTmpPhotoUrl] = useState<string>(undefined)
  const [newSessions, setNewSessions] = useState<CoachingClassSession[]>([])

  useEffect(() => {
    fetchData()

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    let elem = document.querySelector('.CoachingClassForm') as HTMLElement

    elem.style.height = `${window.innerHeight}px`
    elem.style.width = `${window.innerWidth}px`
  }

  useEffect(() => {
    if (sessionsToDelete.length > 0) {
      onValidate()
    }
  }, sessionsToDelete)

  const fetchData = async () => {
    coachingClass?.id && fetchSessions()
    fetchCategories()
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_COACHING_CLASS_SESSIONS({ id: coachingClass.id })
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(res.filter((s) => s.date > Date.now()))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les sessions')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const onValidate = () => {
    props.onValidate(
      coachingClass,
      [...sessions, ...newSessions],
      sessionsToDelete
    )
  }

  const cityForCoordinates = async (coords: { lat: number; lng: number }) => {
    try {
      const res = await httpClient.req(
        ROUTES.REVERSE_GEOCODING({
          lat: coords.lat,
          lng: coords.lng,
          apiKey: 'AIzaSyAfPo51CoBpA1yhWi70oGOlmULPwZYywZQ',
        })
      )
      let addr = res.results[0]

      if (addr) {
        let cityComponent = addr.addressComponents.find((ac: any) =>
          ac.types.includes('locality')
        )

        if (cityComponent) {
          return cityComponent.longName
        }
      }
      return null
    } catch (e) {
      console.warn(e)
    }
  }

  const onSearchBoxLoad = (ref: any) => setSearchBox(ref)

  const onPlacesChanged = async () => {
    const place = searchBox.getPlaces()[0]

    if (!place) {
      return
    }

    let country = place.address_components.find((ac: any) =>
      ac.types.includes('country')
    )

    if (country.long_name == 'France') {
      let coords = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }

      setCoachingClass({
        ...coachingClass,
        location: coords,
        meetingLocation: place.formatted_address,
        city: await cityForCoordinates(coords),
      })
    } else {
      store.notify(Message.Error, 'Le cours doit se dérouler en France.')
    }
  }

  const uploadPhoto = async (e: any) => {
    const file = e.target.files[0]

    try {
      if (file.size / 1024 / 1024 <= 10) {
        const res = await httpClient.req(
          ROUTES.UPLOAD_FILE({ file: e.target.files[0] })
        )

        setCoachingClass({ ...coachingClass, pictureUrl: res.fileUrl })
        setTmpPhotoUrl(res.fileUrl)
      } else {
        store.notify(
          Message.Error,
          'La taille max pour les fichiers est de 10Mo'
        )
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible d'uploader la photo")
      console.warn(e)
    }
  }

  const typeOfMedia = (f: File) => {
    let type = f.type.split('/')[0]

    if (type && (type == 'image' || type == 'video')) {
      return type
    } else {
      return undefined
    }
  }

  const uploadMedia = async (e: any) => {
    let type = typeOfMedia(e.target.files[0])
    const file = e.target.files[0]

    if (type) {
      try {
        if (file.size / 1024 / 1024 <= 10) {
          const res = await httpClient.req(
            ROUTES.UPLOAD_FILE({ file: e.target.files[0] })
          )

          setCoachingClass({
            ...coachingClass,
            medias: [
              ...(coachingClass?.medias || []),
              { url: res.fileUrl, is_main: false, type },
            ],
          })
        } else {
          store.notify(
            Message.Error,
            'La taille max pour les fichiers est de 10Mo'
          )
        }
      } catch (e) {
        store.notify(Message.Error, "Impossible d'uploader la photo")
        console.warn(e)
      }
    } else {
      alert(
        'Le fichier est invalide. Seul les images et vidéos sont acceptées.'
      )
    }
  }

  const deleteMedia = (media: {
    url: string
    is_main: boolean
    type: string
  }) => () => {
    setCoachingClass({
      ...coachingClass,
      medias: coachingClass.medias.filter((m) => m.url != media.url),
    })
  }

  const addNewSession = () => {
    setNewSessions([
      ...newSessions,
      {
        date: Date.now(),
        coachingClassId: coachingClass.id,
        registrationsCount: 0,
        type: 'oneTime',
        canceled: false,
      },
    ])
  }

  const changeNewSession = (i: number) => (v: Date) => {
    let res = [...newSessions]

    res[i].date = v.getTime()
    setNewSessions(res)
  }

  const changeSession = (id: UUID) => (v: Date) => {
    let res = [...sessions]
    let i = sessions.findIndex((s) => s.id === id)

    res[i].date = v.getTime()
    setSessions(res)
  }

  const changeNewSessionType = (i: number, v: string) => () => {
    let res = [...newSessions]

    res[i].type = v
    setNewSessions(res)
  }

  const markSessionToDelete = (s: CoachingClassSession) => () => {
    if (
      window.confirm(
        "Supprimer cet cours l'annulera pour tous les participants déjà inscrits. Vous ne toucherez rien et pourrez être facturé des frais d'annulation."
      )
    ) {
      setSessionsToDelete([...sessionsToDelete, s.id])
    }
  }

  const deleteNewSession = (i: number) => () => {
    setNewSessions(
      newSessions
        .slice(0, i)
        .concat(newSessions.slice(i + 1, newSessions.length))
    )
  }

  const validateClass = () => {
    let errors = []

    if (!coachingClass.name) {
      errors.push('Le nom du cours est manquant')
    }
    if (!coachingClass.duration) {
      errors.push('La durée du cours est manquante')
    } else if (coachingClass.duration > 120) {
      errors.push('La durée du cours est trop élevée (2h max)')
    }
    if (!coachingClass.price) {
      errors.push('Le prix du cours est manquant')
    } else if (coachingClass.price > 100) {
      errors.push('Le prix du cours est trop élevé (100€ max)')
    }
    if (!coachingClass.description) {
      errors.push('La description du cours est manquante')
    }
    if (!coachingClass.location) {
      errors.push("L'emplacement du cours est manquante")
    }
    if (!coachingClass.maxAttendees) {
      errors.push('Le nombre max de participants au cours est manquant')
    } else if (coachingClass.maxAttendees > 100) {
      errors.push(
        'Le nombre max de participants au cours est trop élevé (100 pers. max)'
      )
    }
    if (!coachingClass.pictureUrl) {
      errors.push('La photo du cours est manquante')
    }
    if (!coachingClass.categoryId) {
      errors.push('La catégorie du cours est manquante')
    }
    return errors
  }

  return (
    <Div100vh>
      <div class="CoachingClassForm" onClick={props.onClose}>
        <div class="CoachingClassForm__content" onClick={stopPropagation}>
          <div class="CoachingClassForm__contentClose" onClick={props.onClose}>
            <IconClose />
          </div>
          <div class="CoachingClassForm__contentTitle">
            Création d'un cours
            <SportIcon />
          </div>
          <div class="CoachingClassForm__contentForm">
            <div class="CoachingClassForm__contentFormRow">
              <div class="CoachingClassForm__photo">
                <img src={tmpPhotoUrl || coachingClass?.pictureUrl} />
                <label>
                  <input type="file" onChange={uploadPhoto} />
                  <div class="CoachingClassForm__photoUpload">
                    Changer la photo de profil du cours
                  </div>
                </label>
              </div>
            </div>
            <div class="CoachingClassForm__contentFormRow">
              <InputWithLabel
                label="Nom du cours"
                value={coachingClass?.name}
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, name: v })
                }
                maxlength={50}
              />
            </div>
            <div class="CoachingClassForm__contentFormRow CoachingClassForm__contentFormAddress">
              <StandaloneSearchBox
                onLoad={onSearchBoxLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <InputWithLabel
                  label="Adresse du cours"
                  value={coachingClass?.meetingLocation}
                  disableAutocomplete
                />
              </StandaloneSearchBox>
            </div>
            <div class="CoachingClassForm__contentFormRow">
              <InputWithLabel
                label="Catégorie"
                value={coachingClass?.categoryId}
                selectOptions={[
                  {
                    value: undefined,
                    label: 'Sélectionner une catégorie',
                    disabled: true,
                  },
                  ...categories.map((c) => ({
                    value: c.id,
                    label: c.name,
                  })),
                ]}
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, categoryId: v })
                }
              />
              <InputWithLabel
                label={`Tarif (par cours en €)${
                  coachingClass?.price
                    ? ` -> ${
                        coachingClass.price - (coachingClass.price * 15) / 100
                      }€ net`
                    : ''
                }`}
                value={`${coachingClass?.price}`}
                type="number"
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, price: +v })
                }
                min={0}
                max={100}
              />
            </div>
            <div class="CoachingClassForm__contentFormRow">
              <InputWithLabel
                label="Durée du cours (en mins)"
                value={`${coachingClass?.duration}`}
                type="number"
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, duration: +v })
                }
                min={0}
                max={120}
              />
              <InputWithLabel
                label="Nbr. de participants max"
                value={`${coachingClass?.maxAttendees}`}
                type="number"
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, maxAttendees: +v })
                }
                max={100}
              />
            </div>
            <div class="CoachingClassForm__contentFormRow CoachingClassForm__contentFormRow--alignTop">
              <div class="CoachingClassForm__contentFormSchedules">
                <div class="CoachingClassForm__contentFormSchedulesTitle">
                  Horaires
                </div>
                <div class="CoachingClassForm__contentFormSchedulesInputs">
                  {sessions
                    .filter((s) => !sessionsToDelete.includes(s.id))
                    .map((s) => (
                      <div
                        key={s.id}
                        class={`CoachingClassForm__contentFormSchedulesInputsItem${
                          s.canceled
                            ? ' CoachingClassForm__contentFormSchedulesInputsItem--canceled'
                            : ''
                        }`}
                      >
                        {s.canceled ? (
                          <div class="CoachingClassForm__contentFormSchedulesInputsItemCanceled">
                            Annulé
                          </div>
                        ) : (
                          <div
                            class="CoachingClassForm__contentFormSchedulesInputsItemDelete"
                            onClick={markSessionToDelete(s)}
                          >
                            <IconClose />
                          </div>
                        )}
                        <DateTimePicker
                          placeholder="17 juil. 2020 14:30"
                          value={new Date(s.date)}
                          onChange={changeSession(s.id)}
                          disabled
                        />
                      </div>
                    ))}
                  {newSessions.map((s, i) => (
                    <div
                      key={i}
                      class="CoachingClassForm__contentFormSchedulesInputsItem"
                    >
                      <div
                        class="CoachingClassForm__contentFormSchedulesInputsItemDelete"
                        onClick={deleteNewSession(i)}
                      >
                        <IconClose />
                      </div>
                      <DateTimePicker
                        placeholder="17 juil. 2020 14:30"
                        value={new Date(s.date)}
                        onChange={changeNewSession(i)}
                      />
                      <div class="CoachingClassForm__contentFormSchedulesInputsItemType">
                        <RadioButton
                          checked={s.type == 'oneTime'}
                          onChange={changeNewSessionType(i, 'oneTime')}
                        >
                          Ponctuel
                        </RadioButton>
                        <RadioButton
                          checked={s.type == 'recurrentOneMonth'}
                          onChange={changeNewSessionType(
                            i,
                            'recurrentOneMonth'
                          )}
                        >
                          Récurrent 4 semaines
                        </RadioButton>
                        <RadioButton
                          checked={s.type == 'recurrentTwoMonths'}
                          onChange={changeNewSessionType(
                            i,
                            'recurrentTwoMonths'
                          )}
                        >
                          Récurrent 8 semaines
                        </RadioButton>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  class="CoachingClassForm__contentFormSchedulesAdd"
                  onClick={addNewSession}
                >
                  + Ajouter un horaire
                </div>
              </div>
              <div class="CoachingClassForm__contentFormLevels">
                <div class="CoachingClassForm__contentFormLevelsTitle">
                  Niveau
                </div>
                <div class="CoachingClassForm__contentFormLevelsSliders">
                  <div class="CoachingClassForm__contentFormLevelsSlidersEntry">
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabel">
                      Force{' '}
                      <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabelPercentage">
                        {coachingClass.levels?.strength}%
                      </div>
                    </div>
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntrySlider">
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        step={10}
                        value={coachingClass?.levels?.strength || 0}
                        onChange={(v) =>
                          setCoachingClass({
                            ...coachingClass,
                            levels: {
                              ...(coachingClass?.levels || defaultLevels),
                              strength: v as number,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div class="CoachingClassForm__contentFormLevelsSlidersEntry">
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabel">
                      Endurance{' '}
                      <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabelPercentage">
                        {coachingClass.levels?.stamina}%
                      </div>
                    </div>
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntrySlider">
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        step={10}
                        value={coachingClass?.levels?.stamina || 0}
                        onChange={(v) =>
                          setCoachingClass({
                            ...coachingClass,
                            levels: {
                              ...(coachingClass?.levels || defaultLevels),
                              stamina: v as number,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div class="CoachingClassForm__contentFormLevelsSlidersEntry">
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabel">
                      Souplesse{' '}
                      <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabelPercentage">
                        {coachingClass.levels?.flexibility}%
                      </div>
                    </div>
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntrySlider">
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        step={10}
                        value={coachingClass?.levels?.flexibility || 0}
                        onChange={(v) =>
                          setCoachingClass({
                            ...coachingClass,
                            levels: {
                              ...(coachingClass?.levels || defaultLevels),
                              flexibility: v as number,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div class="CoachingClassForm__contentFormLevelsSlidersEntry">
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabel">
                      Mobilité{' '}
                      <div class="CoachingClassForm__contentFormLevelsSlidersEntryLabelPercentage">
                        {coachingClass.levels?.mobility}%
                      </div>
                    </div>
                    <div class="CoachingClassForm__contentFormLevelsSlidersEntrySlider">
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        step={10}
                        value={coachingClass?.levels?.mobility || 0}
                        onChange={(v) =>
                          setCoachingClass({
                            ...coachingClass,
                            levels: {
                              ...(coachingClass?.levels || defaultLevels),
                              mobility: v as number,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="CoachingClassForm__contentFormRow">
              <InputWithLabel
                label="Description"
                value={coachingClass?.description}
                textarea
                onChange={(v) =>
                  setCoachingClass({ ...coachingClass, description: v })
                }
                maxlength={500}
              />
            </div>
            <div class="CoachingClassForm__contentFormMediasWrapper">
              <div class="CoachingClassForm__contentFormMedias">
                {(coachingClass?.medias || []).map((m) => (
                  <div class="CoachingClassForm__contentFormMediasItem">
                    <div
                      class="CoachingClassForm__contentFormMediasItemDelete"
                      onClick={deleteMedia(m)}
                    >
                      <IconClose />
                    </div>
                    {m.type == 'image' && <img src={m.url} />}
                    {m.type == 'video' && <video src={m.url} />}
                  </div>
                ))}
                <label>
                  <input
                    type="file"
                    accept="image/*,video/*"
                    onChange={uploadMedia}
                  />
                  <div class="CoachingClassForm__contentFormMediasAdd">
                    Importer des photos ou une vidéo
                    <IconAdd />
                  </div>
                </label>
              </div>
            </div>
            <div class="CoachingClassForm__contentFormRow">
              <ReactTooltip place="top" type="error" effect="solid" html />
              <Button
                onClick={onValidate}
                disabled={
                  validateClass().length != 0 ||
                  sessions.length > 0 ||
                  newSessions.length > 0
                }
                dataTip={
                  sessions.length == 0 && newSessions.length == 0
                    ? validateClass().join('<br/>')
                    : [
                        ...validateClass(),
                        'Une programmation est définie, le cours sera donc visible à tous',
                      ].join('<br />')
                }
              >
                Enregistrer comme brouillon
              </Button>
              <Button
                onClick={onValidate}
                disabled={
                  validateClass().length != 0 ||
                  (sessions.length == 0 && newSessions.length == 0)
                }
                dataTip={
                  sessions.length == 0 && newSessions.length == 0
                    ? [
                        ...validateClass(),
                        'Au moins une programmation est nécessaire pour valider le cours',
                      ].join('<br />')
                    : validateClass().join('<br/>')
                }
              >
                Valider mon cours
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  )
}
