import { h } from 'preact'
import './Range.scss'
import InputRange from 'react-input-range'

interface Props {
  value: { min: number; max: number }
  limits: { min: number; max: number }
  onChange: (v: { min: number; max: number }) => void
  valueLabel?: string
  title: string
}

export default (props: Props) => {
  return (
    <div class="Range">
      <div class="Range__title">{props.title}</div>
      <InputRange
        formatLabel={
          props.valueLabel ? (v) => `${v}${props.valueLabel}` : undefined
        }
        maxValue={props.limits.max}
        minValue={props.limits.min}
        value={{
          min: props.value ? props.value.min : props.limits.min,
          max: props.value ? props.value.max : props.limits.max,
        }}
        onChange={props.onChange}
      />
    </div>
  )
}
