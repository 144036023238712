import { h } from 'preact'
import './CoachingClassPreviewVertical.scss'
import { CoachingClass } from '../../types/coaching_class'
import StarIcon from '../../../assets/images/icons/icon_star.svg'
import LocationIcon from '../../../assets/images/icons/icon_location.svg'
import PeopleIcon from '../../../assets/images/icons/icon_people.svg'
import ClockIcon from '../../../assets/images/icons/icon_clock.svg'
import IconBootcamp from '../../../assets/images/icons/icon_bootcamp.svg'
import Coach from '../../types/coach'
import { CoachingClassSession } from '../../types/coaching_class_session'
import moment from 'moment'
import { Category } from '../../types/category'

interface Props {
  coachingClass: CoachingClass
  coach: Coach
  sessions: CoachingClassSession[]
  categories: Category[]
}

export default (props: Props) => {
  const { coachingClass } = props

  return (
    <div class="CoachingClassPreviewVertical">
      <div
        class="CoachingClassPreviewVertical__top"
        style={{ backgroundImage: `url(${coachingClass.pictureUrl})` }}
      >
        <div class="CoachingClassPreviewVertical__topContent">
          <div class="CoachingClassPreviewVertical__price">
            <div class="CoachingClassPreviewVertical__priceContent">
              <span>{coachingClass.price}€</span>
            </div>
          </div>
          {props.coach.averageGrade && (
            <div class="CoachingClassPreviewVertical__rating">
              {(props.coach.averageGrade / 10).toFixed(1)} <StarIcon />
            </div>
          )}
          <div class="CoachingClassPreviewVertical__category">
            <img
              src={
                props.categories.find(
                  (c) => c.id == props.coachingClass.categoryId
                )?.iconUrl
              }
            />
          </div>
        </div>
      </div>
      <div class="CoachingClassPreviewVertical__bottom">
        <div class="CoachingClassPreviewVertical__coach">
          {props.coach.firstName} {props.coach.lastName}
        </div>
        <div class="CoachingClassPreviewVertical__name">
          {coachingClass.name}
        </div>
        <div class="CoachingClassPreviewVertical__location">
          <LocationIcon />
          {coachingClass.meetingLocation}
        </div>
        <div class="CoachingClassPreviewVertical__dates">
          {props.sessions.slice(0, 3).map((s) => (
            <div class="CoachingClassPreviewVertical__datesEntry">
              <ClockIcon />
              <span>{moment(s.date).format('dddd Do MMMM')}</span>
              <div class="CoachingClassPreviewVertical__datesEntryValue">
                {moment(s.date).format('HH[H]mm')}-
                {moment(s.date)
                  .add(moment.duration(coachingClass.duration, 'minutes'))
                  .format('HH[H]mm')}
              </div>
            </div>
          ))}
        </div>
        <div class="CoachingClassPreviewVertical__maxAttendees">
          <PeopleIcon />
          {coachingClass.maxAttendees} pers.
        </div>
      </div>
    </div>
  )
}
