import { h } from 'preact'
import { Category } from '../../types/category'
import { useState } from 'preact/hooks'
import IconFilter from '../../../assets/images/icons/icon_filters.svg'
import './FiltersHandler.scss'
import Coach from '../../types/coach'
import MultiselectFilter from './MultiselectFilter'
import RangesFilter from './RangesFilter'
import Button from '../Button'

export interface Filters {
  categories?: Category[]
  coaches?: Coach[]
  cities?: { city: string }[]
  time?: {
    time: { min: number; max: number }
    duration: { min: number; max: number }
  }
  price?: { min: number; max: number; step?: number }
  classTypes?: { name: string; id: ClassType }[]
  taxDeduction?: { label: string; value: boolean }[]
}

export enum ClassType {
  PersonalCoaching,
  CoachingClass,
}

interface Props extends Filters {
  selectedClassTypes: { name: string; id: ClassType }[]
  selectedCategories?: Category[]
  selectedCities?: { city: string }[]
  selectedCoaches?: Coach[]
  selectedTaxDeduction?: { label: string; value: boolean }[]
  selectedTime?: {
    time: { min: number; max: number }
    duration: { min: number; max: number }
  }
  selectedPrice?: {
    min: number
    max: number
  }
  expanded?: boolean
  class?: string
  onChange: (filters: Filters) => void
}

export default (props: Props) => {
  const [expanded, setExpanded] = useState(props.expanded)

  const expand = (e: any) => {
    e.stopPropagation()
    setExpanded(true)
  }

  const reduce = (e: any) => {
    e.stopPropagation()
    setExpanded(false)
  }

  const onCategoriesChange = (selectedCategories: Category[]) => {
    props.onChange({ categories: selectedCategories })
  }

  const onCoachesChange = (selectedCoaches: Coach[]) => {
    props.onChange({ coaches: selectedCoaches })
  }

  const onCitiesChange = (selectedCities: { city: string }[]) => {
    props.onChange({ cities: selectedCities })
  }

  const onTimeChange = (time: any) => {
    props.onChange({ time: { ...props.selectedTime, time } })
  }

  const onDurationChange = (duration: { min: number; max: number }) => {
    props.onChange({ time: { ...props.selectedTime, duration } })
  }

  const onPriceChange = (price: { min: number; max: number }) => {
    props.onChange({ price })
  }

  const onClassTypesChange = (
    classTypes: { name: string; id: ClassType }[]
  ) => {
    props.onChange({ classTypes })
  }

  const onTaxDeductionChange = (
    taxDeduction: { label: string; value: boolean }[]
  ) => {
    props.onChange({ taxDeduction })
  }

  return (
    <div
      class={`FiltersHandler ${!expanded ? 'FiltersHandler--closed' : ''} ${
        props.class ? props.class : ''
      }`}
      onClick={!expanded ? expand : undefined}
    >
      {!expanded ? (
        <div class="FiltersHandler__closed">
          <IconFilter class="FiltersHandler__logo" />
          Filtrer
        </div>
      ) : (
        <div class="FiltersHandler__content">
          <IconFilter class="FiltersHandler__logo" onClick={reduce} />
          {props.categories && (
            <MultiselectFilter
              class="FiltersHandler__contentEntry"
              options={props.categories}
              selected={props.selectedCategories || []}
              onChange={onCategoriesChange}
              optionsLabel="name"
              identifier="id"
              title="Sport"
              placeholder="Sports..."
            />
          )}
          {props.cities && (
            <MultiselectFilter
              class="FiltersHandler__contentEntry RangeFilter__selected"
              options={props.cities}
              selected={props.selectedCities || []}
              onChange={onCitiesChange}
              identifier="city"
              optionsLabel="city"
              title="Ville"
              placeholder="Ville..."
            />
          )}
          {props.time && (
            <RangesFilter
              class={`FiltersHandler__contentEntry ${
                props.selectedTime ? 'RangeFilter--selected' : ''
              }`}
              onChanges={[onTimeChange, onDurationChange]}
              limits={[props.time.time, props.time.duration]}
              values={[props.selectedTime?.time, props.selectedTime?.duration]}
              valueLabels={['h', 'mins']}
              rangeTitles={['Horaires souhaités', 'Durée du cours souhaitée']}
              title={
                props.selectedTime?.time
                  ? `${props.selectedTime?.time.min}h - ${props.selectedTime?.time.max}h`
                  : 'Heure'
              }
            />
          )}
          {props.price && (
            <RangesFilter
              class={`FiltersHandler__contentEntry ${
                props.selectedPrice ? 'RangeFilter--selected' : ''
              }`}
              onChanges={[onPriceChange]}
              limits={[props.price]}
              values={[props.selectedPrice]}
              valueLabels={['€']}
              rangeTitles={['Tarifs souhaités']}
              title={
                props.selectedPrice
                  ? `${props.selectedPrice.min}€ - ${props.selectedPrice.max}€`
                  : 'Tarif'
              }
            />
          )}
          {props.coaches && (
            <MultiselectFilter
              class="FiltersHandler__contentEntry"
              options={props.coaches
                .filter((c) => c.validated && c.firstName && c.lastName)
                .map((c) => ({
                  ...c,
                  name: `${c.firstName} ${c.lastName}`,
                }))}
              selected={props.selectedCoaches || []}
              onChange={onCoachesChange}
              identifier="id"
              optionsLabel="name"
              title="Coach"
              placeholder="Nom du coach..."
            />
          )}
          {props.classTypes && (
            <MultiselectFilter
              class="FiltersHandler__contentEntry"
              options={props.classTypes}
              selected={props.selectedClassTypes || []}
              onChange={onClassTypesChange}
              identifier="id"
              optionsLabel="name"
              title="Type de cours"
              placeholder="Type de cours..."
            />
          )}
          {props.taxDeduction && (
            <MultiselectFilter
              class="FiltersHandler__contentEntry"
              options={props.taxDeduction}
              selected={props.selectedTaxDeduction || []}
              onChange={onTaxDeductionChange}
              identifier="value"
              optionsLabel="label"
              title="Déduction d'impôt"
              placeholder="Déduction d'impôt..."
            />
          )}
          <Button class="FiltersHander__submitButton" onClick={reduce}>
            Filtrer
          </Button>
        </div>
      )}
    </div>
  )
}
