import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { route, RoutableProps } from 'preact-router'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'

interface Props extends RoutableProps {
  matches?: { code: string }
}

export default (props: Props) => {
  useEffect(() => {
    if (!props.matches?.code) {
      route('/')
    } else {
      createStripeConnectAccount(props.matches.code)
    }
  }, [])

  const createStripeConnectAccount = async (code: String) => {
    try {
      await httpClient.req(
        ROUTES.CREATE_STRIPE_CONNECT_ACCOUNT({
          code,
        })
      )
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de lier le compte Stripe. Veuillez ressayer ou nous contacter.'
      )
      console.warn(e)
    }

    route('/my_account')
  }

  return <div></div>
}
