import { h } from 'preact'
import './Multiselect.scss'
import IconSearch from '../../../assets/images/icons/icon_search_gray.svg'
import IconCheck from '../../../assets/images/icons/icon_check.svg'
import { useState } from 'react'

interface Props<T> {
  options: T[]
  selected: T[]
  identifier: string
  optionsLabel: string
  onChange: (s: T[]) => void
  placeholder: string
}

export default <T extends any>(props: Props<T>) => {
  const [search, setSearch] = useState('')

  const toggleSelectedFor = (option: T) => (e: any) => {
    e.stopPropagation()

    if (
      props.selected.find(
        (s: any) => s[props.identifier] == (option as any)[props.identifier]
      )
    ) {
      props.onChange(
        props.selected.filter(
          (s: any) => s[props.identifier] != (option as any)[props.identifier]
        )
      )
    } else {
      props.onChange([...props.selected, option])
    }
  }

  const onSearchChange = (e: any) => {
    setSearch(e.target.value)
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  return (
    <div class="Multiselect" onClick={stopPropagation}>
      <div class="Multiselect__search">
        <IconSearch />
        <input
          type="text"
          placeholder={props.placeholder}
          onInput={onSearchChange}
        />
      </div>
      <div class="Multiselect__separator"></div>
      <div class="Multiselect__options">
        {props.options
          .filter((o: any) =>
            o[props.optionsLabel].toLowerCase().includes(search.toLowerCase())
          )
          .map((o) => (
            <div
              class={`Multiselect__optionsEntry ${
                props.selected.find(
                  (s: any) =>
                    s[props.identifier] == (o as any)[props.identifier]
                )
                  ? 'Multiselect__optionsEntry--selected'
                  : ''
              }`}
              onClick={toggleSelectedFor(o)}
            >
              <div class="Multiselect__optionsEntryCheckbox">
                {props.selected.find(
                  (s: any) =>
                    s[props.identifier] == (o as any)[props.identifier]
                ) && <IconCheck />}
              </div>
              {(o as any)[props.optionsLabel]}
            </div>
          ))}
      </div>
    </div>
  )
}
