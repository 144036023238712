import { h } from 'preact'
import './CoachInfos.scss'
import Button from '../../components/Button'
import { useState } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Coach from '../../types/coach'
import FileInput from '../../components/FileInput'
import CoachDocument from '../../types/coach_document'
import { RoutableProps } from 'preact-router'
import { v4 as uuid } from 'uuid'
import IconSign from '../../../assets/images/icons/icon_file.svg'
import IconCard from '../../../assets/images/icons/icon_card.svg'
import { useEffect } from 'react'
import Toastify from 'toastify-js'

declare var eversign: any

interface Props extends RoutableProps {
  coach: Coach
  documents: CoachDocument[]
  onChange: (u: Coach) => void
}

export default (props: Props) => {
  const [coach] = useState(props.coach)
  const [identityCard, setIdentityCard] = useState(
    props.documents.find((cd) => cd.documentType == 'identity_card')?.fileUrl
  )
  const [professionnalCard, setProfessionalCard] = useState(
    props.documents.find((cd) => cd.documentType == 'professional_card')
      ?.fileUrl
  )
  const [professionalCivilInsurance, setProfessionalCivilInsurance] = useState(
    props.documents.find(
      (cd) => cd.documentType == 'professional_civil_insurance'
    )?.fileUrl
  )
  const [billingMandate] = useState(
    props.documents.find((cd) => cd.documentType == 'billing_mandate')?.fileUrl
  )
  const [generatingBillingMandate, setGeneratingBillingMandate] = useState(
    false
  )

  useEffect(() => {
    const generating =
      JSON.parse(localStorage.getItem('generatingBillingMandate')) || false

    if (
      props.documents.find((cd) => cd.documentType == 'billing_mandate')
        ?.fileUrl &&
      generating
    ) {
      localStorage.removeItem('generatingBillingMandate')
    } else if (generating) {
      setGeneratingBillingMandate(true)
      localStorage.setItem('generatingBillingMandate', JSON.stringify(true))
    }
  }, [])

  const updateCoach = async () => {
    try {
      store.notify(
        Message.Notification,
        'Votre signature a bien été prise en compte. Le document devrait être validé sous peu.'
      )

      const res = await httpClient.req(ROUTES.UPDATE_COACH(coach))

      props.onChange(res)
      store.notify(
        Message.Notification,
        'Informations sauvegardées avec succès'
      )
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible a sauvegarder les informations coach'
      )
      console.warn(e)
    }
  }

  const onProfessionalCardChange = async (file: File) => {
    try {
      let url = await uploadFile(file)

      httpClient.req(
        ROUTES.CREATE_COACH_DOCUMENT({
          documentType: 'professional_card',
          file_url: url,
        })
      )
      setProfessionalCard(url)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder le document')
      console.warn(e)
    }
  }

  const onIdentityCardChange = async (file: File) => {
    try {
      let url = await uploadFile(file)

      httpClient.req(
        ROUTES.CREATE_COACH_DOCUMENT({
          documentType: 'identity_card',
          file_url: url,
        })
      )
      setIdentityCard(url)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder le document')
      console.warn(e)
    }
  }
  const onProfessionalCivilInsuranceChange = async (file: File) => {
    try {
      let url = await uploadFile(file)

      httpClient.req(
        ROUTES.CREATE_COACH_DOCUMENT({
          documentType: 'professional_civil_insurance',
          file_url: url,
        })
      )
      setProfessionalCivilInsurance(url)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder le document')
      console.warn(e)
    }
  }

  const uploadFile = async (file: File) => {
    if (file.size / 1024 / 1024 <= 10) {
      const res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      return res.fileUrl
    } else {
      store.notify(Message.Error, 'La taille max pour les fichiers est de 10Mo')
    }
  }

  const onDocumentDelete = async (document: CoachDocument) => {
    try {
      await httpClient.req(
        ROUTES.DELETE_COACH_DOCUMENT({
          id: document.id,
        })
      )

      if (document.documentType == 'identity_card') {
        setIdentityCard(undefined)
      } else if (document.documentType == 'professional_card') {
        setProfessionalCard(undefined)
      } else if (document.documentType == 'professional_civil_insurance') {
        setProfessionalCivilInsurance(undefined)
      }
    } catch (e) {
      store.notify(Message.Error, 'Impossible de supprimer le document')
      console.warn(e)
    }
  }

  const generateAndShowBillingMandate = async () => {
    Toastify({
      text: 'Le mandat est en cours de génération. Merci de patienter',
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#fcda69',
    }).showToast()

    const res = await httpClient.req(ROUTES.CREATE_BILLING_MANDATE({}))

    eversign.open({
      url: res.url,
      containerID: 'CoachInfos__billingMandate',
      width: '100%',
      height: 600,
      events: {
        loaded: function () {},
        signed: function () {
          document.getElementById('CoachInfos__billingMandate').style.display =
            'none'
          localStorage.setItem('generatingBillingMandate', JSON.stringify(true))
          setGeneratingBillingMandate(true)
          store.notify(
            Message.Notification,
            'Votre signature a bien été prise en compte. Le document devrait être validé sous peu.'
          )
        },
        declined: function () {},
        error: function () {
          store.notify(
            Message.Error,
            'Une erreur est survenue lors de la signature du mandat'
          )
        },
      },
    })
  }

  return (
    <div class="CoachInfos">
      <div class="CoachInfos__title">Mes documents</div>
      <div class="CoachInfos__subtitle">
        {coach.validated
          ? 'Votre profil est validé et et vos cours sont visible par tous les utilisateurs'
          : 'Votre profil sera accepté une fois que tous vos documents seront validés. Vos cours seront alors visibles à tous les utilisateurs.'}
      </div>
      <div class="CoachInfos__form">
        <div class="CoachInfos__formRow">
          <FileInput
            filled={!!identityCard}
            status={
              props.documents.find((cd) => cd.documentType == 'identity_card')
                ?.status
            }
            onChange={onIdentityCardChange}
            label="Carte d'identité"
            previewUrl={identityCard}
            onDelete={() =>
              onDocumentDelete(
                props.documents.find((cd) => cd.documentType == 'identity_card')
              )
            }
          />
          <FileInput
            onChange={onProfessionalCardChange}
            label="Carte professionnelle"
            status={
              props.documents.find(
                (cd) => cd.documentType == 'professional_card'
              )?.status
            }
            filled={!!professionnalCard}
            previewUrl={professionnalCard}
            onDelete={() =>
              onDocumentDelete(
                props.documents.find(
                  (cd) => cd.documentType == 'professional_card'
                )
              )
            }
          />
        </div>
        <div class="CoachInfos__formRow">
          <FileInput
            filled={!!professionalCivilInsurance}
            onChange={onProfessionalCivilInsuranceChange}
            status={
              props.documents.find(
                (cd) => cd.documentType == 'professional_civil_insurance'
              )?.status
            }
            label="Assurance civile professionnelle"
            previewUrl={professionalCivilInsurance}
            onDelete={() =>
              onDocumentDelete(
                props.documents.find(
                  (cd) => cd.documentType == 'professional_civil_insurance'
                )
              )
            }
          />
          <div
            class={`CoachInfos__signBillingMandate ${
              billingMandate || generatingBillingMandate
                ? 'CoachInfos__signBillingMandate--filled'
                : ''
            }`}
            onClick={
              !billingMandate &&
              !generatingBillingMandate &&
              generateAndShowBillingMandate
            }
          >
            <span>
              {billingMandate || generatingBillingMandate
                ? 'Mandat de facturation signé'
                : 'Signer le mandat de facturation'}
            </span>
            <IconSign />
          </div>
        </div>
        <div class="CoachInfos__formRow">
          <div
            class={`CoachInfos__stripe ${
              coach.stripeUserId ? 'CoachInfos__stripe--filled' : ''
            }`}
          >
            <a
              target="_blank"
              href={`https://connect.stripe.com/express/oauth/authorize?client_id=ca_HbPn317OKTiv887k8rQLRn9FZ5lCOalA&state=${uuid()}&suggested_capabilities[]=transfers&email=${
                store.store.JWT.email
              }`}
            >
              <span>
                {coach.stripeUserId
                  ? 'Changer mon compte Stripe'
                  : 'Lier mon compte à Stripe'}
              </span>
              <IconCard />
            </a>
          </div>
        </div>
        <div id="CoachInfos__billingMandate"></div>
      </div>
      <div class="CoachInfos__actions">
        <Button class="CoachInfos__actionsSubmit" onClick={updateCoach}>
          Mettre à jour mes informations
        </Button>
        {/* {coach.stripeUserId ? (
          <a href="https://dashboard.stripe.com/" target="_blank">
            <Button class="CoachInfos__actionsStripe">Dashboard Stripe</Button>
          </a>
        ) : ( */}

        {/* )} */}
      </div>
    </div>
  )
}
