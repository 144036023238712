import { h } from 'preact'
import './Payment.scss'
import { useState, useContext, useEffect } from 'preact/hooks'
import { CoachingClass } from '../../types/coaching_class'
import { CoachingClassSession } from '../../types/coaching_class_session'
import { StepperContext } from 'react-material-stepper'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Button from '../../components/Button'
import Input from '../../components/Input'
import RadioButton from '../../components/RadioButton'
import { Order } from '../../types/order'
import CreditCard from '../../types/credit_card'
import IconCreditCard from '../../../assets/images/icons/icon_credit_card.svg'
import IconAmex from '../../../assets/images/icons/icon_amex.svg'
import IconVisa from '../../../assets/images/icons/icon_visa.svg'
import IconMastercard from '../../../assets/images/icons/icon_mastercard.svg'

interface Props {
  coachingClass: CoachingClass
  onOrderValidate: (order: Order) => void
  setLoading: (v: boolean) => void
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#4dba7f',
      fontFamily: 'Lato',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export default (props: Props) => {
  const { getData } = useContext(StepperContext)
  const [selectedSessions] = useState<CoachingClassSession[]>(
    getData('recap')?.selectedSessions || []
  )
  const [cardHolderName, setCardHolderName] = useState<string>(undefined)
  const [cards, setCards] = useState<CreditCard[]>([])
  const [cgvChecked, setCgvChecked] = useState(false)
  const [saveCard, setSaveCard] = useState(false)
  const [selectedCard, setSelectedCard] = useState<CreditCard>(undefined)
  const stripe = useStripe()
  const elements = useElements()
  const canProcessPayment =
    cgvChecked &&
    (selectedCard || (cardHolderName && cardHolderName.length > 0))

  useEffect(() => {
    fetchCreditCards()
  }, [])

  const fetchCreditCards = async () => {
    try {
      const ccs = await httpClient.req(ROUTES.FETCH_MY_CREDIT_CARDS({}))

      setCards(ccs.data.map((cc: any) => ({ ...cc.card, stripeId: cc.id })))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer les cartes')
      console.warn(e)
    }
  }

  const totalPrice = () => {
    return selectedSessions.length * props.coachingClass.price
  }

  const createPaymentIntent = async () => {
    const order = await httpClient.req(
      ROUTES.CREATE_ORDER({ session_ids: selectedSessions.map((s) => s.id) })
    )

    return order
  }

  const makePayment = async (order: Order) => {
    const result = await stripe.confirmCardPayment(
      order.stripePaymentIntentSecret,
      {
        payment_method: selectedCard
          ? selectedCard.stripeId
          : {
              card: elements.getElement(CardElement),
              billing_details: {
                name: `${store.store.JWT.firstName} ${store.store.JWT.lastName}`,
              },
            },
        setup_future_usage: saveCard ? 'on_session' : undefined,
        receipt_email: store.store.JWT.email,
      }
    )

    if (result.error) {
      store.notify(Message.Error, result.error.message)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        props.onOrderValidate(order)
      }
    }
  }

  const validatePayment = async () => {
    try {
      if (!stripe || !elements) {
        return
      }

      props.setLoading(true)

      let pi = await createPaymentIntent()

      await makePayment(pi)
    } catch (e) {
      store.notify(
        Message.Error,
        e == 'MAX_ATTENDEES_REACHED'
          ? 'Nombre de participant max atteint. Veuillez choisir une autre session'
          : 'Impossible de créer la commande'
      )
      console.warn(e)
    } finally {
      props.setLoading(false)
    }
  }

  const toggleCard = (c: CreditCard) => () => {
    if (c.stripeId == selectedCard?.stripeId) {
      setSelectedCard(undefined)
    } else {
      setSelectedCard(c)
    }
  }

  return (
    <div class="CoachingClassRegistrationPayment">
      <div class="CoachingClassRegistrationPayment__title">
        Choisissez votre moyen de paiement
      </div>
      <div class="CoachingClassRegistrationPayment__content">
        <div class="CoachingClassRegistrationPayment__contentSavedCards">
          <div class="CoachingClassRegistrationPayment__contentSavedCardsTitle">
            Cartes enregistrées
          </div>
          {cards.map((c) => {
            let Image = IconCreditCard

            if (c.brand == 'mastercard') {
              Image = IconMastercard
            } else if (c.brand == 'visa') {
              Image = IconVisa
            } else if (c.brand == 'amex') {
              Image = IconAmex
            }

            return (
              <div class="CoachingClassRegistrationPayment__contentSavedCardsEntry">
                <RadioButton
                  onChange={toggleCard(c)}
                  checked={selectedCard?.stripeId == c.stripeId}
                >
                  <Image />
                  <div class="CoachingClassRegistrationPayment__contentSavedCardsEntryInfos">
                    <div class="CoachingClassRegistrationPayment__contentSavedCardsEntryLast4">
                      **** **** **** {c.last4}
                    </div>
                    <div class="CoachingClassRegistrationPayment__contentSavedCardsEntryExpiration">
                      Date d'expiration : {c.expMonth}/{c.expYear}
                    </div>
                  </div>
                </RadioButton>
              </div>
            )
          })}
        </div>
        {!selectedCard && (
          <div class="CoachingClassRegistrationPayment__contentNewCard">
            <div class="CoachingClassRegistrationPayment__contentNewCardTitle">
              Nouvelle carte de paiement
            </div>
            <div class="CoachingClassRegistrationPayment__contentNewCardHolderName">
              <Input
                placeholder="Nom du titulaire"
                onChange={setCardHolderName}
                value={cardHolderName}
              />
            </div>
            <div class="CoachingClassRegistrationPayment__contentNewCardWrapper">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <div class="CoachingClassRegistrationPayment__contentSaveCard">
              <RadioButton checked={saveCard} onChange={setSaveCard}>
                Sauvegarder cette carte pour plus tard
              </RadioButton>
            </div>
          </div>
        )}
        <div class="CoachingClassRegistrationPayment__contentCGV">
          <RadioButton checked={cgvChecked} onChange={setCgvChecked}>
            En effectuant cette réservation j'accepte les{' '}
            <a href="/tos" target="_blank">
              CGV
            </a>
          </RadioButton>
        </div>
      </div>
      <div class="CoachingClassRegistrationPayment__footer">
        <div class="CoachingClassRegistrationPayment__footerTotal">
          <div class="CoachingClassRegistrationPayment__footerTotalLeft">
            Total à régler :
          </div>
          <div class="CoachingClassRegistrationPayment__footerTotalRight">
            {totalPrice()}€
          </div>
        </div>
        <Button onClick={validatePayment} disabled={!canProcessPayment}>
          Payer
        </Button>
      </div>
    </div>
  )
}
