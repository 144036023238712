import { h } from 'preact'
import './Button.scss'

interface Props {
  children?: JSX.Element | (JSX.Element | String)[] | string
  onClick?: (e: MouseEvent) => void
  shadow?: boolean
  shadowOnHover?: boolean
  class?: string
  color?: string
  disabled?: boolean
  dataTip?: string
}

export default (props: Props) => {
  return (
    <div
      class={`Button ${props.shadow ? 'Button--shadow' : ''} ${
        props.shadowOnHover ? 'Button--shadowOnHover' : ''
      } ${props.class ? props.class : ''} ${
        props.disabled ? 'Button--disabled' : ''
      }`}
      onClick={!props.disabled && props.onClick}
      data-tip={props.dataTip}
    >
      {props.children}
    </div>
  )
}
