import { h } from 'preact'
import './MenuEntry.scss'

interface Props {
  focused?: boolean
  icon?: preact.ComponentType
  iconRight?: preact.ComponentType
  title: string
  onClick: () => void
  class?: string
  negative?: boolean
}

export default (props: Props) => {
  const Icon = props.icon
  const IconRight = props.iconRight

  return (
    <div
      class={`MenuEntry ${props.focused ? 'MenuEntry--focused' : ''} ${
        props.class || ''
      } ${props.negative ? 'MenuEntry--negative' : ''}`}
      onClick={props.onClick}
    >
      {Icon && <Icon />}
      <div class="MenuEntry__text">
        {props.title}
        {IconRight && (
          <div class="MenuEntry__iconRight">
            <IconRight />
          </div>
        )}
      </div>
    </div>
  )
}
