import { h } from 'preact'
import './Input.scss'

interface Props {
  class?: string
  placeholder?: string
  type?: string
  onChange?: (val: string) => void
  value?: string
}

export default (props: Props) => {
  return (
    <div class={`Input ${props.class ? props.class : ''}`}>
      <input
        placeholder={props.placeholder}
        type={props.type}
        onChange={(e: any) => props.onChange(e.target.value)}
        value={props.value}
      ></input>
    </div>
  )
}
