import { UUID } from './types/common'
import { User } from './types/user'
import Coach from './types/coach'
import CoachDocument from './types/coach_document'
import { CoachingClass } from './types/coaching_class'
import { CoachingClassSession } from './types/coaching_class_session'
import { Message } from './types/messsage'
import { Order } from './types/order'

export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string
  method: Method
  data?: any
  params?: any
  auth?: boolean
  file?: File
  extern?: boolean
  skipHumps?: boolean
  headers?: any
}

export const BASE_URL = 'https://api.gymspot.fr'

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  LOGIN: (data: { email: string; password: string }) => ({
    path: '/users/login',
    method: Method.Post,
    auth: false,
    data,
  }),
  REFRESH_JWT: (data: { token: String }) => ({
    path: `/users/refresh/${data.token}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  SIGNUP: (data: User) => ({
    path: '/users',
    method: Method.Post,
    auth: false,
    data,
  }),
  FETCH_USER: (data: { id: UUID }) => ({
    path: `/users/${data.id}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_ME: (data: {}) => ({
    path: '/users/me',
    method: Method.Get,
    auth: true,
    data,
  }),
  UPDATE_ME: (data: User) => ({
    path: '/users/me',
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_COACHING_CLASS: (data: { id: UUID }) => ({
    path: `/coaching_classes/${data.id}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_COACHING_CLASS_SESSIONS: (data: { id: UUID }) => ({
    path: `/coaching_classes/${data.id}/sessions`,
    method: Method.Get,
    auth: false,
    data,
  }),
  CREATE_COACHING_CLASS_SESSION: (data: CoachingClassSession) => ({
    path: `/coaching_class_sessions`,
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_ALL_SESSIONS: (data: {}) => ({
    path: `/coaching_class_sessions`,
    method: Method.Get,
    auth: false,
    data,
  }),
  UPDATE_COACHING_CLASS_SESSION: (data: { id: UUID }) => ({
    path: `/coaching_class_sessions/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  DELETE_COACHING_CLASS_SESSION: (data: { id: UUID }) => ({
    path: `/coaching_class_sessions/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  FETCH_COACHING_CLASSES: (data: {}) => ({
    path: `/coaching_classes`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_CATEGORIES: (data: {}) => ({
    path: `/categories`,
    method: Method.Get,
    auth: false,
    data,
  }),
  UPLOAD_FILE: (params: { file: File }) => ({
    path: `/files/`,
    method: Method.Post,
    auth: false,
    file: params.file,
  }),
  FETCH_COACH: (data: { id: UUID }) => ({
    path: `/coaches/${data.id}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  CREATE_COACH: (data: Coach) => ({
    path: `/coaches`,
    method: Method.Post,
    auth: true,
    data,
  }),
  CREATE_COACH_DOCUMENT: (data: CoachDocument) => ({
    path: `/coach_documents`,
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_COACH_DOCUMENT: (data: { id: UUID }) => ({
    path: `/coach_documents/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  CREATE_COACHING_CLASS: (data: CoachingClass) => ({
    path: `/coaching_classes`,
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_COACHING_CLASS: (data: CoachingClass) => ({
    path: `/coaching_classes/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_COACH_COACHING_CLASSES: (data: { id: UUID }) => ({
    path: `/coaches/${data.id}/coaching_classes`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_COACHES: (data: {}) => ({
    path: `/coaches`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_ME_COACH: (data: {}) => ({
    path: `/users/me/coach`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_ME_COACH_DOCUMENTS: (data: {}) => ({
    path: `/users/me/coach_documents`,
    method: Method.Get,
    auth: true,
    data,
  }),
  UPDATE_COACH: (data: Coach) => ({
    path: `/coaches/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPDATE_ORDER: (data: Order & { invoiceUrl: string }) => ({
    path: `/orders/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  REVERSE_GEOCODING: (data: { lat: number; lng: number; apiKey: string }) => ({
    path: `https://maps.googleapis.com/maps/api/geocode/json?key=${data.apiKey}&latlng=${data.lat},${data.lng}`,
    method: Method.Get,
    auth: false,
    extern: true,
    data,
  }),
  CREATE_BILLING_MANDATE: (data: {}) => ({
    path: `/eversign/generate_billing_mandate`,
    method: Method.Post,
    auth: true,
    data,
  }),
  CREATE_STRIPE_CONNECT_ACCOUNT: (data: { code: string }) => ({
    path: `/stripe/accounts`,
    method: Method.Post,
    auth: true,
    data,
  }),
  CREATE_ORDER: (data: { session_ids: UUID[] }) => ({
    path: `/orders`,
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_MY_ORDERS: (data: {}) => ({
    path: `/users/me/orders`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_MY_CREDIT_CARDS: (data: {}) => ({
    path: `/users/me/credit_cards`,
    method: Method.Get,
    auth: true,
    data,
  }),
  DELETE_CREDIT_CARD: (data: { stripeId: string }) => ({
    path: `/users/me/credit_cards/${data.stripeId}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  FETCH_COACH_REGISTRATIONS: (data: {}) => ({
    path: `/coaches/me/registrations`,
    method: Method.Get,
    auth: true,
    data,
  }),
  SEND_MESSAGE: (data: Message) => ({
    path: `/orders/${data.orderId}/messages?session_id=${data.sessionId}`,
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_SESSION_MESSAGES: (data: { orderId: UUID; sessionId: UUID }) => ({
    path: `/orders/${data.orderId}/messages?session_id=${data.sessionId}`,
    method: Method.Get,
    auth: true,
    data,
  }),
  CREATE_COACH_REVIEW: (data: {
    coachId: UUID
    rating: number
    comment?: string
  }) => ({
    path: `/coaches/${data.coachId}/reviews`,
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_COACH_REVIEWS: (data: {
    coachId: UUID
    rating: number
    comment?: string
  }) => ({
    path: `/coaches/${data.coachId}/reviews`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_MY_COACH_REVIEW: (data: {
    userId: UUID
    coachId: UUID
    rating: number
    comment?: string
  }) => ({
    path: `/coaches/${data.coachId}/reviews?from=${data.userId}`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_RECEIPT_URL: (data: { orderId: UUID }) => ({
    path: `/orders/${data.orderId}/receipt`,
    method: Method.Get,
    auth: true,
    data,
  }),
  CANCEL_ORDER_SESSION: (data: { orderId: UUID; sessionId: UUID }) => ({
    path: `/orders/${data.orderId}/cancel_session`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  DELETE_COACHING_CLASS: (data: { id: UUID }) => ({
    path: `/coaching_classes/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  ASK_RESET_PASSWORD: (data: { email: string }) => ({
    path: `/users/forgotten_password`,
    method: Method.Post,
    auth: false,
    data,
  }),
  SUBMIT_RESET_PASSWORD: (data: { token: string; password: string }) => ({
    path: `/users/reset_password`,
    method: Method.Post,
    auth: false,
    data,
  }),
  UPDATE_PASSWORD: (data: { oldPassword: string; password: string }) => ({
    path: `/users/change_password`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  LOGIN_GOOGLE: (data: { token: string }) => ({
    path: `/users/login_google`,
    method: Method.Post,
    auth: false,
    data,
  }),
}

export default ROUTES
