import { h } from 'preact'
import './Layout.scss'
import Header from './Header'
import Footer from './Footer'
import Router from 'preact-router'
import CoachingClassDetails from '../CoachingClasses/CoachingClassDetails'
import Home from '../Home'
import Signin from '../Signin'
import Signup from '../Signup'
import { useState } from 'preact/hooks'
import store, { Stored } from '../services/store'
import { useEffect } from 'react'
import CoachingClassesList from '../CoachingClasses/CoachingClassesList'
import MyAccount from '../MyAccount'
import CoachDetails from '../CoachDetails'
import CoachSignup from '../CoachSignup'
import StripeExpressRedirect from '../Stripe/StripeExpressRedirect'
import LegalMentions from '../LegalMentions'
import CGV from '../CGV'
import ForgottenPassword from '../Signin/ForgottenPassword'
import FAQ from '../FAQ'
import CoachGuide from '../CoachGuide'

export default () => {
  const [loggedIn, setLoggedIn] = useState(store.store.RawJWT)
  const [scrolled, setScrolled] = useState(window.scrollY > 0)
  const [showAccountMenu, setShowAccountMenu] = useState(false)

  useEffect(() => {
    setScrolled(window.scrollY > 0)
    window.addEventListener('scroll', () => setScrolled(window.scrollY > 0))
  }, [])

  store.listen(Stored.RawJWT, setLoggedIn)

  const toggleShowAccountMenu = () => {
    setShowAccountMenu((prev) => !prev)
  }

  return (
    <div class="Layout">
      <Router
        onChange={(args) => {
          window.scrollTo(0, 0)
          if (args.url != args.previous && args.url == '/my_account') {
            setShowAccountMenu(false)
          }
        }}
      >
        <Header
          default
          loggedIn={loggedIn}
          withSearchBar
          withBackground
          scrolled={scrolled}
          toggleShowAccountMenu={toggleShowAccountMenu}
        />
        <Header
          path="/"
          loggedIn={loggedIn}
          withSearchBar={false}
          scrolled={scrolled}
          withBackground={scrolled}
          toggleShowAccountMenu={toggleShowAccountMenu}
        />
        <Header
          path="/signin"
          loggedIn={loggedIn}
          withSearchBar={false}
          scrolled={scrolled}
          withBackground={scrolled}
          toggleShowAccountMenu={toggleShowAccountMenu}
        />
        <Header
          path="/signup"
          loggedIn={loggedIn}
          withSearchBar={false}
          scrolled={scrolled}
          withBackground={scrolled}
          toggleShowAccountMenu={toggleShowAccountMenu}
        />
        <Header
          path="/coach_signup"
          loggedIn={loggedIn}
          withSearchBar={false}
          scrolled={scrolled}
          withBackground={scrolled}
          toggleShowAccountMenu={toggleShowAccountMenu}
        />
      </Router>
      <div class="Layout__content">
        <Router>
          <Home path="/" default />
          {!loggedIn && <Signup path="/signup" />}
          {(!loggedIn || store.store.JWT.role == 'user') && (
            <CoachSignup path="/coach_signup" />
          )}
          {!loggedIn && <Signin path="/signin" />}
          {!loggedIn && <ForgottenPassword path="/forgotten_password" />}
          {!loggedIn && (
            <ForgottenPassword path="/reset_password" resetPassword />
          )}
          <CoachingClassDetails path="/coaching_classes/:id" />
          <CoachingClassesList path="/coaching_classes/search" />
          <CoachingClassesList path="/coaching_classes" />
          <CoachDetails path="/coaches/:id" />
          <LegalMentions path="/legal_notice" />
          <CGV path="/tos" />
          <FAQ path="/faq" />
          <CoachGuide path="/coach_guide" />
          {loggedIn && (
            <MyAccount
              path="/my_account"
              showMenu={showAccountMenu}
              toggleShowMenu={toggleShowAccountMenu}
            />
          )}
          {loggedIn && <StripeExpressRedirect path="/stripe/express" />}
        </Router>
      </div>
      <Footer />
    </div>
  )
}
