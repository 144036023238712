import { h } from 'preact'
import './Recap.scss'
import IconClose from '../../../assets/images/icons/icon_remove.svg'
import Button from '../../components/Button'
import moment from 'moment'
import { useState, useContext } from 'preact/hooks'
import { CoachingClass } from '../../types/coaching_class'
import { CoachingClassSession } from '../../types/coaching_class_session'
import { StepperContext } from 'react-material-stepper'

interface Props {
  coachingClass: CoachingClass
  sessions: CoachingClassSession[]
}

export default (props: Props) => {
  const { resolve, goAt, getData } = useContext(StepperContext)
  const [selectedSessions, setSelectedSessions] = useState<
    CoachingClassSession[]
  >(getData('schedules')?.selectedSessions || [])

  const totalPrice = () => {
    return selectedSessions.length * props.coachingClass.price
  }

  const removeSession = (session: CoachingClassSession) => () => {
    setSelectedSessions(selectedSessions.filter((s) => s.id != session.id))
  }

  const validate = () => {
    resolve({ selectedSessions })
  }

  const goToSchedules = () => {
    resolve({ selectedSessions })
    goAt('schedules')
  }

  return (
    <div class="CoachingClassRegistrationRecap">
      <div class="CoachingClassRegistrationRecap__title">
        Récapitulatif de vos vours
      </div>
      <div class="CoachingClassRegistrationRecap__content">
        <div class="CoachingClassRegistrationRecap__content">
          {selectedSessions.map((s) => (
            <div class="CoachingClassRegistrationRecap__contentEntry">
              <div class="CoachingClassRegistrationRecap__contentEntryLeft">
                <div
                  class="CoachingClassRegistrationRecap__contentEntryRemove"
                  onClick={removeSession(s)}
                >
                  <IconClose />
                </div>
                <div class="CoachingClassRegistrationRecap__contentEntryDate">
                  {moment(s.date).format('ddd DD MMMM YYYY')}
                </div>
              </div>
              <div class="CoachingClassRegistrationRecap__contentEntrySeparator" />
              <div class="CoachingClassRegistrationRecap__contentEntryRight">
                <div class="CoachingClassRegistrationRecap__contentEntryTime">
                  {moment(s.date).format('HH[h]mm')} -{' '}
                  {moment(s.date)
                    .add(props.coachingClass.duration, 'minutes')
                    .format('HH[h]mm')}
                </div>
                <div class="CoachingClassRegistrationRecap__contentEntryPrice">
                  {props.coachingClass.price}€
                </div>
              </div>
            </div>
          ))}
          <div
            class="CoachingClassRegistrationRecap__contentAdd"
            onClick={goToSchedules}
          >
            + Ajouter un cours
          </div>
        </div>

        <div class="CoachingClassRegistrationRecap__footer">
          <div class="CoachingClassRegistrationRecap__footerTotal">
            <div class="CoachingClassRegistrationRecap__footerTotalLeft">
              Total à régler :
            </div>
            <div class="CoachingClassRegistrationRecap__footerTotalRight">
              {totalPrice()}€
            </div>
          </div>
          <div class="CoachingClassRegistrationRecap__footerValidate">
            <Button onClick={validate}>Aller au paiement</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
