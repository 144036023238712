import { h } from 'preact'
import Logo from '../../../assets/images/logo.svg'
import IconFacebook from '../../../assets/images/icons/icon_facebook_footer.svg'
import IconInstagram from '../../../assets/images/icons/icon_instagram_footer.svg'
import IconLinkedin from '../../../assets/images/icons/icon_linkedin.png'
import IconRecruitment from '../../../assets/images/icons/icon_recruitment.svg'
import './Footer.scss'
import { useState, useEffect } from 'preact/hooks'
import { Category } from '../../types/category'
import ROUTES from '../../routes'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'

export default () => {
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await fetchCategories()
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      res.sort((c1: Category, c2: Category) => (c1.name > c2.name ? 1 : -1))
      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  return (
    <div class="Footer">
      <div class="Footer__content">
        <div class="Footer__logo Footer__section">
          <Logo />
        </div>
        <div class="Footer__coaches Footer__section">
          <div class="Footer__title">Nos coachs sprotifs</div>
          <div class="Footer__coachesContent">
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=43.296482,5.36978"
              target="_blank"
            >
              Coach sportif Marseille
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=45.764043,4.835659"
              target="_blank"
            >
              Coach sportif Lyon
            </a>
            <a href="" target="_blank">
              Coach sportif Toulouse
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=43.604652,1.444209"
              target="_blank"
            >
              Coach sportif Nice
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=47.218371,-1.553621"
              target="_blank"
            >
              Coach sportif Nantes
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=43.610769,3.876716"
              target="_blank"
            >
              Coach sportif Montpellier
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.5734053,7.752111299999999"
              target="_blank"
            >
              Coach sportif Strasbourg
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=44.837789,-0.57918"
              target="_blank"
            >
              Coach sportif Bordeaux
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=50.62925,3.057256"
              target="_blank"
            >
              Coach sportif Lille
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.8396952,2.2399123"
              target="_blank"
            >
              Coach sportif Boulogne-Billancourt
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.8245306,2.2743419"
              target="_blank"
            >
              Coach sportif Issy-les-Moulineaux
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.759255,2.302553"
              target="_blank"
            >
              Coach sportif Antony
            </a>
            <a href="" target="_blank">
              Coach sportif Levallois-Perret
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.89321700000001,2.287864"
              target="_blank"
            >
              Coach sportif Neuilly
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.828508,2.2188068"
              target="_blank"
            >
              Coach sportif Hauts de Seine
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.801408,2.130122"
              target="_blank"
            >
              Coach sportif Versailles
            </a>
            <a
              href="https://gymspot.fr/coaching_classes/search?mapCenter=48.7850939,1.8256572"
              target="_blank"
            >
              Coach sportif Yvelines
            </a>
          </div>
        </div>
        <div class="Footer__categories Footer__section">
          <div class="Footer__title">Nos programmes sportifs</div>
          <div class="Footer__categoriesContent">
            {categories
              .filter((c) => !c.categoryId)
              .map((c) => (
                <div class="Footer__categoriesContentEntry">
                  <a href={`/coaching_classes/search?categoryId=${c.id}`}>
                    {c.name}
                  </a>
                </div>
              ))}
          </div>
        </div>
        <div class="Footer__links Footer__section">
          <div class="Footer__title">Liens utiles</div>
          <div>
            <a href="/faq">FAQ</a>
          </div>
          <div>
            <a href="/coach_guide">Guide coach sportif</a>
          </div>
          <div>
            <a href="https://corporate.gymspot.fr" target="_blank">
              Gymspot corporate
            </a>
          </div>
          <div>
            <a href="/tos#privacy">Politique de confidentialité</a>
          </div>
          <div>
            <a href="/tos">CGV</a>
          </div>
          <div>
            <a href="/legal_notice">Mentions légales</a>
          </div>
          <a href="/coach_signup">
            <div class="Footer__button Footer__recruitment">
              Recrutement Coach Sportif
              <IconRecruitment />
            </div>
          </a>
          <div class="Footer__button">
            <a href="mailto:contact@gymspot.fr" target="_blank">
              Il n’y a pas de cours ou de coach dans votre ville ?<br />
              Dites-le nous !
            </a>
          </div>
        </div>
        <div>
          <div class="Footer__contact Footer__section">
            <div class="Footer__title">Nous contacter</div>
            <div>1, place Paul Verlaine</div>
            <div>92100 Boulogne-Billancourt</div>
            <div class="Footer__highlight">
              <a href="mailto:contact@gymspot.fr" target="_blank">
                contact@gymspot.fr
              </a>
            </div>
          </div>
          <div class="Footer__socialNetworks Footer__section">
            <div class="Footer__title">Suivez-nous</div>
            <div class="Footer__socialNetworksIcons">
              <a href="https://www.facebook.com/Gymspotfr/" target="_blank">
                <IconFacebook />
              </a>
              <a
                href="https://www.instagram.com/gymspotfr/?hl=fr"
                target="_blank"
              >
                <IconInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/gymspot-fr/"
                target="_blank"
              >
                <img src={IconLinkedin} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="Footer__copyright">&copy; 2020 Gymspot</div>
    </div>
  )
}
