import { h } from 'preact'
import { Elements } from '@stripe/react-stripe-js'

const MultiStripeProvider = ({
  stripe,
  children,
}: {
  stripe: any
  children: any
}) => <Elements stripe={stripe}>{children}</Elements>

export default MultiStripeProvider
