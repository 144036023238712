import { h } from 'preact'
import './MyCoachingClasses.scss'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Coach from '../../types/coach'
import { CoachingClass } from '../../types/coaching_class'
import { Category } from '../../types/category'
import IconEdit from '../../../assets/images/icons/icon_edit.svg'
import IconAdd from '../../../assets/images/icons/icon_add.svg'
import IconClose from '../../../assets/images/icons/icon_remove.svg'
import CoachingClassForm from './CoachingClassForm'
import { CoachingClassSession } from '../../types/coaching_class_session'
import { UUID } from '../../types/common'
import moment from 'moment'
import IconFilter from '../../../assets/images/icons/icon_filters.svg'
import ReactPaginate from 'react-paginate'

interface Props {
  coach: Coach
}

export default (props: Props) => {
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [filteredCoachingClasses, setFilteredCoachingClasses] = useState<
    CoachingClass[]
  >([])
  const [coachingClassesOffset, setCoachingClassesOffset] = useState(0)
  const [categories, setCategories] = useState<Category[]>([])
  const [showForm, setShowForm] = useState(false)
  const [selectedCoachingClass, setSelectedCoachingClass] = useState<
    CoachingClass
  >(undefined)
  const [filter, setFilter] = useState('live')
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredCoachingClasses(
      coachingClasses
        .filter((cc) =>
          filter == 'live'
            ? sessionsForCoachingClass(cc.id).length > 0
            : sessionsForCoachingClass(cc.id).length == 0
        )
        .slice(coachingClassesOffset * 15, coachingClassesOffset * 15 + 15)
    )
  }, [coachingClasses, coachingClassesOffset, filter, sessions])

  const fetchData = async () => {
    await fetchCategories()
    await fetchCoachinClasses()
  }

  const fetchCoachinClasses = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH_COACHING_CLASSES({
          id: props.coach.id,
        })
      )

      res.forEach((cc: CoachingClass) => fetchSessions(cc.id))

      setCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours')
      console.warn(e)
    }
  }

  const fetchSessions = async (cc_id: UUID) => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_COACHING_CLASS_SESSIONS({ id: cc_id })
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions((prev) => [
        ...prev,
        ...res.filter((s) => s.date > Date.now()),
      ])
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les sessions')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const displayForm = (cc: CoachingClass) => () => {
    setSelectedCoachingClass(cc)
    setShowForm(true)
  }

  const deleteCoachingClass = (cc: CoachingClass) => async () => {
    if (
      confirm(
        'Êtes-vous sûr de vouloir supprimer ce cours ? Cette action est irréversible.'
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_COACHING_CLASS({ id: cc.id }))

        setCoachingClasses((prev) => prev.filter((cc2) => cc2.id != cc.id))
      } catch (err) {
        store.notify(Message.Error, 'Impossible de supprimer le cours')
        console.warn(err)
      }
    }
  }

  const onFormClose = () => {
    setShowForm(false)
    setSelectedCoachingClass(undefined)
  }

  const createAndUpdateSessions = (
    sessions: CoachingClassSession[],
    cc_id?: string
  ) => {
    sessions.forEach(async (s) => {
      try {
        if (s.id) {
          let res = await httpClient.req(
            ROUTES.UPDATE_COACHING_CLASS_SESSION(s)
          )
          let idx = sessions.findIndex((s) => s.id === res.id)

          sessions[idx] = res
          setSessions((prev) => [...prev])
        } else {
          let max = 0

          if (s.type == 'recurrentOneMonth') {
            max = 3
          } else if (s.type == 'recurrentTwoMonths') {
            max = 7
          }
          for (let i = 0; i <= max; i++) {
            const params = {
              ...s,
              coaching_class_id: cc_id,
              date: moment(s.date).add(i, 'weeks').unix() * 1000,
            }
            let res = await httpClient.req(
              ROUTES.CREATE_COACHING_CLASS_SESSION(params)
            )

            setSessions((prev) => [...prev, res])
          }
        }
      } catch (err) {
        store.notify(Message.Error, 'Impossible de sauvegarder la session')
        console.warn(err)
      }
    })
  }

  const deleteSessions = (sessions: UUID[]) => {
    sessions.forEach(async (id) => {
      try {
        await httpClient.req(ROUTES.DELETE_COACHING_CLASS_SESSION({ id }))
      } catch (err) {
        store.notify(Message.Error, 'Impossible de supprimer la session')
        console.warn(err)
      }
    })
  }

  const onFormValidate = async (
    coachingClass: CoachingClass,
    sessions: CoachingClassSession[],
    sessionsToDelete: UUID[]
  ) => {
    try {
      const route = coachingClass.id
        ? ROUTES.UPDATE_COACHING_CLASS
        : ROUTES.CREATE_COACHING_CLASS
      const res = await httpClient.req(route(coachingClass))

      if (coachingClass.id) {
        let idx = coachingClasses.findIndex((cc) => cc.id === coachingClass.id)

        coachingClasses[idx] = res
        setCoachingClasses([...coachingClasses])
      } else {
        setCoachingClasses([...coachingClasses, res])
      }

      await createAndUpdateSessions(sessions, res.id)
      deleteSessions(sessionsToDelete)
      setShowForm(false)
    } catch (err) {
      store.notify(Message.Error, 'Impossible de sauvegarder le cours')
      console.warn(err)
    } finally {
      setSelectedCoachingClass(undefined)
    }
  }

  const sessionsForCoachingClass = (cc_id: UUID) => {
    return sessions.filter((s) => s.coachingClassId == cc_id && !s.canceled)
  }

  const goToPage = ({ selected }: { selected: number }) =>
    setCoachingClassesOffset(selected)

  return (
    <div class="MyCoachingClasses">
      {showForm && (
        <CoachingClassForm
          coachingClass={selectedCoachingClass}
          onClose={onFormClose}
          onValidate={onFormValidate}
        />
      )}
      <div class="MyCoachingClasses__title">
        Mes cours
        <div class="MyCoachingClasses__titleFilter">
          <IconFilter />
          <select onChange={(e: any) => setFilter(e.target.value)}>
            <option value="live" selected={filter == 'live'}>
              Planifiés
            </option>
            <option value="draft" selected={filter == 'draft'}>
              Brouillons
            </option>
          </select>
        </div>
        <div
          class="MyCoachingClasses__titleAdd"
          onClick={displayForm(undefined)}
        >
          <IconAdd />
          <span>Créer un cours</span>
        </div>
      </div>
      <div class="MyCoachingClasses__subtitle">
        {props.coach.validated
          ? 'Votre profil est validé et et vos cours sont visible par tous les utilisateurs'
          : 'Votre profil sera accepté une fois que tous vos documents seront validés. Vos cours seront alors visibles à tous les utilisateurs.'}
      </div>
      <div class="MyCoachingClasses__table">
        <table>
          <thead>
            <tr>
              <th class="MyCoachingClasses__tableCell--mobileVisible">Nom</th>
              <th>Catégorie</th>
              <th>Durée</th>
              <th>Localisation</th>
              <th>Prix</th>
              <th>Nbr. personnes max</th>
              <th>Nbr. cours planifiés</th>
              <th class="MyCoachingClasses__tableCell--mobileVisible"></th>
            </tr>
          </thead>
          <tbody>
            {filteredCoachingClasses.map((cc) => {
              const category = categories.find((c) => c.id == cc.categoryId)

              return (
                <tr>
                  <td class="MyCoachingClasses__tableCell--mobileVisible">
                    <div class="MyCoachingClasses__tableName">
                      <img
                        class="MyCoachingClasses__tableNameCategoryIcon"
                        src={
                          category?.iconUrl ||
                          categories.find((c) => c.id == category.categoryId)
                            ?.iconUrl
                        }
                      />
                      <a href={`/coaching_classes/${cc.id}`}>{cc.name}</a>
                    </div>
                  </td>
                  <td>
                    <div class="MyCoachingClasses__tableCategory">
                      <img
                        src={
                          category?.iconUrl ||
                          categories.find((c) => c.id == category.categoryId)
                            ?.iconUrl
                        }
                      />
                      {category.name}
                    </div>
                  </td>
                  <td>
                    <div>{cc.duration} mins</div>
                  </td>
                  <td>
                    <div>{cc.meetingLocation}</div>
                  </td>
                  <td>
                    <div class="MyCoachingClasses__tablePrice">{cc.price}€</div>
                  </td>
                  <td>
                    <div class="MyCoachingClasses__tableMaxAttendees">
                      {cc.maxAttendees}
                    </div>
                  </td>
                  <td>
                    <div class="MyCoachingClasses__tableMaxAttendees">
                      {sessionsForCoachingClass(cc.id).length}
                    </div>
                  </td>
                  <td class="MyCoachingClasses__tableCell--mobileVisible">
                    <div class="MyCoachingClasses__tableActions">
                      <div
                        class="MyCoachingClasses__tableAction"
                        onClick={displayForm(cc)}
                      >
                        <IconEdit />
                      </div>
                      <div
                        class={`MyCoachingClasses__tableAction${
                          sessionsForCoachingClass(cc.id).length > 0
                            ? ' MyCoachingClasses__tableAction--disabled'
                            : ''
                        }`}
                        onClick={
                          sessionsForCoachingClass(cc.id).length == 0 &&
                          deleteCoachingClass(cc)
                        }
                      >
                        <IconClose />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={
            coachingClasses.filter((cc) =>
              filter == 'live'
                ? sessionsForCoachingClass(cc.id).length > 0
                : sessionsForCoachingClass(cc.id).length == 0
            ).length / 15
          }
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={goToPage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  )
}
