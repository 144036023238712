import { h } from 'preact'
import './PersonalInfos.scss'
import { User } from '../../types/user'
import Button from '../../components/Button'
import InputWithLabel from '../../components/InputWithLabel'
import { useState } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { DateTimePicker } from 'react-widgets'
import moment from 'moment'
import ChangePasswordForm from './ChangePasswordForm'

interface Props {
  user: User
  onChange: (u: User) => void
}

export default (props: Props) => {
  const [user, setUser] = useState(props.user)
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false)

  const changePassword = () => {
    setShowChangePasswordForm(true)
  }

  const updateMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.UPDATE_ME(user))

      props.onChange(res)
      store.notify(Message.Notification, 'Profil sauvegardé avec succès')
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder le profil')
      console.warn(e)
    }
  }

  return (
    <div class="PersonalInfos">
      {showChangePasswordForm && (
        <ChangePasswordForm onClose={() => setShowChangePasswordForm(false)} />
      )}
      <div class="PersonalInfos__title">Mes informations personnelles</div>
      <div class="PersonalInfos__form">
        <div class="PersonalInfos__formRow">
          <InputWithLabel
            label="Nom"
            value={user.lastName}
            onChange={(v) => setUser({ ...user, lastName: v })}
          />
          <InputWithLabel
            label="Prénom"
            value={user.firstName}
            onChange={(v) => setUser({ ...user, firstName: v })}
          />
        </div>
        <div class="PersonalInfos__formRow">
          <InputWithLabel
            label="Email"
            value={user.email}
            onChange={(v) => setUser({ ...user, firstName: v })}
            disabled
          />
          <InputWithLabel
            label="Mot de passe"
            type="password"
            value="xxxxxxxx"
            disabled
          >
            <div
              class="PersonalInfos__formPasswordChange"
              onClick={changePassword}
            >
              Changer mon mot de passe
            </div>
          </InputWithLabel>
        </div>
        <div class="PersonalInfos__formRow PersonalInfos__formRow--alignedBottom">
          <InputWithLabel
            label="Téléphone"
            value={user.phone}
            onChange={(v) => setUser({ ...user, phone: v })}
          />
          <DateTimePicker
            placeholder="17 juil. 2020"
            value={user.birthdate && moment(user.birthdate)?.local().toDate()}
            time={false}
            onChange={(v) =>
              setUser({
                ...user,
                birthdate: moment(v).utc().toISOString().replace('Z', ''),
              })
            }
          />
        </div>
        <InputWithLabel
          class="PersonalInfos__formAddress"
          label="Adresse"
          value={user.address}
          onChange={(v) => setUser({ ...user, address: v })}
        />
        <div class="PersonalInfos__formRow">
          <InputWithLabel
            label="Code postal"
            value={user.zipcode}
            onChange={(v) => setUser({ ...user, zipcode: v })}
          />
          <InputWithLabel
            label="Ville"
            value={user.city}
            onChange={(v) => setUser({ ...user, city: v })}
          />
        </div>
      </div>
      <div class="PersonalInfos__actions">
        <Button class="PersonalInfos__actionsSubmit" onClick={updateMe}>
          Mettre à jour mes informations
        </Button>
        <div class="PersonalInfos__actionsDeleteAccount">
          <a target="_blank" href="mailto:contact@gymspot.fr">
            Supprimer mon compte
          </a>
        </div>
      </div>
    </div>
  )
}
