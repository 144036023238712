import { h, Fragment } from 'preact'
import './Registrations.scss'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { CoachingClass } from '../../types/coaching_class'
import { Category } from '../../types/category'
import { Order } from '../../types/order'
import { CoachingClassSession } from '../../types/coaching_class_session'
import IconMail from '../../../assets/images/icons/icon_mail.svg'
import IconFilter from '../../../assets/images/icons/icon_filters.svg'
import IconReceipt from '../../../assets/images/icons/icon_file.svg'
import moment from 'moment'
import MessageForm from './MessageForm'
import { UUID } from '../../types/common'
import { Message as MessageType } from '../../types/messsage'
import FoldingRow from '../../components/FoldingRow'
import Coach from '../../types/coach'
import ReactPaginate from 'react-paginate'
import Loader from 'react-loader-spinner'
import Invoice from '../../Invoice'
import { User } from '../../types/user'

interface Props {
  coach: Coach
}

export default (props: Props) => {
  const [filter, setFilter] = useState('upcoming')
  const [selectedOrder, setSelectedOrder] = useState<Order>(undefined)
  const [selectedPayment, setSelectedPayment] = useState<{ amount: number }>(
    undefined
  )
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [sessionsOffset, setSessionsOffset] = useState(0)
  const [filteredSessions, setFilteredSessions] = useState<
    CoachingClassSession[]
  >([])
  const [coachSessions, setCoachSessions] = useState<CoachingClassSession[]>([])
  const [registrations, setRegistrations] = useState<Order[]>([])
  const [showMessageForm, setShowMessageForm] = useState(false)
  const [messages, setMessages] = useState<{ [k: string]: MessageType[] }>({})
  const [selectedSession, setSelectedSession] = useState<{
    coachingClass: CoachingClass
    session: CoachingClassSession
    order?: Order
    messages?: MessageType[]
    registrations?: Order[]
  }>(undefined)
  const [loading, setLoading] = useState(false)
  const [me, setMe] = useState<User>(undefined)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setCoachSessions(
      sessions.filter((s) => {
        const cc = coachingClasses.find((cc2) => {
          return cc2.id == s.coachingClassId
        })

        return cc?.coachId == props.coach.id
      })
    )
  }, [coachingClasses, sessions])

  useEffect(() => {
    setFilteredSessions(
      coachSessions
        .filter((s) =>
          filter == 'upcoming'
            ? moment(s.date).isAfter(new Date())
            : moment(s.date).isBefore(new Date())
        )
        .slice(sessionsOffset * 15, sessionsOffset * 15 + 15)
    )
  }, [coachSessions, sessionsOffset, filter])

  const fetchData = async () => {
    await fetchCategories()
    await fetchCoachingClasses()
    await fetchSessions()
    await fetchMe()
    await fetchRegistrations()
  }

  const fetchMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ME({}))

      setMe(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer le profil')
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours')
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_ALL_SESSIONS({})
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les sessions')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchRegistrations = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACH_REGISTRATIONS({}))

      res.forEach((o: Order) => {
        o.sessionIds.forEach((s) => fetchMessages(o.id, s))
      })

      setRegistrations(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les inscriptions')
      console.warn(e)
    }
  }

  const closeMessageModal = () => {
    setShowMessageForm(false)
  }

  const displayMessageModal = (
    order: Order,
    session: CoachingClassSession,
    coachingClass: CoachingClass
  ) => () => {
    setSelectedSession({
      order,
      session,
      coachingClass,
      messages: messages[`${session.id}|${order.id}`],
    })
    setShowMessageForm(true)
  }

  const displayGlobalMessageModal = (
    session: CoachingClassSession,
    coachingClass: CoachingClass
  ) => (e: MouseEvent) => {
    e.stopPropagation()
    setSelectedSession({
      session,
      coachingClass,
      registrations: registrations.filter((o) =>
        o.sessionIds.includes(session.id)
      ),
    })
    setShowMessageForm(true)
  }

  const fetchMessages = async (orderId: UUID, sessionId: UUID) => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_SESSION_MESSAGES({ orderId, sessionId })
      )

      res.sort((m1: MessageType, m2: MessageType) =>
        moment(m1.createdAt).isBefore(m2.createdAt) ? -1 : 1
      )
      setMessages((prev) => ({ ...prev, [`${sessionId}|${orderId}`]: res }))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les messages')
      console.warn(e)
    }
  }

  const goToPage = ({ selected }: { selected: number }) =>
    setSessionsOffset(selected)

  const openReceipt = (order: Order) => async () => {
    try {
      setLoading(true)
      const res = await httpClient.req(
        ROUTES.FETCH_RECEIPT_URL({ orderId: order.id })
      )

      setSelectedOrder(order)
      setSelectedPayment(res)
      setLoading(false)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger le reçu')
      console.warn(e)
    }
  }

  return (
    <div class="Registrations">
      {selectedOrder && selectedPayment && (
        <Invoice
          order={selectedOrder}
          payment={selectedPayment}
          recipient={{
            name: `${me.firstName} ${me.lastName}`,
            address: me.address,
            zipcode: me.zipcode,
            city: me.city,
          }}
          onClose={() => setSelectedOrder(undefined)}
          coachingClasses={coachingClasses}
          sessions={sessions}
          coaches={[props.coach]}
          coachInvoice
        />
      )}
      {showMessageForm && (
        <MessageForm
          onClose={closeMessageModal}
          session={selectedSession.session}
          coachingClass={selectedSession.coachingClass}
          order={selectedSession.order}
          messages={selectedSession.messages}
          registrations={selectedSession.registrations}
          coachForm
        />
      )}
      {loading && (
        <div class="Registrations__loader">
          <Loader type="ThreeDots" color="#4dba7f" height={100} width={100} />
        </div>
      )}
      <div class="Registrations__title">
        Mes inscrits
        <div class="MyBookings__titleFilter">
          <IconFilter />
          <select onChange={(e: any) => setFilter(e.target.value)}>
            <option value="upcoming" selected={filter == 'upcoming'}>
              À venir
            </option>
            <option value="past" selected={filter == 'past'}>
              Passés
            </option>
          </select>
        </div>
      </div>
      <div class="Registrations__table">
        <table class="Registrations__tableSessions">
          <thead>
            <tr>
              <th class="Registrations__tableCell--mobileVisible">Cours</th>
              <th class="Registrations__tableCell--mobileVisible">Date</th>
              <th>Horaire</th>
              <th>Participants</th>
              <th class="Registrations__tableCell--mobileVisible"></th>
            </tr>
          </thead>
          <tbody>
            {filteredSessions.map((s) => {
              let coachingClass = coachingClasses.find(
                (cc) => cc.id == s.coachingClassId
              )
              let date = moment(s.date)
              let category = categories.find(
                (c) => c.id == coachingClass.categoryId
              )

              return (
                <Fragment>
                  <FoldingRow
                    class="Registrations__tableSessionsEntry"
                    accordion={
                      <td
                        colSpan={5}
                        class="Registrations__tableCell--mobileVisible"
                      >
                        <table class="Registrations__tableSessionsRegistrations">
                          <thead>
                            <tr>
                              <th class="Registrations__tableCell--mobileVisible">
                                Nom
                              </th>
                              <th>N° Commande</th>
                              <th>Date</th>
                              <th>Horaire</th>
                              <th class="Registrations__tableCell--mobileVisible"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {registrations
                              .filter((r) => r.sessionIds.includes(s.id))
                              .map((o) => {
                                let date_reg = moment(s.date)

                                return (
                                  <tr>
                                    <td class="Registrations__tableCell--mobileVisible">
                                      {o.userName}
                                    </td>
                                    <td class="Registrations__tableId">
                                      {o.id}
                                    </td>
                                    <td class="Registrations__tableDate Registration__tableCell--centered">
                                      {date_reg.format('L')}
                                    </td>
                                    <td class="Registrations__tableTime Registration__tableCell--centered">
                                      {date_reg.format('HH[h]mm')} -{' '}
                                      {date_reg
                                        .add(coachingClass.duration, 'minutes')
                                        .format('HH[h]mm')}
                                    </td>
                                    <td class="Registrations__tableCell--mobileVisible Registration__tableCell--centered Registrations__tableActions">
                                      <div
                                        class="Registrations__tableAction"
                                        onClick={openReceipt(o)}
                                      >
                                        <IconReceipt />
                                      </div>
                                      <abbr title={`Message à ${o.userName}`}>
                                        <div
                                          class="Registrations__tableAction"
                                          onClick={displayMessageModal(
                                            o,
                                            s,
                                            coachingClass
                                          )}
                                        >
                                          <IconMail />
                                          {messages[`${s.id}|${o.id}`]?.length >
                                            0 && (
                                            <div class="Registrations__tableActionBadge">
                                              {
                                                messages[`${s.id}|${o.id}`]
                                                  ?.length
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </abbr>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </td>
                    }
                  >
                    <td class="Registrations__tableName Registrations__tableCell--mobileVisible">
                      <div class="Registrations__tableNameIcon">
                        <img
                          src={
                            category.iconUrl ||
                            categories.find((c) => c.id == category.categoryId)
                              ?.iconUrl
                          }
                        />
                      </div>
                      <div class="Registrations__tableNameLabel">
                        {coachingClass.name}
                        {s.canceled && (
                          <div class="Registrations__tableNameLabelCancelled">
                            Annulé
                          </div>
                        )}
                      </div>
                    </td>
                    <td class="Registrations__tableDate Registration__tableCell--centered Registrations__tableCell--mobileVisible">
                      {date.format('L')}
                    </td>
                    <td class="Registrations__tableTime Registration__tableCell--centered">
                      {date.format('HH[h]mm')} -{' '}
                      {date
                        .add(coachingClass.duration, 'minutes')
                        .format('HH[h]mm')}
                    </td>
                    <td class="Registrations__tableAttendees Registration__tableCell--centered">
                      {
                        registrations.filter((o) => o.sessionIds.includes(s.id))
                          .length
                      }
                    </td>
                    <td class="Registrations__tableCell--mobileVisible">
                      <abbr title="Message à tous les participants">
                        <div
                          class="Registrations__tableAction Registration__tableCell--centered"
                          onClick={displayGlobalMessageModal(s, coachingClass)}
                        >
                          <IconMail />
                        </div>
                      </abbr>
                    </td>
                  </FoldingRow>
                </Fragment>
              )
            })}
            {/* {registrations.map((o) =>
              o.sessionIds.map((s) => {
                let session = sessions.find((s2) => s2.id == s)
        

                return (
                  
                )
              })
            )} */}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={coachSessions.length / 15}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={goToPage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  )
}
