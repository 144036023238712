import { h, Fragment } from 'preact'
import Logo from '../../../assets/images/logo.svg'
import IconGeoloc from '../../../assets/images/icons/icon_location.svg'
import IconSport from '../../../assets/images/icons/icon_sport.svg'
import IconMyAccount from '../../../assets/images/icons/icon_my_account.svg'
import IconHamburgerMenu from '../../../assets/images/icons/icon_hamburger_menu.svg'
import './Header.scss'
import { RoutableProps, route } from 'preact-router'
import { useState, useEffect } from 'preact/hooks'
import { Category } from '../../types/category'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { UUID } from '../../types/common'
import InputWithLabel from '../../components/InputWithLabel'
import { StandaloneSearchBox } from '@react-google-maps/api'
import { callOnClickOutside } from '../../utils'

interface Props extends RoutableProps {
  loggedIn?: boolean
  withSearchBar?: boolean
  withBackground?: boolean
  scrolled?: boolean
  toggleShowAccountMenu: () => void
}

export default (props: Props) => {
  const [showCategories, setShowCategories] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState<UUID>(undefined)
  const [categoriesMenu, setCategoriesMenu] = useState<HTMLElement>(undefined)
  const [mapCenter, setMapCenter] =
    useState<{ lat: number; lng: number }>(undefined)
  const [searchBox, setSearchBox] = useState<any>(undefined)
  const [hamburgerMenuExpanded, setHamburgerMenuExpanded] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (categoriesMenu) {
      callOnClickOutside(() => {
        if (showCategories) {
          setShowCategories(false)
        }
      }, categoriesMenu)
    }
  })

  const fetchData = async () => {
    await fetchCategories()
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      res.sort((c1: Category, c2: Category) => (c1.name > c2.name ? 1 : -1))
      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const toggleCategories = (e: MouseEvent) => {
    e.stopPropagation()
    setShowCategories(!showCategories)
  }

  const goToCategory = (c: Category) => (e: MouseEvent) => {
    e.stopPropagation()
    route(`/coaching_classes/search?categoryId=${c.id}`)
    window.location.reload()
  }

  const goToPersonalCoaching = (e: MouseEvent) => {
    e.preventDefault()
    route(`/coaching_classes/search?classType=personal`)
    window.location.reload()
  }

  const gotToCoachingClasses = (e: MouseEvent) => {
    e.preventDefault()
    route(`/coaching_classes/search?classType=coaching_class`)
    window.location.reload()
  }

  const goToSearch = () => {
    let url = `/coaching_classes/search?`
    let args = []

    if (selectedCategory) {
      args.push(`categoryId=${selectedCategory}`)
    }
    if (mapCenter) {
      args.push(`mapCenter=${mapCenter.lat},${mapCenter.lng}`)
    }

    route(url + args.join('&'))
    window.location.reload()
  }

  const onSearchBoxLoad = (ref: any) => setSearchBox(ref)

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0]

    if (!place) {
      return
    }
    setMapCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
  }

  const subcategoriesFor = (id: UUID) => {
    return categories.filter((c) => c.categoryId == id)
  }

  const toggleHamburgerMenuExpanded = () => {
    setHamburgerMenuExpanded((prev) => !prev)
  }

  return (
    <div
      class={`Header ${props.withSearchBar ? 'Header--withSearchBar' : ''} ${
        props.withBackground ? 'Header--withBackground' : ''
      } ${props.scrolled ? 'Header--scrolled' : ''}`}
    >
      {/* BEGIN -- Hamburger menu -- mobile -- BEGIN*/}
      <div class="Header__hamburgerMenu">
        <IconHamburgerMenu onClick={toggleHamburgerMenuExpanded} />
        {hamburgerMenuExpanded && (
          <div class="Header__hamburgerMenuContentWrapper">
            <div class="Header__hamburgerMenuContent">
              <div class="Header__search Header__search--mobile">
                <div class="Header__searchInputs">
                  <div class="Header__searchLeft">
                    <IconGeoloc />
                    <StandaloneSearchBox
                      onLoad={onSearchBoxLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <InputWithLabel
                        placeholder="11 Avenue des Champs-Élysées, Paris"
                        disableAutocomplete
                      />
                    </StandaloneSearchBox>
                  </div>
                  <div class="Header__searchSeparator"></div>
                  <div class="Header__searchRight">
                    <IconSport />
                    <InputWithLabel
                      selectOptions={[
                        { value: undefined, label: 'Tous les sports' },
                        ...categories.map((c) => ({
                          label: c.name,
                          value: c.id,
                        })),
                      ]}
                      value={selectedCategory}
                      onChange={setSelectedCategory}
                    />
                  </div>
                  <div class="Header__searchButton" onClick={goToSearch}>
                    <i>search</i> Rechercher
                  </div>
                </div>
              </div>
              <div class="Header__entry Header__entry--mobile">
                <a onClick={goToPersonalCoaching}>Tous les coaches personnel</a>
              </div>
              <div class="Header__entry Header__entry--mobile">
                <a onClick={gotToCoachingClasses}>Tous les cours collectifs</a>
              </div>
              <div
                class="Header__entry Header__entry--mobile Header__categoriesButton"
                onClick={toggleCategories}
              >
                <span>Nos programmes</span>
                {showCategories && (
                  <div class="Header__categories Header__categories--mobile">
                    {categories
                      .filter((c) => !c.categoryId)
                      .map((c) => (
                        <div
                          class="Header__categoriesEntry"
                          onClick={goToCategory(c)}
                        >
                          <div class="Header__categoriesEntryIcon">
                            <img
                              src={
                                c.iconUrl ||
                                categories.find((c2) => c2.id == c.categoryId)
                                  ?.iconUrl
                              }
                            />
                          </div>
                          <div class="Header__categoriesEntryName">
                            {c.name}
                          </div>
                          {subcategoriesFor(c.id).length > 0 && (
                            <div class="Header__categoriesEntrySubcategories">
                              {subcategoriesFor(c.id).map((c) => (
                                <div
                                  class="Header__categoriesEntrySubcategoriesEntry"
                                  onClick={goToCategory(c)}
                                >
                                  {c.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div class="Header__entry Header__entry--mobile">
                <a href="https://corporate.gymspot.fr">Gymspot corporate</a>
              </div>
              {props.loggedIn || (
                <div class="Header__entry Header__entry--mobile Header__signin">
                  <a href="/signin" onClick={toggleHamburgerMenuExpanded}>
                    Se connecter
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* END -- Hamburger menu -- mobile -- END */}
      <div class="Header__left">
        <div
          class="Header__logo"
          onClick={() => {
            route('/')
            window.location.reload()
          }}
        >
          <Logo />
        </div>
        {props.withSearchBar && (
          <div class="Header__search">
            <div class="Header__searchInputs">
              <div class="Header__searchLeft">
                <IconGeoloc />
                <StandaloneSearchBox
                  onLoad={onSearchBoxLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <InputWithLabel
                    placeholder="11 Avenue des Champs-Élysées, Paris"
                    disableAutocomplete
                  />
                </StandaloneSearchBox>
              </div>
              <div class="Header__searchSeparator"></div>
              <div class="Header__searchRight">
                <IconSport />
                <InputWithLabel
                  selectOptions={[
                    { value: undefined, label: 'Tous les sports' },
                    ...categories.map((c) => ({
                      label: c.name,
                      value: c.id,
                    })),
                  ]}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                />
              </div>
            </div>
            <div class="Header__searchButton" onClick={goToSearch}>
              <i>search</i>
            </div>
          </div>
        )}
      </div>
      <div class="Header__right">
        <div class="Header__entry">
          <a onClick={goToPersonalCoaching}>Tous les coaches personnel</a>
        </div>
        <div class="Header__entry">
          <a onClick={gotToCoachingClasses}>Tous les cours collectifs</a>
        </div>
        <div class="Header__entry" onClick={toggleCategories}>
          <a>Nos programmes</a>
          {showCategories && (
            <div class="Header__categories" ref={setCategoriesMenu}>
              {categories
                .filter((c) => !c.categoryId)
                .map((c) => (
                  <div
                    class="Header__categoriesEntry"
                    onClick={goToCategory(c)}
                  >
                    <div class="Header__categoriesEntryIcon">
                      <img
                        src={
                          c.iconUrl ||
                          categories.find((c2) => c2.id == c.categoryId)
                            ?.iconUrl
                        }
                      />
                    </div>
                    <div class="Header__categoriesEntryName">{c.name}</div>
                    {subcategoriesFor(c.id).length > 0 && (
                      <div class="Header__categoriesEntrySubcategories">
                        {subcategoriesFor(c.id).map((c) => (
                          <div
                            class="Header__categoriesEntrySubcategoriesEntry"
                            onClick={goToCategory(c)}
                          >
                            {c.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div class="Header__entry">
          <a href="https://corporate.gymspot.fr" target="_blank">
            Gymspot corporate
          </a>
        </div>
        {props.loggedIn ? (
          <a href="/my_account">
            <div
              class="Header__myAccount"
              onClick={() => {
                setHamburgerMenuExpanded(false)
                props.toggleShowAccountMenu()
              }}
            >
              <IconMyAccount />
            </div>
          </a>
        ) : (
          <Fragment>
            <a href="/signin" class="Header__myAccount--mobileVisible">
              <div class="Header__myAccount">
                <IconMyAccount />
              </div>
            </a>
            <div class="Header__entry Header__signin">
              <a href="/signin">Se connecter</a>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}
