import { h } from 'preact'
import './MessageForm.scss'
import { CoachingClass } from '../../../types/coaching_class'
import Rater from 'react-rater'
import IconGeoloc from '../../../../assets/images/icons/icon_location.svg'
import IconClock from '../../../../assets/images/icons/icon_clock.svg'
import IconClose from '../../../../assets/images/icons/icon_remove.svg'
import { useState } from 'preact/hooks'
import { useEffect } from 'react'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import Coach from '../../../types/coach'
import { CoachingClassSession } from '../../../types/coaching_class_session'
import moment from 'moment'
import { Order } from '../../../types/order'
import { User } from '../../../types/user'
import Button from '../../../components/Button'
import { Message as MessageType } from '../../../types/messsage'

interface Props {
  registrations?: Order[]
  session: CoachingClassSession
  coachingClass: CoachingClass
  order?: Order
  coachForm?: boolean
  messages?: MessageType[]
  onClose: () => void
}

export default (props: Props) => {
  const [coach, setCoach] = useState<Coach>(undefined)
  const [user, setUser] = useState<User>(undefined)
  const [message, setMessage] = useState<string>(undefined)

  useEffect(() => {
    fetchData()

    let elem = document.querySelector('.MessageForm') as HTMLElement

    elem.style.height = window.innerHeight.toString()
    elem.style.width = window.innerWidth.toString()
  }, [])

  const fetchData = async () => {
    if (props.coachForm && props.order) {
      await fetchUser()
    }
    await fetchCoach()
  }

  const fetchCoach = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH({
          id: props.coachingClass.coachId,
        })
      )

      setCoach(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger le coach')
      console.warn(e)
    }
  }

  const fetchUser = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_USER({
          id: props.order.userId,
        })
      )

      setUser(res)
    } catch (e) {
      store.notify(Message.Error, "Impossible de charger l'utilisateur")
      console.warn(e)
    }
  }

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const onValidate = async () => {
    try {
      if (props.order) {
        await httpClient.req(
          ROUTES.SEND_MESSAGE({
            orderId: props.order.id,
            sessionId: props.session.id,
            content: message,
            userId: !props.coachForm ? props.order.userId : undefined,
            coachId: props.coachForm ? props.coachingClass.coachId : undefined,
          })
        )

        store.notify(Message.Notification, 'Message envoyé')
      } else if (props.registrations) {
        for (const registration of props.registrations) {
          await httpClient.req(
            ROUTES.SEND_MESSAGE({
              orderId: registration.id,
              sessionId: props.session.id,
              content: message,
              userId: !props.coachForm ? registration.userId : undefined,
              coachId: props.coachForm
                ? props.coachingClass.coachId
                : undefined,
            })
          )
        }
        store.notify(Message.Notification, 'Messages envoyés')
      } else {
        throw 'No recipient'
      }
      props.onClose()
    } catch (e) {
      store.notify(Message.Error, "Impossible d'envoyer le message")
      console.warn(e)
    }
  }

  return (
    <div class="MessageForm" onClick={props.onClose}>
      {coach && (
        <div class="MessageForm__content" onClick={stopPropagation}>
          <div class="MessageForm__close" onClick={props.onClose}>
            <IconClose />
          </div>
          <div class="MessageForm__contentHeader">
            <div class="MessageForm__contentHeaderCoach">
              <img src={coach.pictureUrl} />
            </div>
            <div class="MessageForm__contentHeaderDesc">
              <div class="MessageForm__contentHeaderDescCoach">
                {coach.firstName} {coach.lastName}
              </div>
              <div class="MessageForm__contentHeaderDescTitle">
                {props.coachingClass.name}
              </div>
              {coach.reviewsCount > 0 && (
                <div class="MessageForm__contentHeaderDescRating">
                  <div class="MessageForm__contentHeaderDescRatingValue">
                    {coach.averageGrade / 10}
                  </div>
                  <Rater rating={coach.averageGrade / 10} interactive={false} />
                </div>
              )}
              <div class="MessageForm__contentHeaderDescLocation">
                <IconGeoloc />
                {props.coachingClass.meetingLocation}
              </div>
              <div class="MessageForm__contentHeaderDescDate">
                <IconClock />
                {moment(props.session.date).format('LLLL')}
              </div>
            </div>
            <div class="MessageForm__contentHeaderPrice">
              <div class="MessageForm__contentHeaderPriceContent">
                <span>{props.coachingClass.price}€</span>
              </div>
            </div>
          </div>
          <div class="MessageForm__contentBody">
            {props.messages && (
              <div class="MessageForm__contentBodyMessages">
                {props.messages.map((m) => (
                  <div class="MessageForm__contentBodyMessagesEntry">
                    <div class="MessageForm__contentBodyMessagesEntryFrom">
                      {props.coachForm
                        ? m.coachId
                          ? 'Moi'
                          : `${user.firstName} ${user.lastName}`
                        : m.coachId
                        ? `${coach.firstName} ${coach.lastName}`
                        : 'Moi'}
                    </div>
                    <div class="MessageForm__contentBodyMessagesEntryContent">
                      {m.content}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div class="MessageForm__contentBodyTitle">
              {props.coachForm
                ? `Votre message à ${
                    props.order
                      ? `${user.firstName} ${user.lastName}`
                      : 'tous les participants'
                  }`
                : 'Votre message au coach'}
            </div>
            <div class="MessageForm__contentBodyMessage">
              <textarea onChange={(e: any) => setMessage(e.target.value)} />
            </div>
            <div class="MessageForm__contentBodySubmit">
              <Button
                onClick={onValidate}
                disabled={!message || message.length == 0}
              >
                Envoyer
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
