import { h } from 'preact'
import { RoutableProps } from 'preact-router'
import './LegalMentions.scss'

export default (props: RoutableProps) => {
  return (
    <div class="LegalNotice">
      <p>
        Conformément aux dispositions de l'article 6 III-1 de la loi n° 2004-575
        du 21 juin 2004 pour la confiance dans l'économie numérique, le présent
        site est exploité par la société CENTES, société par actions simplifiée
        au capital social de 1.000 euros, ayant son siège social 1 Place Paul
        Verlaine 92100 BOULOGNE BILLANCOURT, immatriculée au RCS de NANTERRE
        sous le n°882 848 310
      </p>
      <div>
        <h2>Marque et logo</h2>
        <p>Gymspot est une marque déposée et protégée.</p>
      </div>
      <div>
        <h2>Hébergement</h2>
        <p>Google Cloud Plateform</p>
      </div>
      <div>
        <h2>Responsable de publication</h2>
        <p>
          Directeur de publication : Michael Centissimo Si vous remarquez une
          erreur ou un lien erroné sur ce site merci de nous contacter.
        </p>
      </div>
      <div>
        <h2>Droits d’auteur</h2>
        <p>
          L’ensemble de ce site relève de la législation française sur le droit
          d’auteur et la propriété intellectuelle. En application de la loi du
          11 mars 1957 (art. 41) et du code de la propriété intellectuelle du
          1er juillet 1992, toute représentation, reproduction, modification,
          utilisation commerciale, partielle ou totale ainsi que tout transfert
          vers un autre site sont interdits, sauf autorisation de la société
          Digital In Progress. Les logos, visuels et pdf sur ce site sont la
          propriété de la société CENTES SAS. Toute reproduction intégrale ou
          partielle de ce site ou de l’un des éléments qui le compose est
          interdite. La société CENTES SAS se réserve le droit de demander des
          dommages et intérêts en cas de contrefaçon et plus généralement
          d’atteinte à ses droits de propriété intellectuelle.
        </p>
      </div>
      <div>
        <h2>Gestion des liens </h2>
        <p>
          Le site contient des liens vers d’autres sites Web ou d’autres sources
          Internet. Dans la mesure où la société CENTES SAS ne peut contrôler
          ces sites et ces sources externes, la société ne peut être tenue pour
          responsable de la mise à disposition de ces sites et sources externes,
          et ne peut supporter aucune responsabilité quant au contenu,
          publicités, produits, services ou tout autre matériel disponible sur
          ou à partir de ces sites ou sources externes. L’internaute reconnaît
          que l’utilisation du site internet de la société CENTES SAS est régie
          par le droit français. (réglementées par la Loi n° 2004-575 du 21 juin
          2004 pour la confiance dans l’économie numérique).
        </p>
      </div>
    </div>
  )
}
