import { h } from 'preact'
import { RoutableProps, route } from 'preact-router'
import Woman from '../../assets/images/woman_signup.png'
import Input from '../components/Input'
import RadioButton from '../components/RadioButton'
import { useState } from 'preact/hooks'
import Button from '../components/Button'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import './Signup.scss'
import store, { Stored, Message } from '../services/store'
import ReactTooltip from 'react-tooltip'

export default (_: RoutableProps) => {
  const [optinNewsletter, setOptinNewsletter] = useState(false)
  const [optinPartners, setOptinPartners] = useState(false)
  const [optinCGU, setOptinCGU] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const submitForm = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.SIGNUP({
          firstName,
          lastName,
          email,
          password,
          optinNewsletter,
          optinPartners,
        })
      )
      const [rawJwt, jwt, token] = httpClient.storeCreds(res)
      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.update(Stored.RefreshToken, token)
      store.notify(Message.Notification, 'Vous êtes inscrit avec succès')
      route('/')
    } catch (e) {
      store.notify(
        Message.Error,
        e == 'EMAIL_ALREADY_TAKEN'
          ? 'Un compte existe déjà avec cet email'
          : "Une erreur est survenue lors de l'inscription"
      )
      console.warn(e)
    }
  }

  const canSignup = () => {
    return (
      optinCGU &&
      firstName &&
      lastName &&
      password &&
      password.length >= 8 &&
      email
    )
  }

  const validate = () => {
    let errors = []

    if (!optinCGU) {
      errors.push('Vous devez accepter les CGV avant de pouvoir continuer')
    }
    if (!password || password.length < 8) {
      errors.push('Votre mot de passe doit faire au moins 8 caractères')
    }
    return errors
  }

  return (
    <div class="Signup">
      <div class="Signup__content">
        <div class="Signup__title">
          <div class="Signup__titleTop">INSCRIVEZ</div>
          <div class="Signup__titleBottom">VOUS</div>
        </div>
        <img class="Signup__womanPicture" src={Woman} />
        <div class="Signup__form">
          <div class="Signup__formTop">
            <Input
              placeholder="NOM *"
              onChange={setLastName}
              value={lastName}
            />
            <Input
              placeholder="PRÉNOM *"
              onChange={setFirstName}
              value={firstName}
            />
          </div>
          <div class="Signup__formMiddle">
            <Input
              placeholder="EMAIL *"
              onChange={setEmail}
              type="email"
              value={email}
            />
            <Input
              placeholder="MOT DE PASSE *"
              type="password"
              onChange={setPassword}
              value={password}
            />
          </div>
          <div class="Signup__formBottom">
            <div class="Signup__formBottomLeft">
              <RadioButton
                checked={optinNewsletter}
                onChange={setOptinNewsletter}
              >
                S'inscrire à la newsletter Gymspot
              </RadioButton>
              <RadioButton checked={optinPartners} onChange={setOptinPartners}>
                S'inscrire aux offres partenaires Gymspot
              </RadioButton>
              <RadioButton checked={optinCGU} onChange={setOptinCGU}>
                J'accepte les{' '}
                <a href="/tos" target="_blank">
                  CGV
                </a>{' '}
                *
              </RadioButton>
            </div>
            <div class="Signup__formBottomRight">* Champ obligatoire</div>
          </div>
          <ReactTooltip place="top" type="error" effect="solid" html />
          <Button
            shadow
            onClick={submitForm}
            disabled={!canSignup()}
            dataTip={validate().join('<br/>')}
          >
            S'inscrire
          </Button>
        </div>
        <div class="Signup__loginLink">
          Vous avez déjà un compte ? <a href="/signin">Connectez-vous</a>
        </div>
      </div>
    </div>
  )
}
