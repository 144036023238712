import { h } from 'preact'
import './InputWithLabel.scss'

interface Props {
  type?: string
  value?: string
  onChange?: (v: string) => void
  label?: string
  class?: string
  children?: JSX.Element | string | (string | JSX.Element)[]
  disabled?: boolean
  textarea?: boolean
  selectOptions?: { label: string; value: string; disabled?: boolean }[]
  placeholder?: string
  disableAutocomplete?: boolean
  maxlength?: number
  min?: number
  max?: number
}

export default (props: Props) => {
  const onChange = (e: any) => props.onChange && props.onChange(e.target.value)

  return (
    <div
      class={`InputWithLabel ${props.class ? props.class : ''} ${
        props.disabled ? 'InputWithLabel--disabled' : ''
      }`}
    >
      {props.label && <div class="InputWithLabel__label">{props.label}</div>}
      <div class="InputWithLabel__inputWrapper">
        <div
          class={`InputWithLabel__input ${
            props.selectOptions ? 'InputWithLabel__input--nopadding' : ''
          }`}
        >
          {props.selectOptions ? (
            <select onChange={onChange}>
              {props.selectOptions.map((o) => (
                <option
                  value={o.value}
                  selected={props.value == o.value}
                  disabled={o.disabled}
                >
                  {o.label}
                </option>
              ))}
            </select>
          ) : props.textarea ? (
            <textarea
              maxLength={props.maxlength}
              disabled={props.disabled}
              type={props.type || 'text'}
              onInput={onChange}
              value={props.value}
            />
          ) : (
            <input
              maxLength={props.maxlength}
              disabled={props.disabled}
              type={props.type || 'text'}
              onInput={onChange}
              value={props.value}
              placeholder={props.placeholder}
              autoComplete={props.disableAutocomplete ? 'new-password' : 'on'}
              min={props.min}
              max={props.max}
            />
          )}
        </div>
        {props.children}
      </div>
    </div>
  )
}
