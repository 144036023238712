import { h, Fragment } from 'preact'
import './CoachingClassDetails.scss'
import { RoutableProps, route } from 'preact-router'
import { useState, useEffect } from 'preact/hooks'
import { CoachingClass } from '../../types/coaching_class'
import { UUID } from '../../types/common'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import IconGeoloc from '../../../assets/images/icons/icon_location.svg'
import IconPerson from '../../../assets/images/icons/icon_person.svg'
import IconDuration from '../../../assets/images/icons/icon_duration.svg'
import Rater from 'react-rater'
import store, { Message } from '../../services/store'
import Carousel from '@brainhubeu/react-carousel'
import { CoachingClassSession } from '../../types/coaching_class_session'
import ClockIcon from '../../../assets/images/icons/icon_clock.svg'
import IconPlaceholder from '../../../assets/images/icons/icon_person.svg'
import moment from 'moment'
import { unique } from '../../utils'
import { Category } from '../../types/category'
import IconFacebook from '../../../assets/images/icons/icon_facebook_green.svg'
import IconMail from '../../../assets/images/icons/icon_mail_green.svg'
import CoachingClassRegistration from '../../CoachingClassRegistration'
import { Review } from '../../types/review'
import { User } from '../../types/user'
import ReactPaginate from 'react-paginate'
import MetaTags from 'react-meta-tags'

interface Props extends RoutableProps {
  id?: UUID
}

export default (props: Props) => {
  const [coachingClass, setCoachingClass] = useState<CoachingClass>(undefined)
  const [sessions, setSessions] = useState<CoachingClassSession[]>(undefined)
  const [showAllSessions, setShowAllSessions] = useState(false)
  const [coachCategories, setCoachCategories] = useState<Category[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [coachCoachingClasses, setCoachCoachingClasses] = useState<
    CoachingClass[]
  >([])
  const [showRegistration, setShowRegistration] = useState(false)
  const [reviews, setReviews] = useState<Review[]>([])
  const [filteredReviews, setFilteredReviews] = useState<Review[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [reviewsOffset, setReviewsOffset] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    let catIds = coachCoachingClasses.map((cc) => cc.categoryId).filter(unique)

    setCoachCategories(categories.filter((c) => catIds.includes(c.id)))
  }, [categories, coachCoachingClasses])

  useEffect(() => {
    coachingClass && fetchCoachCoachingClasses()
  }, [coachingClass])

  useEffect(() => {
    setFilteredReviews(reviews.slice(reviewsOffset * 4, reviewsOffset * 4 + 4))
  }, [reviews, reviewsOffset])

  useEffect(() => {
    filteredReviews.forEach((r) => fetchUser(r.userId))
  }, [filteredReviews])

  useEffect(() => {
    if (coachingClass) {
      fetchReviews()
    }
  }, [coachingClass])

  useEffect(() => {
    if (coachingClass && !coachingClass.coach.validated) {
      route('/')
    }
  }, [coachingClass])

  const fetchData = async () => {
    await fetchSessions()
    await fetchCoachingClass()
    await fetchCategories()
  }

  const fetchSessions = async () => {
    try {
      const res: CoachingClassSession[] = await httpClient.req(
        ROUTES.FETCH_COACHING_CLASS_SESSIONS({ id: props.id })
      )

      res.sort((s1, s2) => (s1.date < s2.date ? -1 : 1))
      setSessions(
        res.filter((s) => s.date > Date.now()).filter((s) => !s.canceled)
      )
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les sessions')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchCoachCoachingClasses = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH_COACHING_CLASSES({
          id: coachingClass.coachId,
        })
      )

      setCoachCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours du coach')
      console.warn(e)
    }
  }

  const fetchCoachingClass = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACHING_CLASS({
          id: props.id,
        })
      )

      setCoachingClass(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger le cours')
      console.warn(e)
    }
  }

  const fetchReviews = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_COACH_REVIEWS({
          coachId: coachingClass.coachId,
        })
      )

      setReviews(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les avis')
      console.warn(e)
    }
  }

  const fetchUser = async (id: UUID) => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USER({ id }))

      setUsers((prev) => [...prev, res])
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les avis')
      console.warn(e)
    }
  }

  const toggleShowAllSessions = () => {
    setShowAllSessions(!showAllSessions)
  }

  const displayRegistration = () => {
    store.store.JWT ? setShowRegistration(true) : route('/signin')
  }

  const hideRegistration = () => {
    setShowRegistration(false)
  }

  const averageGrade = () => {
    if (reviews.length == 0) {
      return undefined
    }
    return reviews.map((r) => r.rating).reduce((t, e) => t + e) / reviews.length
  }

  const goToPage = ({ selected }: { selected: number }) =>
    setReviewsOffset(selected)

  return (
    coachingClass &&
    coachingClass.coach.validated && (
      <div class="CoachingClassDetails">
        <MetaTags>
          <title>{`Gymspot : Cours de sport collectif : ${coachingClass.name} avec ${coachingClass.coach.firstName} ${coachingClass.coach.lastName}`}</title>
          <meta
            name="description"
            content={`Votre cours de sport collectif avec ${
              coachingClass.coach.firstName
            } ${coachingClass.coach.lastName} : ${
              coachingClass.meetingLocation
            } / ${
              categories.find((c) => c.id == coachingClass.categoryId)?.name
            }. Découvrez toutes les dates disponibles`}
          />
          <meta
            property="og:title"
            content={`Gymspot - ${coachingClass.name}`}
          />
          <meta
            property="og:image"
            content={coachingClass.pictureUrl || undefined}
          />
        </MetaTags>
        {showRegistration && (
          <CoachingClassRegistration
            coachingClass={coachingClass}
            sessions={sessions}
            onClose={hideRegistration}
          />
        )}
        <div class="CoachingClassDetails__header">
          <div class="CoachingClassDetails__headerImages">
            <div class="CoachingClassDetails__headerImagesClass">
              <img src={coachingClass.pictureUrl} />
            </div>
            <a href={`/coaches/${coachingClass.coachId}`}>
              <div class="CoachingClassDetails__headerImagesCoach">
                <img
                  src={coachingClass.coach && coachingClass.coach.pictureUrl}
                />
              </div>
            </a>
            <div class="CoachingClassDetails__headerImagesCoach CoachingClassDetails__headerImagesCoach--mobileVisible">
              <img src={coachingClass.pictureUrl} />
            </div>
          </div>
          <div class="CoachingClassDetails__headerDesc">
            <a href={`/coaches/${coachingClass.coachId}`}>
              <div class="CoachingClassDetails__headerDescCoach">
                {coachingClass.coach &&
                  `${coachingClass.coach.firstName} ${coachingClass.coach.lastName}`}
              </div>
            </a>
            <h1 class="CoachingClassDetails__headerDescTitle">
              {coachingClass.name}
            </h1>
            <div class="CoachingClassDetails__headerDescRating">
              {averageGrade() && (
                <Fragment>
                  <div class="CoachingClassDetails__headerDescRatingValue">
                    {(coachingClass.coach.averageGrade / 10).toFixed(1)}
                  </div>
                  <Rater rating={averageGrade()} interactive={false} />
                  <div class="CoachingClassDetails__headerDescRatingFeedbacks">
                    <a href="#ratings">{reviews.length} avis</a>
                  </div>
                </Fragment>
              )}
              <div class="CoachingClassDetails__headerDescRatingNetworks">
                <div>
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      location.href
                    )}`}
                  >
                    <IconFacebook />
                  </a>
                  <a target="_blank" href={`mailto:?body=${location.href}`}>
                    <IconMail />
                  </a>
                </div>
                <div class="CoachingClassDetails__headerDescRatingNetworksTitle">
                  Partagez votre cours
                </div>
              </div>
            </div>
            <div class="CoachingClassDetails__headerDescLocation">
              <IconGeoloc />
              {coachingClass.meetingLocation}
            </div>
            <div
              class="CoachingClassDetails__headerDescBook"
              onClick={displayRegistration}
            >
              Réserver
            </div>
          </div>
          <div class="CoachingClassDetails__headerPrice">
            <div class="CoachingClassDetails__headerPriceContent">
              <span>{coachingClass.price}€</span>
            </div>
          </div>
        </div>
        <div class="CoachingClassDetails__content">
          <div class="CoachingClassDetails__contentMain">
            <div class="CoachingClassDetails__contentMainLeft">
              <div class="CoachingClassDetails__contentMainLeftFirstLine">
                <div class="CoachingClassDetails__infoCard">
                  <IconDuration />
                  <div class="CoachingClassDetails__infoCardText">
                    <span>Durée : </span>
                    {coachingClass.duration}m{' '}
                  </div>
                </div>
                <div class="CoachingClassDetails__infoCard">
                  <IconPerson />
                  <div class="CoachingClassDetails__infoCardText">
                    <span>Nbr de participants : </span>
                    {coachingClass.maxAttendees} PERSONNES MAX.
                  </div>
                </div>
              </div>
              <div class="CoachingClassDetails__contentMainLeftDesc">
                <h2 class="CoachingClassDetails__contentMainLeftDescTitle">
                  DESCRIPTION DU COURS
                </h2>
                <div class="CoachingClassDetails__contentMainLeftDescContent">
                  {coachingClass.description}
                </div>
              </div>
              {coachingClass.medias.length > 0 && (
                <div class="CoachingClassDetails__contentMainLeftMedias">
                  <Carousel
                    centered
                    infinite
                    addArrowClickHandler
                    dots
                    autoPlay={5000}
                    stopAutoPlayOnHover
                  >
                    {coachingClass.medias.map((m) => (
                      <div class="CoachingClassDetails__contentMainLeftMediasPhoto">
                        {m.type == 'image' && <img src={m.url} />}
                        {m.type == 'video' && <video src={m.url} controls />}
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </div>
            <div class="CoachingClassDetails__contentMainRight">
              <div class="CoachingClassDetails__contentMainRightHours">
                <h2 class="CoachingClassDetails__contentMainRightHoursTitle">
                  HORAIRES DES COURS
                </h2>
                <div class="CoachingClassDetails__contentMainRightHoursContent">
                  {sessions &&
                    (showAllSessions ? sessions : sessions.slice(0, 3)).map(
                      (s) => (
                        <div class="CoachingClassDetails__contentMainRightHoursContentEntry">
                          <ClockIcon />
                          {moment(s.date).format('dddd Do MMMM')}
                          <span>
                            {moment(s.date).format('HH[H]mm')}-
                            {moment(s.date)
                              .add(
                                moment.duration(
                                  coachingClass.duration,
                                  'minutes'
                                )
                              )
                              .format('HH[H]mm')}
                          </span>
                        </div>
                      )
                    )}
                </div>
                {sessions.length > 3 && (
                  <div
                    class="CoachingClassDetails__contentMainRightHoursSeeMore"
                    onClick={toggleShowAllSessions}
                  >
                    {showAllSessions
                      ? '- Voir moins de cours'
                      : '+ Voir plus de cours'}
                  </div>
                )}
              </div>
              <div class="CoachingClassDetails__contentMainRightOtherClasses">
                <h2 class="CoachingClassDetails__contentMainRightOtherClassesTitle">
                  COURS PROPOSÉS PAR {coachingClass.coach.firstName}{' '}
                  {coachingClass.coach.lastName}
                </h2>
                <div class="CoachingClassDetails__contentMainRightOtherClassesContent">
                  {coachCategories.map((c) => (
                    <a href={`/coaching_classes/search?categoryId=${c.id}`}>
                      <div class="CoachingClassDetails__contentMainRightOtherClassesContentEntry">
                        <img
                          src={
                            c.iconUrl ||
                            categories.find((c) => c.id == c.categoryId)
                              ?.iconUrl
                          }
                        />
                        {c.name}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <div class="CoachingClassDetails__contentMainRightLevels">
                <h2 class="CoachingClassDetails__contentMainRightPriceTitle">
                  Niveau
                </h2>
                <div class="CoachingClassDetails__contentMainRightLevelsEntries">
                  <div class="CoachingClassDetails__contentMainRightLevelsEntry">
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryLabel">
                      Force
                    </div>
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryValue">
                      <div
                        class="CoachingClassDetails__contentMainRightLevelsEntryValueFill"
                        style={{ width: `${coachingClass.levels.strength}%` }}
                      ></div>
                    </div>
                  </div>
                  <div class="CoachingClassDetails__contentMainRightLevelsEntry">
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryLabel">
                      Endurance
                    </div>
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryValue">
                      <div
                        class="CoachingClassDetails__contentMainRightLevelsEntryValueFill"
                        style={{ width: `${coachingClass.levels.stamina}%` }}
                      ></div>
                    </div>
                  </div>
                  <div class="CoachingClassDetails__contentMainRightLevelsEntry">
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryLabel">
                      Souplesse
                    </div>
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryValue">
                      <div
                        class="CoachingClassDetails__contentMainRightLevelsEntryValueFill"
                        style={{
                          width: `${coachingClass.levels.flexibility}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div class="CoachingClassDetails__contentMainRightLevelsEntry">
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryLabel">
                      Mobilité
                    </div>
                    <div class="CoachingClassDetails__contentMainRightLevelsEntryValue">
                      <div
                        class="CoachingClassDetails__contentMainRightLevelsEntryValueFill"
                        style={{ width: `${coachingClass.levels.mobility}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {coachingClass.medias.length > 0 && (
            <div class="CoachingClassDetails__contentMainLeftMedias--mobile">
              <Carousel
                centered
                infinite
                addArrowClickHandler
                dots
                autoPlay={5000}
                stopAutoPlayOnHover
              >
                {coachingClass.medias.map((m) => (
                  <div class="CoachingClassDetails__contentMainLeftMediasPhoto">
                    {m.type == 'image' && <img src={m.url} />}
                    {m.type == 'video' && <video src={m.url} controls />}
                  </div>
                ))}
              </Carousel>
            </div>
          )}
          {reviews.length > 0 && (
            <div class="CoachingClassDetails__contentReviews" id="ratings">
              <div class="CoachingClassDetails__contentReviewsContent">
                <div class="CoachingClassDetails__contentReviewsTitle">
                  Avis sur {coachingClass.coach.firstName}{' '}
                  {coachingClass.coach.lastName}
                </div>
                <div class="CoachingClassDetails__contentReviewsSumup">
                  <div class="CoachingClassDetails__contentReviewsSumupAverage">
                    <span>
                      {(coachingClass.coach.averageGrade / 10).toFixed(1)}
                    </span>
                    <Rater rating={averageGrade()} interactive={false} />
                  </div>
                  <div class="CoachingClassDetails__contentReviewsSumupCount">
                    {reviews.length} avis
                  </div>
                </div>
                <div class="CoachingClassDetails__contentReviewsEntries">
                  {filteredReviews.map((r) => {
                    let u = users.find((u) => u.id == r.userId)

                    return (
                      u && (
                        <div class="CoachingClassDetails__contentReviewsEntry">
                          <div class="CoachingClassDetails__contentReviewsEntryLeft">
                            <div class="CoachingClassDetails__contentReviewsEntryLeftPhoto">
                              {u.pictureUrl && u.pictureUrl.length > 0 ? (
                                <img src={u.pictureUrl} />
                              ) : (
                                <IconPlaceholder />
                              )}
                            </div>
                            <div class="CoachingClassDetails__contentReviewsEntryLeftName">
                              {u.firstName} {u.lastName}
                            </div>
                            <div class="CoachingClassDetails__contentReviewsEntryLeftRating">
                              <Rater rating={r.rating} interactive={false} />
                            </div>
                          </div>
                          <div class="CoachingClassDetails__contentReviewsEntryRight">
                            {r.comment || ''}
                          </div>
                        </div>
                      )
                    )
                  })}
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={reviews.length / 4}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={goToPage}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}
