import { h, Fragment } from 'preact'
import './MyBookings.scss'
import Button from '../../components/Button'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { Order } from '../../types/order'
import { CoachingClass } from '../../types/coaching_class'
import { CoachingClassSession } from '../../types/coaching_class_session'
import { Category } from '../../types/category'
import moment from 'moment'
import Coach from '../../types/coach'
import IconLocation from '../../../assets/images/icons/icon_location.svg'
import IconPeople from '../../../assets/images/icons/icon_people.svg'
import IconClock from '../../../assets/images/icons/icon_clock.svg'
import IconClose from '../../../assets/images/icons/icon_remove.svg'
import IconPreview from '../../../assets/images/icons/icon_preview.svg'
import IconMail from '../../../assets/images/icons/icon_mail.svg'
import IconStar from '../../../assets/images/icons/icon_star.svg'
import IconFilter from '../../../assets/images/icons/icon_filters.svg'
import IconFile from '../../../assets/images/icons/icon_file.svg'
import { UUID } from '../../types/common'
import { Message as MessageType } from '../../types/messsage'
import MessageForm from '../Registrations/MessageForm'
import CoachRating from './CoachRating'
import ReactPaginate from 'react-paginate'
import Invoice from '../../Invoice'
import { User } from '../../types/user'

interface Props {
  selected?: string
}

export default (props: Props) => {
  const [filter, setFilter] = useState(props.selected || 'upcoming')
  const [selectedOrder, setSelectedOrder] = useState<Order>(undefined)
  const [orders, setOrders] = useState<Order[]>([])
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([])
  const [ordersOffset, setOrdersOffset] = useState(0)
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [messages, setMessages] = useState<{ [k: string]: MessageType[] }>({})
  const [showMessageForm, setShowMessageForm] = useState(false)
  const [showRatingForm, setShowRatingForm] = useState(false)
  const [selectedCoach, setSelectedCoach] = useState<Coach>(undefined)
  const [selectedSession, setSelectedSession] = useState<{
    coachingClass: CoachingClass
    session: CoachingClassSession
    order: Order
    messages: MessageType[]
  }>(undefined)
  const [me, setMe] = useState<User>(undefined)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredOrders(orders.slice(ordersOffset * 15, ordersOffset * 15 + 15))
  }, [orders, ordersOffset])

  const fetchData = async () => {
    await fetchCoachingClasses()
    await fetchSessions()
    await fetchCategories()
    await fetchCoaches()
    await fetchMe()
    await fetchOrders()
  }

  const fetchMe = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ME({}))

      setMe(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de récupérer le profil')
      console.warn(e)
    }
  }

  const fetchOrders = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_MY_ORDERS({}))

      res.sort((o1: Order, o2: Order) =>
        moment(o1.createdAt).isBefore(o2.createdAt) ? -1 : 1
      )
      res.forEach((o: Order) => {
        o.sessionIds.forEach((s) => fetchMessages(o.id, s))
      })
      setOrders(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les réservations')
      console.warn(e)
    }
  }

  const fetchMessages = async (orderId: UUID, sessionId: UUID) => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_SESSION_MESSAGES({ orderId, sessionId })
      )

      res.sort((m1: MessageType, m2: MessageType) =>
        moment(m1.createdAt).isBefore(m2.createdAt) ? -1 : 1
      )
      setMessages((prev) => ({ ...prev, [`${sessionId}|${orderId}`]: res }))
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les messages')
      console.warn(e)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les catégories')
      console.warn(e)
    }
  }

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      setCoaches(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les coaches')
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours')
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ALL_SESSIONS({}))

      setSessions(res)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de charger les cours')
      console.warn(e)
    }
  }

  const closeRatingModal = () => {
    setShowRatingForm(false)
    setSelectedCoach(undefined)
  }

  const closeMessageModal = () => {
    setShowMessageForm(false)
    setSelectedSession(undefined)
  }

  const displayMessageModal = (
    order: Order,
    session: CoachingClassSession,
    coachingClass: CoachingClass
  ) => () => {
    setSelectedSession({
      order,
      session,
      coachingClass,
      messages: messages[`${session.id}|${order.id}`] || [],
    })
    setShowMessageForm(true)
  }

  const displayRatingModal = (coach: Coach) => () => {
    setSelectedCoach(coach)
    setShowRatingForm(true)
  }

  const goToPage = ({ selected }: { selected: number }) =>
    setOrdersOffset(selected)

  const cancelSession = (
    order: Order,
    session: CoachingClassSession
  ) => async () => {
    if (
      confirm(
        "Êtes-vous sûr de vouloir annuler ce cours ? Cette action est irréversible et des frais d'annulation seront retenus."
      )
    ) {
      try {
        await httpClient.req(
          ROUTES.CANCEL_ORDER_SESSION({
            orderId: order.id,
            sessionId: session.id,
          })
        )

        window.location.reload()
      } catch (e) {
        store.notify(
          Message.Error,
          "Impossible de procéder à l'annulation. Merci de nous contact à contact@gymspot.fr"
        )
        console.warn(e)
      }
    }
  }

  return (
    <div class="MyBookings">
      {selectedOrder && (
        <Invoice
          order={selectedOrder}
          recipient={{
            name: `${me.firstName} ${me.lastName}`,
            address: me.address,
            zipcode: me.zipcode,
            city: me.city,
          }}
          onClose={() => setSelectedOrder(undefined)}
          coachingClasses={coachingClasses}
          sessions={sessions}
          coaches={coaches}
        />
      )}
      {showMessageForm && (
        <MessageForm
          onClose={closeMessageModal}
          session={selectedSession.session}
          coachingClass={selectedSession.coachingClass}
          messages={selectedSession.messages}
          order={selectedSession.order}
        />
      )}
      {showRatingForm && (
        <CoachRating onClose={closeRatingModal} coach={selectedCoach} />
      )}
      <div class="MyBookings__title">
        Mes réservations
        <div class="MyBookings__titleFilter">
          <IconFilter />
          <select onChange={(e: any) => setFilter(e.target.value)}>
            <option value="upcoming" selected={filter == 'upcoming'}>
              À venir
            </option>
            <option value="past" selected={filter == 'past'}>
              Passés
            </option>
          </select>
        </div>
      </div>
      <div class="MyBookings__bookings">
        {filteredOrders.length == 0 ? (
          <div class="MyBookings__bookingsPlaceholder">Aucune réservation</div>
        ) : (
          filteredOrders.map((o) => {
            return [...o.sessionIds, ...o.cancelledSessionIds].map((s) => {
              const session =
                sessions.find((s2) => s2.id == s) || ({ date: Date() } as any)
              const coachingClass =
                coachingClasses.find(
                  (cc) => cc.id == session.coachingClassId
                ) || ({} as any)
              const category =
                categories.find((c) => c.id == coachingClass.categoryId) ||
                ({} as any)
              const coach =
                coaches.find((c) => c.id == coachingClass.coachId) ||
                ({} as any)
              let display = true

              if (filter == 'upcoming') {
                display = session && moment(session.date).isAfter(moment())
              } else {
                display = session && moment(session.date).isBefore(moment())
              }

              return (
                display && (
                  <div
                    class={`MyBookings__entry ${
                      filter == 'past' ? 'MyBookings__entry--past' : ''
                    }`}
                  >
                    <div class="MyBookings__entryContent">
                      <div class="MyBookings__entryContentPhoto">
                        {(session.canceled ||
                          o.cancelledSessionIds.includes(session.id)) && (
                          <div class="MyBookings__entryContentCanceled">
                            Annulé
                          </div>
                        )}
                        <img
                          src={coachingClass.pictureUrl}
                          class="MyBookings__entryContentPhotoBackground"
                        />
                        <div class="MyBookings__entryContentPhotoCategoryIcon">
                          <img
                            src={
                              category.iconUrl ||
                              categories.find(
                                (c) => c.id == category.categoryId
                              )?.iconUrl
                            }
                          />
                        </div>
                      </div>
                      <div class="MyBookings__entryContentInfos">
                        <div class="MyBookings__entryContentInfosLeft">
                          <div class="MyBookings__entryContentInfosLeftCoach">
                            {coach.firstName} {coach.lastName}
                          </div>
                          <div class="MyBookings__entryContentInfosLeftName">
                            {coachingClass.name}
                          </div>
                          <div class="MyBookings__entryContentInfosLeftOrder">
                            Commande n°{o.id}
                          </div>
                          <div class="MyBookings__entryContentInfosLeftLocation">
                            <IconLocation />
                            {coachingClass.meetingLocation}
                          </div>
                          {session && coachingClass && (
                            <div class="MyBookings__entryContentInfosLeftDates">
                              <div class="MyBookings__entryContentInfosLeftDatesEntry">
                                <IconClock />
                                <span>
                                  {moment(session.date).format('dddd Do MMMM')}
                                </span>{' '}
                                {moment(session.date).format('HH[H]mm')}-
                                {moment(session.date)
                                  .add(
                                    moment.duration(
                                      coachingClass.duration,
                                      'minutes'
                                    )
                                  )
                                  .format('HH[H]mm')}
                              </div>
                            </div>
                          )}
                          <div class="MyBookings__entryContentInfosLeftMaxAttendees">
                            <IconPeople />
                            {coachingClass.maxAttendees} pers.
                          </div>
                        </div>
                        <div class="MyBookings__entryContentInfosRight">
                          <div class="MyBookings__entryContentInfosRightPrice">
                            {coachingClass.price}€
                          </div>
                        </div>
                      </div>
                    </div>
                    {messages[`${session.id}|${o.id}`] && (
                      <div class="MyBookings__entryMessages">
                        {messages[`${session.id}|${o.id}`].map((m) => {
                          return (
                            <div class="MyBookings__entryMessagesEntry">
                              <div class="MyBookings__entryMessagesEntryFrom">
                                {m.coachId
                                  ? `${coach.firstName} ${coach.lastName}`
                                  : 'Moi'}{' '}
                                -{' '}
                                {moment
                                  .utc(m.createdAt)
                                  .local()
                                  .format('L HH:mm')}
                              </div>
                              <div class="MyBookings__entryMessagesEntryContent">
                                {m.content}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    <div class="MyBookings__entryActions">
                      <a
                        href={`/coaching_classes/${coachingClass.id}`}
                        class="MyBookings__entryPreview"
                      >
                        <Button>
                          <IconPreview />
                          <div class="MyBookings__entryPreviewTitle">
                            Voir la fiche du cours
                          </div>
                        </Button>
                      </a>
                      <Button
                        onClick={() => setSelectedOrder(o)}
                        class="MyBookings__entryInvoice"
                      >
                        <IconFile />
                        <div class="MyBookings__entryInvoiceTitle">
                          Visualiser la facture
                        </div>
                      </Button>
                      {filter == 'upcoming' &&
                        !session.canceled &&
                        !o.cancelledSessionIds.includes(session.id) && (
                          <Fragment>
                            <Button
                              class="MyBookings__entryMessage"
                              onClick={displayMessageModal(
                                o,
                                session,
                                coachingClass
                              )}
                            >
                              <IconMail />
                              <div class="MyBookings__entryMessageTitle">
                                Contacter le Coach
                              </div>
                            </Button>
                            {!session ||
                              session.canceled ||
                              o.cancelledSessionIds.includes(session.id) ||
                              (moment(session.date).isAfter(moment(Date())) &&
                                moment(session.date).diff(
                                  moment(Date()),
                                  'hours'
                                ) < 24) || (
                                <Button
                                  class="MyBookings__entryCancel"
                                  onClick={cancelSession(o, session)}
                                >
                                  <IconClose />
                                  <div class="MyBookings__entryCancelTitle">
                                    Annuler le cours
                                  </div>
                                </Button>
                              )}
                          </Fragment>
                        )}
                      {filter == 'past' && (
                        <Button
                          class="MyBookings__entryRate"
                          onClick={displayRatingModal(
                            coaches.find((c) => c.id == coachingClass.coachId)
                          )}
                        >
                          <IconStar />
                          <div class="MyBookings__entryRateTitle">
                            Noter le coach
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                )
              )
            })
          })
        )}
        {orders.length > 0 && (
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={orders.length / 15}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={goToPage}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </div>
    </div>
  )
}
