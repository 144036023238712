import { h } from 'preact'
import './ChangePasswordForm.scss'
import IconClose from '../../../../assets/images/icons/icon_remove.svg'
import { useState } from 'preact/hooks'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import Button from '../../../components/Button'
import InputWithLabel from '../../../components/InputWithLabel'

interface Props {
  onClose: () => void
}

export default (props: Props) => {
  const [newPassword, setNewPassword] = useState<string>(undefined)
  const [oldPassword, setOldPassword] = useState<string>(undefined)

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const onValidate = async () => {
    try {
      await httpClient.req(
        ROUTES.UPDATE_PASSWORD({
          password: newPassword,
          oldPassword,
        })
      )

      store.notify(Message.Notification, 'Mot de passe changé avec succès')
      props.onClose()
    } catch (e) {
      store.notify(Message.Error, 'Impossible de changer le mot de passe')
      console.warn(e)
    }
  }

  return (
    <div class="ChangePasswordForm" onClick={props.onClose}>
      <div class="ChangePasswordForm__content" onClick={stopPropagation}>
        <div class="ChangePasswordForm__close" onClick={props.onClose}>
          <IconClose />
        </div>
        <div class="ChangePasswordForm__contentBody">
          <div class="ChangePasswordForm__contentBodyTitle">
            Merci de renseigner votre ancien mot de passe et le nouveau
          </div>
          <div class="ChangePasswordForm__contentBodyMessage">
            <InputWithLabel
              type="password"
              label="Ancien mot de passe"
              value={oldPassword}
              onChange={setOldPassword}
            />
            <InputWithLabel
              type="password"
              label="Nouveau mot de passe"
              value={newPassword}
              onChange={setNewPassword}
            />
          </div>
          <div class="ChangePasswordForm__contentBodySubmit">
            <Button
              onClick={onValidate}
              disabled={!newPassword || !oldPassword}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
