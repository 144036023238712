import { h } from 'preact'
import './CategoryTag.scss'
import { Category } from '../../types/category'

interface Props {
  categories: Category[]
  category: Category
  personalCoaching?: boolean
}

export default (props: Props) => {
  return (
    <a
      href={`/coaching_classes/search?categoryId=${props.category.id}${
        props.personalCoaching ? '&classType=personal' : ''
      }`}
    >
      <div class="CategoryTag">
        {props.personalCoaching && (
          <div class="CategoryTag__banner">Coaching personnel</div>
        )}
        <div class="CategoryTag__content">
          <img
            src={
              props.category.iconUrl ||
              props.categories.find((c) => c.id == props.category.categoryId)
                ?.iconUrl
            }
          />
          {props.category.name}
        </div>
      </div>
    </a>
  )
}
