import { h } from 'preact'
import './CoachingClassPreviewHorizontal.scss'
import { CoachingClass } from '../../types/coaching_class'
import StarIcon from '../../../assets/images/icons/icon_star.svg'
import LocationIcon from '../../../assets/images/icons/icon_location.svg'
import PeopleIcon from '../../../assets/images/icons/icon_people.svg'
import ClockIcon from '../../../assets/images/icons/icon_clock.svg'
import { UUID } from '../../types/common'
import { Category } from '../../types/category'
import Coach from '../../types/coach'
import { CoachingClassSession } from '../../types/coaching_class_session'
import moment from 'moment'

interface Props {
  coachingClass: CoachingClass
  categories: Category[]
  coach: Coach
  onClick?: (c: CoachingClass) => void
  sessions: CoachingClassSession[]
}

export default (props: Props) => {
  const { coachingClass } = props

  const category = (id: UUID) => props.categories.find((c) => c.id == id)

  const onClick = () => props.onClick && props.onClick(coachingClass)

  return (
    <div class="CoachingClassPreviewHorizontal" onClick={onClick}>
      <div class="CoachingClassPreviewHorizontal__photo">
        <img
          src={coachingClass.pictureUrl}
          class="CoachingClassPreviewHorizontal__photoBackground"
        />
        <div class="CoachingClassPreviewHorizontal__photoCategoryIcon">
          <img
            src={
              category(coachingClass.categoryId).iconUrl ||
              props.categories.find(
                (c) => c.id == category(coachingClass.categoryId).categoryId
              )?.iconUrl
            }
          />
        </div>
      </div>
      <div class="CoachingClassPreviewHorizontal__infos">
        <div class="CoachingClassPreviewHorizontal__infosLeft">
          <div class="CoachingClassPreviewHorizontal__infosLeftCoach">
            {props.coach.firstName} {props.coach.lastName}
          </div>
          <div class="CoachingClassPreviewHorizontal__infosLeftName">
            {coachingClass.name}
          </div>
          <div class="CoachingClassPreviewHorizontal__infosLeftLocation">
            <LocationIcon />
            {coachingClass.meetingLocation}
          </div>
          <div class="CoachingClassPreviewHorizontal__infosLeftDates">
            {props.sessions.slice(0, 3).map((s) => (
              <div class="CoachingClassPreviewHorizontal__infosLeftDatesEntry">
                <ClockIcon />
                <span>{moment(s.date).format('dddd Do MMMM')}</span>{' '}
                {moment(s.date).format('HH[H]mm')}-
                {moment(s.date)
                  .add(moment.duration(coachingClass.duration, 'minutes'))
                  .format('HH[H]mm')}
              </div>
            ))}
          </div>
          <div class="CoachingClassPreviewHorizontal__infosLeftMaxAttendees">
            <PeopleIcon />
            {coachingClass.maxAttendees} pers.
          </div>
        </div>
        <div class="CoachingClassPreviewHorizontal__infosRight">
          {props.coach.averageGrade > 0 && (
            <div class="CoachingClassPreviewHorizontal__infosRightRating">
              {(props.coach.averageGrade / 10).toFixed(1)} <StarIcon />
            </div>
          )}
          <div class="CoachingClassPreviewHorizontal__infosRightPrice">
            {coachingClass.price}€
          </div>
        </div>
      </div>
    </div>
  )
}
