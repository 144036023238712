import { h } from 'preact'
import './Invoice.scss'
import { Order } from '../types/order'
import IconClose from '../../assets/images/icons/icon_remove.svg'
import Button from '../components/Button'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { CoachingClass } from '../types/coaching_class'
import { CoachingClassSession } from '../types/coaching_class_session'
import Logo from '../../assets/images/logo.svg'
import Coach from '../types/coach'

interface Recipient {
  name: string
  address: string
  zipcode: string
  city: string
}

interface Props {
  order: Order
  recipient: Recipient
  onClose: () => void
  sessions: CoachingClassSession[]
  coachingClasses: CoachingClass[]
  coaches: Coach[]
  coachInvoice?: boolean
  payment?: { amount: number }
}

export default (props: Props) => {
  const now = moment()
  const invoiceNumber = `FC${now.year()}${now.month()}${now.day()}${now.hour()}${now.minutes()}${now.seconds()}${now.milliseconds()}`

  const downloadPdf = () => {
    html2canvas(document.querySelector('.Invoice__pdf')).then((canvas: any) => {
      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF('p', 'mm', [297, 210]) as any

      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297)
      pdf.save(`${props.order.id}.pdf`)
    })
  }

  return (
    <div class="Invoice" id={props.order.id} onClick={props.onClose}>
      <div class="MessageForm__close" onClick={props.onClose}>
        <IconClose />
      </div>
      <div class="Invoice__content" onClick={(e) => e.stopPropagation()}>
        <div class="Invoice__pdfWrapper">
          <div class="Invoice__pdf">
            <div class="Invoice__body">
              <div class="Invoice__headerTitle">
                <div>FACTURE</div>
                <div class="Invoice__headerTitleId">N° {invoiceNumber}</div>
              </div>
              <div class="Invoice__header">
                <div class="Invoice__headerLeft">
                  <Logo class="Invoice__headerLogo" />
                  <div class="Invoice__headerCompanyName">SAS Gymspot</div>
                  <div class="Invoice__headerCompanyAddress">
                    RCS Nanterre 882 848 310
                    <br />
                    FR03 882 848 310
                    <br />1 place Paul Verlaine
                    <br />
                    92100 Boulogne-Billancourt
                  </div>

                  <div class="Invoice__headerDate">
                    Le {moment(new Date()).format('LL')}
                  </div>
                </div>
                <div class="Invoice__headerRight">
                  <div class="Invoice__headerRecipient">
                    {props.recipient.name}
                    <br />
                    {props.recipient.address}
                    <br />
                    {props.recipient.zipcode}
                    <br />
                    {props.recipient.city}
                  </div>
                  <div class="Invoice__headerDate">
                    Commande du {moment(props.order.createdAt).format('LL')}
                  </div>
                </div>
              </div>
              <div class="Invoice__table">
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Coach</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.order.sessionIds.map((s_id) => {
                      let session = props.sessions.find((s) => s.id == s_id)
                      let coachingClass = props.coachingClasses.find(
                        (cc) => cc.id == session.coachingClassId
                      )
                      let coach = props.coaches.find(
                        (c) => c.id == coachingClass.coachId
                      )

                      return (
                        <tr>
                          <td>{coachingClass.name}</td>
                          <td>{moment(session.date).format('LLL')}</td>
                          <td>
                            {coach.firstName} {coach.lastName}
                          </td>
                          <td class="centered">{coachingClass.price}€</td>
                        </tr>
                      )
                    })}
                    {props.payment && (
                      <tr>
                        <td colSpan={3}>Frais Gymspot</td>
                        <td class="centered">
                          -
                          {(
                            props.order.totalPrice -
                            props.payment.amount / 100
                          ).toFixed(2)}
                          €
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={3} class="right">
                        TOTAL RECU
                      </td>
                      <td class="centered">
                        {(props.payment
                          ? props.payment.amount / 100
                          : props.order.totalPrice
                        ).toFixed(2)}
                        €
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="Invoice__footer">
              Gymspot, RCS Nanterre 882 848 31, 1 place Paul Verlaine, 92100
              Boulogne-Billancourt
            </div>
          </div>
        </div>
        <Button onClick={downloadPdf}>Télécharger la facture</Button>
      </div>
    </div>
  )
}
