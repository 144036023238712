import { h } from 'preact'
import './CoachProfile.scss'
import { Coach } from '../../types/coach'
import Button from '../../components/Button'
import InputWithLabel from '../../components/InputWithLabel'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { StandaloneSearchBox } from '@react-google-maps/api'
import RadioButton from '../../components/RadioButton'
import { Category } from '../../types/category'
import Multiselect from '../../components/Multiselect'

interface Props {
  coach: Coach
  categories: Category[]
  onChange: (u: Coach) => void
}

export default (props: Props) => {
  const [coach, setCoach] = useState(props.coach)
  const [tmpUrl, setTmpUrl] = useState(undefined)
  const [searchBox, setSearchBox] = useState<any>(undefined)

  useEffect(() => {
    if (coach && coach.pictureUrl && coach.pictureUrl.length > 0 && tmpUrl) {
      updateCoach()
    }
  }, [tmpUrl])

  const updateCoach = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.UPDATE_COACH({
          ...coach,
          pictureUrl: tmpUrl || coach.pictureUrl,
        })
      )

      props.onChange(res)
      setTmpUrl(undefined)
      store.notify(Message.Notification, 'Profil sauvegardé avec succès')
    } catch (e) {
      store.notify(Message.Error, 'Impossible de sauvegarder le profil coach')
      console.warn(e)
    }
  }

  const uploadPhoto = async (e: any) => {
    const file = e.target.files[0]

    try {
      if (file.size / 1024 / 1024 <= 10) {
        const res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

        setCoach({ ...coach, pictureUrl: res.fileUrl })
        setTmpUrl(res.fileUrl)
        return res.fileUrl
      } else {
        store.notify(
          Message.Error,
          'La taille max pour les fichiers est de 10Mo'
        )
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible d'uploader la photo")
      console.warn(e)
    }
  }

  const onSearchBoxLoad = (ref: any) => setSearchBox(ref)

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0]
    let city = null

    if (!place) {
      return
    }
    let cityComponent = place.address_components.find((ac: any) =>
      ac.types.includes('locality')
    )

    if (cityComponent) {
      city = cityComponent.long_name
    }

    setCoach({
      ...coach,
      location: place.formatted_address,
      city,
      coords: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    })
  }

  return (
    <div class="CoachProfile">
      <div class="CoachProfile__title">Mes informations coach</div>
      <div class="CoachInfos__subtitle">
        {coach.validated
          ? 'Votre profil est validé et et vos cours sont visible par tous les utilisateurs'
          : 'Votre profil sera accepté une fois que tous vos documents seront validés. Vos cours seront alors visibles à tous les utilisateurs.'}
      </div>
      <div class="CoachProfile__photo">
        {coach.pictureUrl && <img src={coach.pictureUrl} />}
        <label>
          <input type="file" onChange={uploadPhoto} />
          <div class="CoachProfile__photoUpload">
            Changer la photo de profil
          </div>
        </label>
      </div>
      <div class="CoachProfile__form">
        <div class="CoachProfile__formRow">
          <InputWithLabel
            label="Prénom"
            value={coach.firstName}
            onChange={(v) => setCoach({ ...coach, firstName: v })}
          />
          <InputWithLabel
            label="Nom"
            value={coach.lastName}
            onChange={(v) => setCoach({ ...coach, lastName: v })}
          />
        </div>
        <div class="CoachProfile__formRow CoachProfile__formLocation">
          <StandaloneSearchBox
            onLoad={onSearchBoxLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <InputWithLabel
              label="Ma localisation"
              placeholder={null}
              value={coach.location}
              disableAutocomplete
            />
          </StandaloneSearchBox>
        </div>
        <div class="CoachProfile__formRow">
          <InputWithLabel
            textarea
            label="Description"
            value={coach.description}
            onChange={(v) => setCoach({ ...coach, description: v })}
          />
          <InputWithLabel
            textarea
            label="Qualifications"
            value={coach.qualifications}
            onChange={(v) => setCoach({ ...coach, qualifications: v })}
          />
        </div>
        <div class="CoachProfile__personalCoaching">
          <h3 class="CoachProfile__personalCoachingTitle">
            Coaching personnel
          </h3>
          <div class="CoachProfile__formRow">
            <InputWithLabel
              label={`Tarif (par cours en €)${
                coach.personalCoachingPrice
                  ? ` -> ${
                      coach.personalCoachingPrice -
                      (coach.personalCoachingPrice * 15) / 100
                    }€ net`
                  : ''
              }`}
              type="number"
              value={coach.personalCoachingPrice?.toString()}
              onChange={(v) =>
                setCoach({ ...coach, personalCoachingPrice: +v })
              }
            />
            <RadioButton
              checked={coach.taxDeduction}
              onChange={(v) => setCoach({ ...coach, taxDeduction: v })}
            >
              Déduction d'impôts possible
            </RadioButton>
          </div>
          <div class="CoachProfile__formRow CoachProfile__formRow--alignedTop">
            <Multiselect
              options={props.categories}
              selected={props.categories.filter((c) =>
                coach.personalCoachingCategories.includes(c.id)
              )}
              optionsLabel={'name'}
              onChange={(v) =>
                setCoach({
                  ...coach,
                  personalCoachingCategories: v.map((c) => c.id),
                })
              }
              identifier={'id'}
              placeholder={'Catégories pour le coaching personnel'}
            />
          </div>
        </div>
      </div>
      <div class="CoachProfile__actions">
        <Button class="CoachProfile__actionsSubmit" onClick={updateCoach}>
          Mettre à jour mes informations
        </Button>
      </div>
    </div>
  )
}
